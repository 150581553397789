import { useEffect, useState } from 'react'

import { breakpoints } from '@joor/design-system'

import { ReactComponent as JOORLogo } from 'assets/images/logo_joor.svg'

import { HOME } from 'routes/paths'

import {
  HomeLink,
  LeftOptionsContainer,
  NavbarHeader,
  NavbarRevampContainer,
  RightOptionsContainer,
} from '../Navbar.ui'
import NavbarCartDropdown from '../NavbarRevamp/components/CartDropdown/CartDropdown'
import MessageMenuOption from '../NavbarRevamp/components/MessageMenuOption/MessageMenuOption'
import NavbarOrdersDropdown from '../NavbarRevamp/components/OrdersDropdown/NavbarOrdersDropdown'
import ProLiteIndicator from '../NavbarRevamp/components/ProLiteIndicator/ProLiteIndicator'
import ShopDropdown from '../NavbarRevamp/components/ShopDropdown/ShopDropdown'
import { HomeIds } from '../NavbarRevamp/navbarRevamp.ids'
import { useRecentlyCreatedAccounts } from './NavbarLiteRetailer.queries'
import NavbarExploreDropdown from './components/ExploreDropdown/ExploreDropdown'
import MobileNavbar from './components/MobileNavbar/MobileNavbar'
import RetailAccountsDropdown from './components/RetailerAccountsDropdown/RetailerAccountsDropdown'

export const NavbarLiteRetailer = () => {
  const [shouldRenderHamburger, setShouldRenderHamburger] = useState(false)
  const { recentlyCreatedAccounts } = useRecentlyCreatedAccounts()
  useEffect(() => {
    const handleResize = () => {
      setShouldRenderHamburger(window.innerWidth <= breakpoints.numeric.tablet)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (shouldRenderHamburger) {
    return (
      <NavbarRevampContainer>
        <NavbarHeader>
          <MobileNavbar
            homeLink={HOME}
            isProAccount={false}
            recentlyCreatedAccounts={recentlyCreatedAccounts}
          />
        </NavbarHeader>
      </NavbarRevampContainer>
    )
  }

  return (
    <NavbarRevampContainer>
      <NavbarHeader data-testid="navbar">
        <ProLiteIndicator />
        <HomeLink to={HOME} data-testid={HomeIds.HomeLinkTrigger}>
          <JOORLogo />
        </HomeLink>
        <LeftOptionsContainer>
          <ShopDropdown />
          <NavbarOrdersDropdown />
          <NavbarExploreDropdown />
        </LeftOptionsContainer>
        <RightOptionsContainer>
          <RetailAccountsDropdown
            recentlyCreatedAccounts={recentlyCreatedAccounts}
          />
          <MessageMenuOption />
          <NavbarCartDropdown />
        </RightOptionsContainer>
      </NavbarHeader>
    </NavbarRevampContainer>
  )
}
