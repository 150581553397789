import { useSelector } from 'react-redux'

import {
  isAccountTypeRetailer,
  userCanViewRetailerSettings,
} from 'store/currentUser/selectors'

import { ATTRIBUTE_SETTINGS, RETAILER_SETTINGS } from 'routes/paths'

import DropdownMenuTextLink from '../../DropdownMenuTextLink/DropdownMenuTextLink'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { SettingsDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

const RetailerSettings = () => {
  const isRetailerAccount = useSelector(isAccountTypeRetailer)
  const canViewRetailerSettings = useSelector(userCanViewRetailerSettings)

  return isRetailerAccount && canViewRetailerSettings ? (
    <>
      <DropdownMenuTextLink
        to={RETAILER_SETTINGS}
        message={messages.retailerSettings}
        id={SettingsDropdownIds.SettingsOptionRetailerSettings}
      />
      <DropdownMenuTextLink
        to={ATTRIBUTE_SETTINGS}
        message={messages.attributeSettings}
        id={SettingsDropdownIds.SettingsOptionAttributeSettings}
      />
    </>
  ) : null
}

export default RetailerSettings
