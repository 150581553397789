import { Modal, ModalProvider } from '@joor/design-system'

import usePortal from 'hooks/usePortal'

type ModalProps = {
  isOpen: boolean
  onClose?: () => void
  message: JSX.Element
}

const PrimaryAccountExplicationModal = ({
  isOpen,
  onClose = () => {},
  message,
}: ModalProps) => {
  const Portal = usePortal()

  return (
    <Portal>
      <ModalProvider>
        <Modal
          isOpen={isOpen}
          title={{ text: 'Primary Account' }}
          onClose={onClose}
        >
          <Modal.Content>{message}</Modal.Content>
          <Modal.Actions primaryButton={{ text: 'Close', handler: onClose }} />
        </Modal>
      </ModalProvider>
    </Portal>
  )
}

export default PrimaryAccountExplicationModal
