import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'

import { setDisplayLanguageForUserDocument } from '__generated__/atlas-types'

import { atlasClient } from 'graphql/client'
import { getUserId } from 'store/currentUser/selectors'

export const useUpdateSelectedLanguage = () => {
  const userId = useSelector((state) => getUserId(state))

  const [setDisplayLanguageForUser] = useMutation(
    setDisplayLanguageForUserDocument,
    {
      client: atlasClient,
    },
  )

  const updateSelectedLanguage = (code: string) => {
    if (userId) {
      const input = {
        id: window.btoa(`User:${userId}`),
        languageCode: code,
      }
      setDisplayLanguageForUser({
        variables: {
          input,
        },
      })
    }
  }
  return { updateSelectedLanguage }
}
