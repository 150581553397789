import { Suspense } from 'react'

import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { Redirect, Route, Switch } from 'react-router-dom'

import { featureFlagRouteWrapper } from 'utils/sdks/launchDarklyFeatureFlagRouteWrapper'

import Loader from 'components/Core/Loader/Loader'
import { REDIRECT_AFTER_SWITCH } from 'modals/OrderList/StartOrderModal/RetailerStartOrderModal/RetailerStartOrderModal.component'
import LegacyPage from 'pages/LegacyPage'
import RouterPage from 'pages/RouterPage/RouterPage'
import PrivateRoute from 'routes/PrivateRoute'
import {
  ASSORTMENT_LIST,
  HOME,
  SUBMISSIONS_VIEW,
  WORKSPACE_LIST,
} from 'routes/paths'
import {
  DEFAULT_ROUTES,
  PRIVATE_ROUTES,
  PUBLIC_ROUTES,
  RouteType,
} from 'routes/routes'

import styles from './PageContent.less'
import ExceptionHandler from 'containers/ExceptionHandler'
import FileDownloadHandler from 'containers/FileDownload/FileDownloadHandler.container'
import NewsBanner from 'containers/NewsBanner'
import NotificationHandler from 'containers/NotificationHandler/NotificationHandler.container'
import PageLoader from 'containers/PageLoader/PageLoader.container'
import EarlyAccessWidgetContainer from 'features/EarlyAccessWidget/components/EarlyAccessWidgetContainer/EarlyAccessWidgetContainer'

const PublicPageWrapper = ({ route, ...routerProps }: { route: RouteType }) => {
  const { component: WrappedComponent, path, title, feature } = route

  if (feature) {
    return featureFlagRouteWrapper(
      (props: {}) => (
        <RouterPage path={path} title={title}>
          <WrappedComponent {...props} />
        </RouterPage>
      ),
      { flagKey: feature },
      routerProps,
    )
  }

  return (
    <RouterPage path={path} title={title}>
      <WrappedComponent {...routerProps} />
    </RouterPage>
  )
}

/**
 * looks like we could wrap everything with PrivateRoute and RouterPage but turns out
 * that we want featureFlagRouterWrapper to be able to remove RouterPage to correctly
 * render blank screen or PHP and it can't do that if RouterPage is rendered above it
 */
const PrivatePageWrapper = ({
  route,
  ...routerProps
}: {
  route: RouteType
}) => {
  const { component: WrappedComponent, path, title, feature } = route

  if (feature) {
    return (
      <PrivateRoute>
        {featureFlagRouteWrapper(
          (props: {}) => (
            <RouterPage path={path} title={title}>
              <WrappedComponent {...props} />
            </RouterPage>
          ),
          { flagKey: feature },
          routerProps,
        )}
      </PrivateRoute>
    )
  }

  return (
    <PrivateRoute>
      <RouterPage path={path} title={title}>
        <WrappedComponent {...routerProps} />
      </RouterPage>
    </PrivateRoute>
  )
}

// TODO: Consolidate ExceptionHandler into NotificationHandler (ch6779)
const PageContent = () => {
  const { workspaceListPage } = useFlags()
  const redirectAfterSwitch = window.localStorage.getItem(REDIRECT_AFTER_SWITCH)
  const redirectAfterSwitchInfo = redirectAfterSwitch
    ? JSON.parse(redirectAfterSwitch)
    : null

  return (
    <>
      <NewsBanner newsToDisplay={['upcomingMaintenanceBanner']} />
      <div className={styles.routerOutlet}>
        <EarlyAccessWidgetContainer />
        <Suspense fallback={workspaceListPage ? <></> : <Loader active />}>
          <Switch>
            {PRIVATE_ROUTES.map((route) => {
              if (
                route.path === HOME &&
                (redirectAfterSwitchInfo?.store?.brand?.id ||
                  redirectAfterSwitchInfo?.path.includes(
                    SUBMISSIONS_VIEW.replace(':submissionId', ''),
                  ))
              ) {
                return (
                  <Route
                    exact
                    key={route.path}
                    path={route.path}
                    component={(props: { location: { pathname: string } }) => (
                      <Redirect
                        to={props.location.pathname.replace(
                          route.path,
                          redirectAfterSwitchInfo.path,
                        )}
                      />
                    )}
                  />
                )
              }

              if (route.path === ASSORTMENT_LIST && workspaceListPage) {
                return (
                  <Route
                    exact
                    key={route.path}
                    path={route.path}
                    component={(props: { location: { pathname: string } }) => (
                      <Redirect
                        to={props.location.pathname.replace(
                          route.path,
                          WORKSPACE_LIST,
                        )}
                      />
                    )}
                  />
                )
              }

              return (
                <Route
                  exact
                  key={route.path}
                  path={route.path}
                  // we use render instead of component to avoid unmount/mount
                  render={(routerProps) => (
                    <PrivatePageWrapper route={route} {...routerProps} />
                  )}
                />
              )
            })}
            {PUBLIC_ROUTES.map((route) => {
              return (
                <Route
                  exact
                  key={route.path}
                  path={route.path}
                  render={(routerProps) => (
                    // we use render instead of component to avoid unmount/mount
                    <PublicPageWrapper route={route} {...routerProps} />
                  )}
                />
              )
            })}
            {DEFAULT_ROUTES.map((route) => {
              return (
                <Route
                  exact
                  key={route.path}
                  path={route.path}
                  component={route.component}
                />
              )
            })}
            <Route component={(props: {}) => <LegacyPage {...props} />} />
          </Switch>
        </Suspense>
      </div>
      <NotificationHandler />
      <ExceptionHandler />
      <FileDownloadHandler />
      <PageLoader />
    </>
  )
}

export default PageContent
