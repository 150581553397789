import { Link } from 'react-router-dom'
import styled from 'styled-components'

import MenuText from './DropdownMenuText'
import { IDropdownMenuTextLink } from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

const WrappedLink = styled(Link)`
  cursor: pointer;
  text-decoration-line: none;
  margin: auto 0;
  display: flex;
  line-height: 16px;
`
const DropdownMenuTextLink = ({
  to,
  className,
  isNew = false,
  message,
  target,
  onClick,
  testId,
  id,
  count,
}: IDropdownMenuTextLink) => (
  <WrappedLink
    to={to}
    target={target}
    data-testid={testId}
    id={id}
    className="menuTextLink"
  >
    <MenuText
      className={className}
      isNew={isNew}
      message={message}
      onClick={onClick}
      count={count}
    />
  </WrappedLink>
)
export default DropdownMenuTextLink
