import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'

import {
  LiteRetailerAccountsInfoDocument,
  RecentlyCreatedAccountsQueryDocument,
} from '__generated__/atlas-types'

import { fromGlobalId, toGlobalId } from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'
import { userIsInternal } from 'store/currentUser/selectors'

import { useUserAccounts } from 'features/Navbar/NavbarRevamp/components/AccountsDropdown/accountsDropdown.hooks'
import { GrapheneAccount } from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

atlas`#graphql
  query LiteRetailerAccountsInfo ($retailerIds: [ID!]!) {
   retailers (retailerIds:$retailerIds) {
     edges {
       node {
         id
         isPrimary
         name
         privacy
         createdAt
         accountCreationSource {
          id
          requestedByAccount {
            id
            name
          }
        }
        brands(allConnections:true){
           edges{
             id
             profileName
             node {
               id
             }
           }
         }
       }
     }
   }
 }
`

export type ConnectedAccountType = {
  id: string
  profileName?: string | null
  node?: {
    id: string
  } | null
}

export type LiteRetailerAccountsType = {
  id: string
  isPrimary: boolean
  name: string
  privacy: boolean
  createdAt: string
  accountCreationSource?: {
    id?: string
    requestedByAccount?: {
      id: string
      name?: string | null
    }
  } | null
  connectedAccounts: ConnectedAccountType[]
}

export type LiteRetailerAccountsInfoType = {
  userAccounts: GrapheneAccount[]
  accounts: LiteRetailerAccountsType[]
  accountsPrivacyLoading: boolean
}

/*
  The accounts that we want to show up in the modal are:
  - Accounts accessible by the logged user
  - Lite Retailer accounts in case the current logged user is not internal
  - Retailer accounts in case the current logged user is internal
  The modal shouldn't be opened by users with access to brands or pro retail accounts 
*/

export const useGetLiteRetailerAccountsInfo = (): LiteRetailerAccountsInfoType => {
  const userAccounts: GrapheneAccount[] = useUserAccounts()
  const isInternalUser = useSelector(userIsInternal)
  const retailerIds = userAccounts
    .filter((account) => {
      const isRetailerAccount =
        fromGlobalId(account.id).type === 'RetailerAccount'
      return isInternalUser
        ? isRetailerAccount
        : isRetailerAccount && !account.assortmentPlanning
    })
    .map(({ id }) => id)
  const { data, loading } = useQuery(LiteRetailerAccountsInfoDocument, {
    client: atlasClient,
    variables: {
      retailerIds,
    },
    skip: !retailerIds.length,
  })

  return {
    userAccounts,
    accounts:
      data?.retailers?.edges?.map((account) => ({
        id: toGlobalId('Account', fromGlobalId(account?.node?.id).id) || '',
        privacy: account?.node?.privacy || false,
        isPrimary: account?.node?.isPrimary || false,
        name: account?.node?.name || '',
        createdAt: account?.node?.createdAt || '',
        accountCreationSource: account?.node?.accountCreationSource,
        connectedAccounts: account?.node?.brands?.edges || [],
      })) || [],
    accountsPrivacyLoading: loading,
  }
}

export type RecentlyCreatedAccountType = {
  id: string
  name: string
  privacy: boolean
}

type RecentlyCreatedAccountsType = {
  recentlyCreatedAccounts: RecentlyCreatedAccountType[]
}

atlas`#graphql 
  query RecentlyCreatedAccountsQuery {
    viewingUser {
      id
      recentlyCreatedAccounts {
        id
        name
        privacy
      }
    }
  }
`

export const useRecentlyCreatedAccounts = (): RecentlyCreatedAccountsType => {
  const { data } = useQuery(RecentlyCreatedAccountsQueryDocument, {
    client: atlasClient,
  })

  return {
    recentlyCreatedAccounts: data?.viewingUser?.recentlyCreatedAccounts || [],
  }
}
