import { Component } from 'react'

import classNames from 'classnames'
import {
  find,
  forEach,
  get,
  intersection,
  isEmpty,
  isNil,
  isNumber,
  isUndefined,
  map,
  reduce,
  size,
  slice,
} from 'lodash'
import PropTypes from 'prop-types'

import {
  EMPTY_DELIVERY_WINDOW,
  getDeliveryWindowText,
} from 'utils/transformations/deliveryWindows'
import { fromGlobalId } from 'utils/transformations/graphql'

import { getSkusPriceRangeByTrade } from 'shop/products/getters'
import {
  EMPTY_VALUE,
  PRODUCT_SIZE_CODE,
  PRODUCT_WHOLESALE_ATTRIBUTE,
} from 'shop/products/traits'
import {
  findSkuPriceByPriceTypeId,
  fromSkuId,
  getCasepackMultiplier,
  getVariantByCode,
} from 'shop/products/utils'

import {
  keyboardNavigationHandler,
  selectAllOnFocus,
} from 'components/AssortmentUnitsPage/KeyboardNavigation/KeyboardNavigation'
import { Table as CoreTable, Number, Tooltip } from 'components/Core'
import Table from 'components/Shop/Table'
import { ALIGN_RIGHT } from 'components/Shop/Table/Cell'
import { HEADER } from 'components/Shop/Table/Container'
import {
  DISCOUNT_POSITION_IN_ROWS,
  DOOR_POSITION_IN_ROWS,
  PRICE_POSITION_IN_ROWS,
  PRICE_ROW_ID,
  QUANTITY_COLUMN_ID,
  SUGG_RETAILER_COLUMN_ID,
  TOTAL_ROW_ID,
  TOTAL_SECTION_ID,
  UNITS_AVAILABLE_ROW_ID,
  VARIANT_POSITION_IN_ROWS,
  WHOLESALE_COLUMN_ID,
  getDoorsFinalPositionInRows,
  getPositionInRows,
  getShowNumberInput,
} from 'modals/Shop/TablesConfiguration'

import { getCasepackDeliveryRange } from '../ProductDetailModal/ProductDetailModal.transformations'
import styles from './ProductDetailSizedTable.module.css'

const SIZES_POSITION_IN_COLUMNS = 1

class ProductDetailSizedTable extends Component {
  constructor(props) {
    super(props)
    this.tableRef = null
  }

  componentDidMount() {
    if (this.tableRef) {
      this.tableRef.addEventListener('keydown', keyboardNavigationHandler)
      this.tableRef.addEventListener('focusin', selectAllOnFocus)
    }
  }

  componentDidUpdate() {
    this.setUnitsAvailable()
    this.setPrices()
  }

  componentWillUnmount() {
    if (this.tableRef) {
      this.tableRef.removeEventListener('keydown', keyboardNavigationHandler)
      this.tableRef.removeEventListener('focusin', selectAllOnFocus)
    }
  }

  setColumnUnitsAvailable = (cellParams, rows, skuUnits) => {
    const {
      doors,
      getCellValueFromTable,
      updateCellValue,
      discounts,
    } = this.props
    const doorSize = size(doors)
    const variantIndex = getPositionInRows(VARIANT_POSITION_IN_ROWS, discounts)
    const doorIndex = getPositionInRows(DOOR_POSITION_IN_ROWS, discounts)
    const rowsIdToUpdate = [
      ...(doorSize < 1 ? slice(rows, variantIndex, variantIndex + 1) : []),
      ...(doorSize > 0
        ? slice(rows, doorIndex, getDoorsFinalPositionInRows(doors, doorIndex))
        : []),
    ].map((row) => row.id)
    const units = reduce(
      rowsIdToUpdate,
      (acc, rowId) =>
        acc +
        (getCellValueFromTable({
          ...cellParams,
          rowId,
        }) || 0),
      0,
    )
    updateCellValue({
      ...cellParams,
      rowId: UNITS_AVAILABLE_ROW_ID,
      value: !isNil(skuUnits) ? skuUnits - units : null,
    })
  }

  setUnitsAvailable = () => {
    const {
      isTableInitialized,
      isRowInitialized,
      tableId,
      product: { variants, casepacks },
      sections,
      availabilityGroupsItems,
      initTableRow,
    } = this.props
    const isUnitsRowInitialized = isRowInitialized({
      tableId,
      rowId: UNITS_AVAILABLE_ROW_ID,
    })

    if (isTableInitialized && !isUnitsRowInitialized) {
      sections.forEach((section) => {
        const {
          variantValue: { skusIds },
          rows,
          id: sectionId,
        } = section
        const sizeVariant = getVariantByCode(variants, PRODUCT_SIZE_CODE)

        forEach(get(sizeVariant, 'values'), (sizeVariantValue) => {
          const skuInCommonId = intersection(
            sizeVariantValue.skusIds,
            skusIds,
          )[0]

          const skuUnits = availabilityGroupsItems.get(skuInCommonId)
          const cellParams = {
            tableId,
            sectionId,
            columnId: sizeVariantValue.id,
          }
          this.setColumnUnitsAvailable(cellParams, rows, skuUnits)
        })
        forEach(casepacks, (casepack) => {
          const skuUnits = availabilityGroupsItems.get(
            get(casepack, `colors[${sectionId}].inventoryId`),
          )
          const cellParams = {
            tableId,
            sectionId,
            columnId: casepack.id,
          }
          this.setColumnUnitsAvailable(cellParams, rows, skuUnits)
        })
      })
      initTableRow({ tableId, rowId: UNITS_AVAILABLE_ROW_ID, value: true })
    }
  }

  setSkuPrices = (section) => {
    const {
      tableId,
      product: { variants, skus },
      priceTypeId,
      updateCellValue,
      discountFactor,
    } = this.props
    const sizeVariant = getVariantByCode(variants, PRODUCT_SIZE_CODE)
    const {
      variantValue: { skusIds },
    } = section

    forEach(get(sizeVariant, 'values'), (sizeVariantValue) => {
      const skuInCommonId = intersection(sizeVariantValue.skusIds, skusIds)[0]
      const skuInCommon = skus.get(skuInCommonId)
      const skuPrice = findSkuPriceByPriceTypeId(skuInCommon, priceTypeId)
      const wholesaleValue = get(skuPrice, 'wholesalePrice')
      const retailValue = get(skuPrice, 'suggestedRetailPrice')
      updateCellValue({
        tableId,
        sectionId: section.id,
        rowId: PRICE_ROW_ID,
        columnId: sizeVariantValue.id,
        value: {
          wholesalePrice: {
            min: wholesaleValue * discountFactor,
            max: wholesaleValue * discountFactor,
          },
          retailPrice: {
            min: retailValue * discountFactor,
            max: retailValue * discountFactor,
          },
        },
      })
    })
  }

  setCasepackPrices = (section) => {
    const {
      tableId,
      product: { casepacks },
      updateCellValue,
    } = this.props

    forEach(casepacks, (casepack) => {
      updateCellValue({
        tableId,
        sectionId: section.id,
        rowId: PRICE_ROW_ID,
        columnId: casepack.id,
        value: this.getCasepackPrice(section, casepack),
      })
    })
  }

  setPrices = () => {
    const {
      isTableInitialized,
      initTableRow,
      tableId,
      isRowInitialized,
      areDiscountsReady,
    } = this.props
    const isPriceRowInitialized = isRowInitialized({
      tableId,
      rowId: PRICE_ROW_ID,
    })

    if (isTableInitialized && !isPriceRowInitialized && areDiscountsReady) {
      const { sections } = this.props
      sections.forEach((section) => {
        this.setSkuPrices(section)
        this.setCasepackPrices(section)
      })
      initTableRow({ tableId, rowId: PRICE_ROW_ID, value: true })
    }
  }

  getSizeHeaderColumns = () => {
    const {
      product: { variants },
      getSimpleCell,
    } = this.props
    const sizeVariant = find(
      variants,
      (variant) => variant.code === PRODUCT_SIZE_CODE,
    )
    const sizes = get(sizeVariant, 'values')
    const lastSizeIndex = size(sizes) - 1

    return map(sizes, ({ value }, index) => {
      const simpleCellStructure = getSimpleCell(value)
      return {
        ...simpleCellStructure,
        props: {
          ...simpleCellStructure.props,
          withBorder: lastSizeIndex === index,
          highlighted: true,
          align: ALIGN_RIGHT,
        },
      }
    })
  }

  getCasepacksTooltipContent = (casepack) => [
    <div
      key="title"
      className={classNames(styles.casepacksTooltipTitle, 'notranslate')}
    >
      {casepack.name}
    </div>,
    <CoreTable key="table" borderless className={styles.casepacksTooltipTable}>
      <CoreTable.Header>
        <CoreTable.Row>
          {casepack.sizes.map(({ value }) => (
            <CoreTable.HeaderCell
              className={classNames(styles.casepacksTooltipCell, 'notranslate')}
              key={value}
            >
              {value}
            </CoreTable.HeaderCell>
          ))}
          <CoreTable.HeaderCell className={styles.casepacksTooltipCell}>
            Total
          </CoreTable.HeaderCell>
        </CoreTable.Row>
      </CoreTable.Header>
      <CoreTable.Body>
        <CoreTable.Row>
          {casepack.sizes.map(({ value, quantity }) => (
            <CoreTable.Cell
              className={classNames(styles.casepacksTooltipCell, 'notranslate')}
              key={value}
            >
              {quantity}
            </CoreTable.Cell>
          ))}
          <CoreTable.Cell
            className={classNames(styles.casepacksTooltipCell, 'notranslate')}
          >
            {casepack.total}
          </CoreTable.Cell>
        </CoreTable.Row>
      </CoreTable.Body>
    </CoreTable>,
  ]

  getCasepackHeaderColumns = () => {
    const {
      product: { casepacks, casepackIds },
    } = this.props
    const lastCasepackIndex = size(casepacks) - 1

    return map(casepackIds, (casepackId, index) => ({
      props: {
        highlighted: true,
        className: classNames(styles.column, {
          [styles.singleColumn]: casepacks.length === 1,
          [styles.multipleColumns]: casepacks.length > 1,
        }),
        align: ALIGN_RIGHT,
        withBorder: index === lastCasepackIndex,
      },
      children: (
        <Tooltip
          position="top right"
          hoverable
          trigger={
            <div className={classNames(styles.casepacksHeader, 'notranslate')}>
              {casepacks[casepackId].name}
            </div>
          }
          content={this.getCasepacksTooltipContent(casepacks[casepackId])}
        />
      ),
    }))
  }

  getFormattedVariantColumnInPriceRow = (
    section,
    variantColumnValue,
    extraColumnProps,
  ) => {
    const {
      product: { skus, casepacks },
      priceTypeId,
      getCellWithProps,
      getCellValueFromTable,
      tableId,
      getFormattedPrice,
      flags: { skuDeliveries: skuDeliveriesFlag },
      skuDeliveries,
      userDateFormat,
    } = this.props
    const {
      variantValue: { skusIds },
      rowId: _rowId,
    } = section
    const variantSkus = map(skusIds, (skuId) => skus.get(skuId))
    let wholesaleValue = getSkusPriceRangeByTrade(
      variantSkus,
      priceTypeId,
      PRODUCT_WHOLESALE_ATTRIBUTE,
    )
    const skuColumnPrice = getCellValueFromTable({
      tableId,
      sectionId: get(section, 'id'),
      rowId: PRICE_ROW_ID,
      columnId: get(variantColumnValue, 'id'),
    })

    const shouldDisplayEmptyCell =
      !skuColumnPrice || wholesaleValue.min === wholesaleValue.max
    const emptyValue =
      shouldDisplayEmptyCell && !skuDeliveriesFlag ? '' : EMPTY_VALUE
    const wholesalePrice = shouldDisplayEmptyCell
      ? emptyValue
      : getFormattedPrice(get(skuColumnPrice, 'wholesalePrice')) || EMPTY_VALUE

    if (!skuDeliveriesFlag) {
      return getCellWithProps(wholesalePrice, extraColumnProps)
    }

    const colorVariantRawId = fromSkuId(skusIds?.[0])?.colorVariantId
    const sizeVariantRawId = fromGlobalId(
      fromGlobalId(variantColumnValue?.id).id,
    ).id
    const colorSkuDeliveries = skuDeliveries[colorVariantRawId]
    const skuDelivery = colorSkuDeliveries?.find((skuDelivery) => {
      const { colorVariantId, sizeVariantId } = fromSkuId(skuDelivery?.sku?.id)
      return (
        colorVariantId === colorVariantRawId &&
        sizeVariantId === sizeVariantRawId
      )
    })
    const casepack = casepacks[variantColumnValue?.id]
    const skuDeliveryRange = getDeliveryWindowText(
      skuDelivery?.startShipDate,
      skuDelivery?.completeShipDate,
      EMPTY_DELIVERY_WINDOW,
      undefined,
      userDateFormat,
    )
    const casepackDeliveryRange = getCasepackDeliveryRange(
      colorSkuDeliveries,
      casepack,
      undefined,
      userDateFormat,
    )?.deliveryRange

    const deliveryRange =
      skuDeliveryRange !== EMPTY_DELIVERY_WINDOW
        ? skuDeliveryRange
        : casepackDeliveryRange

    const deliveryTooltip = (
      <Tooltip
        position="top right"
        hoverable
        trigger={<span>{wholesalePrice}</span>}
        content={`Delivery: ${deliveryRange}`}
      />
    )
    return deliveryRange !== EMPTY_DELIVERY_WINDOW
      ? getCellWithProps(deliveryTooltip, extraColumnProps)
      : getCellWithProps(wholesalePrice, extraColumnProps)
  }

  getFormattedPriceRowFromSection = (section) => {
    const {
      product: { variants, casepackIds },
      flags: { skuDeliveries: skuDeliveriesFlag },
      getCellWithProps,
    } = this.props
    const { rows } = section
    const priceRow = rows[PRICE_POSITION_IN_ROWS]
    const sizeVariant = getVariantByCode(variants, PRODUCT_SIZE_CODE)

    return {
      ...priceRow,
      columns: [
        ...slice(priceRow.columns, 0, SIZES_POSITION_IN_COLUMNS),
        ...map(get(sizeVariant, 'values', []), (value, index) => {
          const extraColumnProps = {
            highlighted: true,
            withBorder: sizeVariant.values.length === index + 1,
            align: ALIGN_RIGHT,
          }
          return this.getFormattedVariantColumnInPriceRow(
            section,
            value,
            extraColumnProps,
          )
        }),
        ...map(casepackIds, (casepackId, index) => {
          const extraColumnProps = {
            highlighted: true,
            withBorder: casepackIds.length === index + 1,
          }
          return skuDeliveriesFlag
            ? this.getFormattedVariantColumnInPriceRow(
                section,
                { id: casepackId },
                extraColumnProps,
              )
            : getCellWithProps('', extraColumnProps)
        }),
        ...slice(priceRow.columns, SIZES_POSITION_IN_COLUMNS),
      ],
    }
  }

  getFormattedDiscountRowFromSection = (section) => {
    const {
      product: { variants, casepackIds },
      getCellWithProps,
      discounts,
    } = this.props
    const { rows } = section
    const discountRow = rows[DISCOUNT_POSITION_IN_ROWS]
    const sizeVariant = getVariantByCode(variants, PRODUCT_SIZE_CODE)
    const discount = get(discounts[0], 'factor', null)
    const discountCellContent = (
      <div className={styles.discounts}>
        {discount ? `${discount}%` : EMPTY_VALUE}
      </div>
    )

    return {
      ...discountRow,
      columns: [
        ...slice(discountRow.columns, 0, SIZES_POSITION_IN_COLUMNS),
        ...map(get(sizeVariant, 'values', []), (_, index) => {
          const extraColumnProps = {
            highlighted: true,
            withBorder: sizeVariant.values.length === index + 1,
          }
          return getCellWithProps(discountCellContent, extraColumnProps)
        }),
        ...map(casepackIds, (_, index) => {
          const extraColumnProps = {
            highlighted: true,
            withBorder: casepackIds.length === index + 1,
          }
          return getCellWithProps(discountCellContent, extraColumnProps)
        }),
        ...slice(discountRow.columns, SIZES_POSITION_IN_COLUMNS),
      ],
    }
  }

  getFormattedVariantRowFromSection = (section) => {
    const {
      tableId,
      product: { variants, casepacks, casepackIds },
      getSimpleCell,
      doors,
      getRowQuantity,
      getRowPrices,
      getFormattedPrice,
      discounts,
    } = this.props
    const variantIndex = getPositionInRows(VARIANT_POSITION_IN_ROWS, discounts)
    const { rows, id: sectionId } = section
    const variantRow = rows[variantIndex]
    const sizeVariant = getVariantByCode(variants, PRODUCT_SIZE_CODE)
    const options = {
      section,
      rowId: variantRow.id,
      showNumberInput: isEmpty(doors),
    }
    const cellParams = {
      tableId,
      sectionId,
      rowId: variantRow.id,
    }
    const quantityValue = getRowQuantity(cellParams)
    const { wholesalePrice, retailPrice } = getRowPrices(cellParams)

    return {
      ...variantRow,
      columns: [
        ...slice(variantRow.columns, 0, SIZES_POSITION_IN_COLUMNS),
        ...this.getInputCellsFromArray(get(sizeVariant, 'values', []), options),
        ...this.getInputCellsFromArray(casepackIds, options, casepacks),
        getSimpleCell(isEmpty(doors) ? quantityValue : ''),
        getSimpleCell(isEmpty(doors) ? getFormattedPrice(wholesalePrice) : ''),
        getSimpleCell(isEmpty(doors) ? getFormattedPrice(retailPrice) : ''),
        ...slice(variantRow.columns, SIZES_POSITION_IN_COLUMNS),
      ],
    }
  }

  getNumberInput = ({ section, columnId, rowId, disableInput }) => {
    const {
      getCellValueFromTable,
      tableId,
      isValidQuantity,
      product: { orderMinimum: productOrderMinimum },
    } = this.props
    const {
      id: sectionId,
      variantValue: { orderMinimum: variantOrderMinimum },
    } = section
    const tableParams = {
      tableId,
      sectionId,
      rowId,
      columnId,
    }
    const inputValue = getCellValueFromTable(tableParams)
    const isInvalidQuantity =
      (variantOrderMinimum || productOrderMinimum) &&
      !isValidQuantity({
        ...tableParams,
        variantOrderMinimum,
        productOrderMinimum,
      })
    return (
      <Number
        defaultValue={inputValue || '0'}
        className={classNames(styles.numberInput, {
          [styles.invalid]: isInvalidQuantity,
        })}
        disabled={disableInput}
        onChange={this.handleNumberInputChange(sectionId, rowId, columnId)}
        onlyNaturalNumbersAllowed
        clearDefaultValueOnFocus
        allowEmptyValue={false}
      />
    )
  }

  getInputCellsFromArray = (array, options = {}, objectById) => {
    const { getCellWithProps } = this.props
    const {
      section,
      section: {
        id: sectionId,
        variantValue: { skusIds },
      },
      rowId,
      showNumberInput,
    } = options

    return map(array, (element, index) => {
      const extraColumnProps = {
        highlighted: true,
        withBorder: size(array) === index + 1,
        align: ALIGN_RIGHT,
      }
      const isCasepack = !isUndefined(objectById)
      let disableInput = false
      if (!isCasepack) {
        const skusInCommon = intersection(element.skusIds, skusIds)
        disableInput = isEmpty(skusInCommon)
      } else {
        disableInput = isUndefined(
          get(objectById, `[${element}]colors[${sectionId}]`),
        )
      }
      const numberInput = this.getNumberInput({
        section,
        columnId: element.id || element,
        rowId,
        disableInput,
      })
      if (showNumberInput) {
        return getCellWithProps(numberInput, extraColumnProps)
      } else {
        return getCellWithProps('', extraColumnProps)
      }
    })
  }

  getFormattedDoorRowsFromSection = (section) => {
    const {
      tableId,
      doors,
      product: { variants, casepacks, casepackIds },
      getSimpleCell,
      getRowQuantity,
      getRowPrices,
      getFormattedPrice,
      discounts,
    } = this.props
    const { id: sectionId, rows } = section
    const doorSize = size(doors)

    const doorIndex = getPositionInRows(DOOR_POSITION_IN_ROWS, discounts)
    const endDoorsPosition = getDoorsFinalPositionInRows(doors, doorIndex)
    const doorRows =
      doorSize > 0 ? slice(rows, doorIndex, endDoorsPosition) : []
    const sizeVariant = getVariantByCode(variants, PRODUCT_SIZE_CODE)
    return map(doorRows, (doorRow, index) => {
      const showNumberInput = getShowNumberInput(doorSize, index)

      const options = {
        section,
        rowId: doorRow.id,
        showNumberInput,
      }
      const cellParams = {
        tableId,
        sectionId,
        rowId: doorRow.id,
      }
      const quantityValue = getRowQuantity(cellParams)
      const { wholesalePrice, retailPrice } = getRowPrices(cellParams)

      return {
        ...doorRow,
        columns: [
          ...slice(doorRow.columns, 0, SIZES_POSITION_IN_COLUMNS),
          ...this.getInputCellsFromArray(
            get(sizeVariant, 'values', []),
            options,
          ),
          ...this.getInputCellsFromArray(casepackIds, options, casepacks),
          ...(showNumberInput
            ? [
                getSimpleCell(quantityValue),
                getSimpleCell(getFormattedPrice(wholesalePrice)),
                getSimpleCell(getFormattedPrice(retailPrice)),
              ]
            : []),
          ...(!showNumberInput
            ? [getSimpleCell(''), getSimpleCell(''), getSimpleCell('')]
            : []),
          ...slice(doorRow.columns, SIZES_POSITION_IN_COLUMNS),
        ],
      }
    })
  }

  getUnitsColumnsFromArray = (sectionId, array) =>
    map(array, (element, index) => {
      const { getCellValueFromTable, tableId, getCellWithProps } = this.props

      const extraColumnProps = {
        highlighted: true,
        withBorder: size(array) === index + 1,
      }
      const unitsAvailable = getCellValueFromTable({
        tableId,
        sectionId,
        rowId: UNITS_AVAILABLE_ROW_ID,
        columnId: get(element, 'id') || element,
      })
      return getCellWithProps(
        <span
          className={classNames(styles.unitsAvailable, {
            [styles.invalid]: unitsAvailable < 0,
          })}
        >
          {unitsAvailable}
        </span>,
        extraColumnProps,
      )
    })

  getFormattedUnitsRowFromSection = (section) => {
    const {
      product: { variants, casepackIds },
      getSimpleCell,
    } = this.props
    const sizeVariant = getVariantByCode(variants, PRODUCT_SIZE_CODE)

    return {
      props: {
        small: true,
        skipNavigation: true,
      },
      columns: [
        getSimpleCell('Units Available'),
        ...this.getUnitsColumnsFromArray(
          section.id,
          get(sizeVariant, 'values', []),
        ),
        ...this.getUnitsColumnsFromArray(section.id, casepackIds),
        getSimpleCell(''),
        getSimpleCell(''),
        getSimpleCell(''),
      ],
    }
  }

  getTotalColumnsFromArray = (array) => {
    const { tableId, getCellValueFromTable, getCellWithProps } = this.props

    return map(array, (value, index) => {
      const extraColumnProps = {
        highlighted: true,
        align: ALIGN_RIGHT,
        withBorder: array.length === index + 1,
      }
      const cellValue = getCellValueFromTable({
        tableId,
        sectionId: TOTAL_SECTION_ID,
        rowId: TOTAL_ROW_ID,
        columnId: value.id || value,
      })
      return getCellWithProps(cellValue || 0, extraColumnProps)
    })
  }

  getTotalRowFromTable = () => {
    const {
      tableId,
      product: { variants, casepackIds },
      getSimpleCell,
      getRowQuantity,
      getRowPrices,
      getFormattedPrice,
    } = this.props
    const cellParams = {
      tableId,
      sectionId: TOTAL_SECTION_ID,
      rowId: TOTAL_ROW_ID,
    }
    const sizeVariant = getVariantByCode(variants, PRODUCT_SIZE_CODE)
    const quantityValue = getRowQuantity(cellParams)
    const { wholesalePrice, retailPrice } = getRowPrices(cellParams)

    return {
      props: {
        tertiary: true,
        medium: true,
        skipNavigation: true,
      },
      columns: [
        getSimpleCell('Total'),
        ...this.getTotalColumnsFromArray(get(sizeVariant, 'values', [])),
        ...this.getTotalColumnsFromArray(casepackIds),
        getSimpleCell(quantityValue),
        getSimpleCell(getFormattedPrice(wholesalePrice)),
        getSimpleCell(getFormattedPrice(retailPrice)),
      ],
    }
  }

  getCasepackPrice = (section, casepack) => {
    const { priceTypeId, discountFactor } = this.props
    const { id: sectionId } = section
    const { sizes: casepackSizes, colors: casepackColors } = casepack
    return reduce(
      casepackSizes,
      (acc, casepackSize) => {
        const { id: sizeVariantId } = casepackSize
        const sku = get(casepackColors, `[${sectionId}].skus[${sizeVariantId}]`)
        const skuPrices = findSkuPriceByPriceTypeId(sku, priceTypeId)

        return {
          wholesalePrice: {
            min:
              acc.wholesalePrice.min +
              get(skuPrices, 'wholesalePrice', 0) *
                casepackSize.quantity *
                discountFactor,
            max:
              acc.wholesalePrice.max +
              get(skuPrices, 'wholesalePrice', 0) *
                casepackSize.quantity *
                discountFactor,
          },
          retailPrice: {
            min:
              acc.retailPrice.min +
              get(skuPrices, 'suggestedRetailPrice', 0) *
                casepackSize.quantity *
                discountFactor,
            max:
              acc.retailPrice.max +
              get(skuPrices, 'suggestedRetailPrice', 0) *
                casepackSize.quantity *
                discountFactor,
          },
        }
      },
      {
        wholesalePrice: { min: 0, max: 0 },
        retailPrice: { min: 0, max: 0 },
      },
    )
  }

  getSkuPrices = (cellParams, quantity) => {
    const { getCellValueFromTable, getRowPrices, getNewPrice } = this.props
    const { wholesalePrice, retailPrice } = getCellValueFromTable({
      ...cellParams,
      rowId: PRICE_ROW_ID,
    })

    const {
      wholesalePrice: oldWholesalePrice,
      retailPrice: oldRetailPrice,
    } = getRowPrices(cellParams)

    const newWholesalePrice = getNewPrice(wholesalePrice, quantity)
    const newRetailPrice = getNewPrice(retailPrice, quantity)

    return {
      wholesalePrice: {
        min: oldWholesalePrice.min + newWholesalePrice.min,
        max: oldWholesalePrice.max + newWholesalePrice.max,
      },
      retailPrice: {
        min: oldRetailPrice.min + newRetailPrice.min,
        max: oldRetailPrice.max + newRetailPrice.max,
      },
    }
  }

  setRowQuantityAndPrices = (cellParams, newValue, quantity) => {
    const {
      updateCellValue,
      getRowQuantity,
      product: { casepacks },
    } = this.props
    const casepackMultiplier = getCasepackMultiplier(
      cellParams.columnId,
      casepacks,
    )
    const newQuantityValue =
      getRowQuantity(cellParams) + newValue * casepackMultiplier

    const { wholesalePrice, retailPrice } = this.getSkuPrices(
      cellParams,
      newValue,
    )
    const newValuesConfig = [
      {
        value: quantity,
      },
      {
        columnId: QUANTITY_COLUMN_ID,
        value: newQuantityValue,
      },
      {
        columnId: WHOLESALE_COLUMN_ID,
        value: wholesalePrice,
      },
      {
        columnId: SUGG_RETAILER_COLUMN_ID,
        value: retailPrice,
      },
    ]

    newValuesConfig.forEach((cellParamsConfig) => {
      updateCellValue({
        ...cellParams,
        ...cellParamsConfig,
      })
    })
  }

  updateUnitsAvailable = (cellParams, value) => {
    const { updateCellValue, getCellValueFromTable } = this.props
    const unitsAvailable = getCellValueFromTable({
      ...cellParams,
      rowId: UNITS_AVAILABLE_ROW_ID,
    })
    if (isNumber(unitsAvailable)) {
      updateCellValue({
        ...cellParams,
        rowId: UNITS_AVAILABLE_ROW_ID,
        value: unitsAvailable - value,
      })
    }
  }

  handleNumberInputChange = (sectionId, rowId, columnId) => (
    _,
    { value, defaultValue },
  ) => {
    const quantity = parseInt(value || 0, 10)

    if (quantity !== defaultValue) {
      const { getCellValueFromTable, setTotalRowValues, tableId } = this.props
      const cellParams = {
        tableId,
        sectionId,
        rowId,
        columnId,
      }
      const previousValue = getCellValueFromTable(cellParams)
      const newValue = quantity - (previousValue || 0)
      this.updateUnitsAvailable(cellParams, newValue)
      this.setRowQuantityAndPrices(cellParams, newValue, quantity)
      setTotalRowValues(cellParams, previousValue, quantity)
    }
  }

  renderHeader = () => {
    const { headerCols } = this.props
    const headerRow = {
      props: {
        big: true,
        secondary: true,
      },
      columns: [
        ...slice(headerCols, 0, SIZES_POSITION_IN_COLUMNS),
        ...this.getSizeHeaderColumns(),
        ...this.getCasepackHeaderColumns(),
        ...slice(headerCols, SIZES_POSITION_IN_COLUMNS),
      ],
    }
    return <Table.Container type={HEADER} rows={[headerRow]} />
  }

  renderBody = () => {
    const { sections, doors, availabilityGroupsItems, discounts } = this.props
    const rows = []
    const doorsIndex = getPositionInRows(DOOR_POSITION_IN_ROWS, discounts)
    const positionSliced = getDoorsFinalPositionInRows(doors, doorsIndex)

    sections.forEach((section) => {
      rows.push(
        ...[
          this.getFormattedPriceRowFromSection(section),
          ...(!isEmpty(discounts)
            ? [this.getFormattedDiscountRowFromSection(section)]
            : []),
          this.getFormattedVariantRowFromSection(section),
          ...this.getFormattedDoorRowsFromSection(section),
          ...slice(section.rows, positionSliced),
          ...(!isEmpty(availabilityGroupsItems)
            ? [this.getFormattedUnitsRowFromSection(section)]
            : []),
        ],
      )
    })

    return (
      <Table.Container
        rows={[
          ...rows,
          ...(!isEmpty(sections) ? [this.getTotalRowFromTable()] : []),
        ]}
      />
    )
  }

  render() {
    return (
      <div
        className={styles.ProductDetailSizedTable}
        data-scroll-container="x-axis"
      >
        <Table
          headerSticky
          firstColSticky
          className={styles.table}
          tableRef={(ref) => {
            this.tableRef = ref
          }}
        >
          {this.renderHeader()}
          {this.renderBody()}
        </Table>
      </div>
    )
  }
}

ProductDetailSizedTable.propTypes = {
  product: PropTypes.shape({
    variants: PropTypes.arrayOf(PropTypes.object).isRequired,
    casepacks: PropTypes.object.isRequired,
    casepackIds: PropTypes.array.isRequired,
    skus: PropTypes.object.isRequired,
  }).isRequired,
  getSimpleCell: PropTypes.func,
  headerCols: PropTypes.array,
  sections: PropTypes.object,
  priceTypeId: PropTypes.string.isRequired,
  doors: PropTypes.array,
  discounts: PropTypes.array,
  getCellWithProps: PropTypes.func,
  updateCellValue: PropTypes.func,
  getCellValueFromTable: PropTypes.func,
  setTotalRowValues: PropTypes.func,
  tableId: PropTypes.string.isRequired,
  isValidQuantity: PropTypes.func,
  availabilityGroupsItems: PropTypes.object,
  isTableInitialized: PropTypes.bool,
  getRowQuantity: PropTypes.func,
  getRowPrices: PropTypes.func,
  initTableRow: PropTypes.func,
  isRowInitialized: PropTypes.func,
  getNewPrice: PropTypes.func,
  getFormattedPrice: PropTypes.func,
  discountFactor: PropTypes.number,
  areDiscountsReady: PropTypes.bool,
  skuDeliveries: PropTypes.object,
}

ProductDetailSizedTable.defaultProps = {
  getSimpleCell: () => {},
  headerCols: [],
  sections: new Map(),
  doors: [],
  discounts: [],
  getCellWithProps: () => {},
  updateCellValue: () => {},
  getCellValueFromTable: () => {},
  isValidQuantity: () => {},
  availabilityGroupsItems: new Map(),
  isTableInitialized: false,
  getRowQuantity: () => {},
  getRowPrices: () => {},
  initTableRow: () => {},
  isRowInitialized: () => {},
  setTotalRowValues: () => {},
  getNewPrice: () => {},
  getFormattedPrice: () => {},
  discountFactor: 1,
  areDiscountsReady: false,
  flags: { skuDeliveries: false },
  skuDeliveries: {},
}

export default ProductDetailSizedTable
