import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'

import {
  LiteRetailerAccountsInfoDocument,
  LiteRetailerConnectedAccountsDropdownDocument,
  UpdateRetailerPrimaryAccountDocument,
} from '__generated__/atlas-types'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'
import { isLiteRetailer as isLiteRetailerSelector } from 'store/currentUser/selectors'

atlas`#graphql
  mutation UpdateRetailerPrimaryAccount(
    $input: UpdateRetailerPrimaryAccountInput!
  ) {
    UpdateRetailerPrimaryAccount(input: $input) {
      retailerId
    }
  }
`

export const useUpdateRetailerPrimaryAccount = () => {
  const isLiteRetailer = useSelector(isLiteRetailerSelector)
  const [mutate, { loading, error }] = useMutation(
    UpdateRetailerPrimaryAccountDocument,
    {
      client: atlasClient,
    },
  )

  return {
    updateRetailerPrimaryAccount: (
      retailerId: string,
      setPrimaryAccount: boolean,
    ) => {
      return mutate({
        variables: {
          input: {
            retailerId,
            primaryAccount: setPrimaryAccount,
          },
        },
        refetchQueries: [
          ...(isLiteRetailer
            ? [
                {
                  query: LiteRetailerAccountsInfoDocument,
                  variables: { accountIds: [retailerId] },
                },
              ]
            : []),
          {
            query: LiteRetailerConnectedAccountsDropdownDocument,
            variables: { retailerIds: [retailerId] },
          },
        ],
      })
    },
    isUpdatingPrimaryAccountLoading: loading,
    error,
  }
}
