import { FIND_NEW_RETAILERS_PREFIX as FEATURE_PREFIX } from 'featurePrefix.ids'

const FILTERS_PREFIX = 'filters'
const RETAILER_LIST = 'retailer-list'
const RETAILER_CARD = 'retailer-card'
const CONNECT_MODAL = 'connect-modal'

export const FindNewRetailersPageIds = {
  Title: `${FEATURE_PREFIX}__title`,
  Component: `${FEATURE_PREFIX}__component`,
  Search: `${FEATURE_PREFIX}__search`,
  SortBy: `${FEATURE_PREFIX}__sort-by`,
  ClearSearchFilters: `${FEATURE_PREFIX}__clear-search-filters`,
  FilteredResultsNumber: `${FEATURE_PREFIX}__${RETAILER_LIST}__filtered-results-number`,
} as const

export const FindNewRetailersFilterIds = {
  Component: `${FEATURE_PREFIX}__${FILTERS_PREFIX}__component`,
  Counter: `${FEATURE_PREFIX}__${FILTERS_PREFIX}__counter`,
  LocationCity: `${FEATURE_PREFIX}__${FILTERS_PREFIX}__location_city`,
  LocationState: `${FEATURE_PREFIX}__${FILTERS_PREFIX}__location_state`,
  LocationCountry: `${FEATURE_PREFIX}__${FILTERS_PREFIX}__location_country`,
  LocationZip: `${FEATURE_PREFIX}__${FILTERS_PREFIX}__location_zip`,
  BrandsCarried: `${FEATURE_PREFIX}__${FILTERS_PREFIX}__brands-carried`,
  WholesalePrice: `${FEATURE_PREFIX}__${FILTERS_PREFIX}__wholesale-price`,
  WholesalePriceMin: `${FEATURE_PREFIX}__${FILTERS_PREFIX}__wholesale-price-min`,
  WholesalePriceMax: `${FEATURE_PREFIX}__${FILTERS_PREFIX}__wholesale-price-max`,
  Categories: `${FEATURE_PREFIX}__${FILTERS_PREFIX}__categories`,
  CategoriesSelector: `${FEATURE_PREFIX}__${FILTERS_PREFIX}__categories-selector`,
  RetailerId: `${FEATURE_PREFIX}__${FILTERS_PREFIX}__retailer-id`,
  ClearButton: `${FEATURE_PREFIX}__${FILTERS_PREFIX}__clear-button`,
  ApplyButton: `${FEATURE_PREFIX}__${FILTERS_PREFIX}__apply-button`,
} as const

export const FindNewRetailersList = {
  Pagination: `${FEATURE_PREFIX}__${RETAILER_LIST}__pagination`,
  SmallPageSize: `${FEATURE_PREFIX}__${RETAILER_LIST}__small-page-size`,
  LargePageSize: `${FEATURE_PREFIX}__${RETAILER_LIST}__large-page-size`,
}

export const FindNewRetailerCard = {
  // dont want to break pendo tracking but this action button will be now
  // just for the connect icon figure
  ActionButton: `${FEATURE_PREFIX}__${RETAILER_CARD}__action-button`,
  ProfileLink: `${FEATURE_PREFIX}__${RETAILER_CARD}__profile-link`,
  RetailerAddress: `${FEATURE_PREFIX}__${RETAILER_CARD}__address`,
  RetailerDescription: `${FEATURE_PREFIX}__${RETAILER_CARD}__description`,
  RetailerWbesite: `${FEATURE_PREFIX}__${RETAILER_CARD}__website`,
  ManageConnectionLink: `${FEATURE_PREFIX}__${RETAILER_CARD}__manage-connection-link`,
  SendOutreach: `${FEATURE_PREFIX}__${RETAILER_CARD}__send_outreach`,
  ViewProspects: `${FEATURE_PREFIX}__${RETAILER_CARD}__view_prospects`,
}

export const FindNewRetailerConnectModal = {
  Title: `${FEATURE_PREFIX}__${CONNECT_MODAL}__title`,
  Message: `${FEATURE_PREFIX}__${CONNECT_MODAL}__message`,
  PriceTypeDropdown: `${FEATURE_PREFIX}__${CONNECT_MODAL}__pricetype-dropdown`,
  OtherBrand: `${FEATURE_PREFIX}__${CONNECT_MODAL}__other-brand`,
  SendButton: `${FEATURE_PREFIX}__${CONNECT_MODAL}__send-button`,
}
