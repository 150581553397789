import ArrayOfIdsFilter from 'utils/filters/arrayOfIdsFilter'
import DateFilter from 'utils/filters/dateFilter'

import messages from './CancelStyleFilters.messages'
import styles from './CancelStyleFilters.module.css'

// All the filters in Cancel Style
export const cancelStyleFilters = {
  updatedAt: new DateFilter({
    filterKey: 'updatedAt',
    label: messages.updatedAt,
    dataKey: 'updatedAt',
    messages,
    styles,
  }),
  collections: new ArrayOfIdsFilter({
    filterKey: 'collections',
    label: messages.linesheet,
    dataKey: 'collections',
    expanded: true,
  }),
}

// Filter Setup for the Cancel Style Filter
export const filterSetup = [
  {
    key: 'updatedAt',
    title: messages.updatedAt,
    noTranslate: true,
    filters: [cancelStyleFilters.updatedAt],
  },
  {
    key: 'collections',
    title: messages.linesheet,
    noTranslate: true,
    filters: [cancelStyleFilters.collections],
  },
]
