import { FC, useEffect, useState } from 'react'

import { Icon, Subtitle, TextButton, Tooltip } from '@joor/design-system'
import sortBy from 'lodash/sortBy'
import { useSelector } from 'react-redux'

import { SocialMediaType, TradeShowAward } from '__generated__/atlas-types'

import { getURLFromString } from 'utils/getURLFromString'

import { isAccountTypeRetailer } from 'store/currentUser/selectors'

import {
  EmptyDescriptionMessage,
  StyledAnchor,
  StyledAwardBadge,
  StyledContainer,
  StyledDescription,
  StyledDescriptionContainer,
  StyledMediaContainer,
} from './BasicInfoViewMode.styles'
import { RetailerProfile } from 'features/LeadGen/RetailerProfile/retailerProfilePage.types'

const SOCIAL_MEDIA_ORDER = [
  SocialMediaType.INSTAGRAM,
  SocialMediaType.TWITTER,
  SocialMediaType.FACEBOOK,
]

const DESCRIPTION_CHARACTER_LIMIT = 1300

const getDescription = (length: number, description?: string) => {
  if ((description?.length || 0) >= length) {
    return `${description?.slice(0, length)}...`
  } else return description
}

const getSocialMediaIcon = (socialMediaType?: SocialMediaType | null) => {
  switch (socialMediaType) {
    case SocialMediaType.INSTAGRAM:
      return 'instagram'
    case SocialMediaType.TWITTER:
      return 'twitter'
    default:
      return 'facebook'
  }
}
const formatFacebookUrl = (url: string) => {
  const urlParameters = url.split('/')
  const facebookParameterIndex = urlParameters.findIndex(
    (urlParameter: string) => urlParameter.includes('facebook'),
  )
  const compiledUrl = urlParameters.splice(
    facebookParameterIndex,
    urlParameters.length - 1,
  )
  const formattedUrl = `http://${compiledUrl.join('/')}`
  return formattedUrl
}
const getSocialMediaUrl = (
  socialMediaType?: SocialMediaType | null,
  url?: string | null,
) => {
  switch (socialMediaType) {
    case SocialMediaType.INSTAGRAM:
      return `https://instagram.com/${url?.replace('@', '')}`
    case SocialMediaType.TWITTER:
      return `https://twitter.com/${url?.replace('@', '')}`
    default:
      return formatFacebookUrl(url || '')
  }
}

type BasicInfoViewModeProps = {
  description?: string
  website?: string
  socialMedia?: RetailerProfile['socialMediaAccounts']
  awards?: TradeShowAward[] | null
}

const BasicInfoViewMode: FC<BasicInfoViewModeProps> = ({
  description,
  website,
  socialMedia,
  awards,
}) => {
  const [showDescription, setShowDescription] = useState<boolean>(false)
  const [descriptionCharLimit, setDescriptionCharLimit] = useState<number>(
    DESCRIPTION_CHARACTER_LIMIT,
  )
  const descriptionOverflows = (description?.length ?? 0) > descriptionCharLimit
  const isRetailerAccount = useSelector(isAccountTypeRetailer)

  const websiteUrl = website ? getURLFromString(website) : undefined

  useEffect(() => {
    const updateWindowDimensions = () => {
      const windowWidth = window.screen.width
      if (windowWidth >= 1200 && windowWidth < 1350) {
        setDescriptionCharLimit(500)
      } else if (windowWidth >= 1350 && windowWidth < 1520) {
        setDescriptionCharLimit(750)
      } else if (windowWidth >= 1520 && windowWidth < 1720) {
        setDescriptionCharLimit(1000)
      } else {
        setDescriptionCharLimit(DESCRIPTION_CHARACTER_LIMIT)
      }
    }

    window.addEventListener('resize', updateWindowDimensions)

    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  return (
    <StyledContainer>
      <StyledDescriptionContainer>
        {description ? (
          <StyledDescription
            $maxHeight={showDescription ? 'auto' : '400px'}
            className="noTranslate"
          >
            {descriptionOverflows && !showDescription
              ? getDescription(descriptionCharLimit, description)
              : description}
          </StyledDescription>
        ) : (
          <EmptyDescriptionMessage>
            {isRetailerAccount
              ? 'Edit page to add a description'
              : 'The retailer has not provided any information.'}
          </EmptyDescriptionMessage>
        )}
        {descriptionOverflows && (
          <TextButton onClick={() => setShowDescription(!showDescription)}>
            {showDescription ? 'Show Less' : 'Show More'}
          </TextButton>
        )}
        {websiteUrl && (
          <StyledAnchor href={websiteUrl.href} target="_blank" rel="noreferrer">
            {websiteUrl.hostname}
          </StyledAnchor>
        )}
        {Boolean(socialMedia?.length) && (
          <StyledMediaContainer>
            {sortBy(socialMedia, (media) =>
              SOCIAL_MEDIA_ORDER.findIndex((o) => media.type === o),
            )?.map(({ url, type }, index) => {
              if (url === '') return null
              return (
                <a
                  href={getSocialMediaUrl(type, url)}
                  target="_blank"
                  rel="noreferrer"
                  data-testid={`retailer-${type}-account`}
                  key={`${index}_${url}`}
                  className="notranslate"
                >
                  <Icon iconName={getSocialMediaIcon(type)} />
                </a>
              )
            })}
          </StyledMediaContainer>
        )}
        {Boolean(awards?.length) && (
          <div>
            <Subtitle>Awards</Subtitle>
            {awards?.map((award) => (
              <Tooltip key={award.id} content={award.description}>
                <StyledAwardBadge
                  data-testId={`award-badge-${award.id}`}
                  src={award.awardBadgeUrl!}
                />
              </Tooltip>
            ))}
          </div>
        )}
      </StyledDescriptionContainer>
    </StyledContainer>
  )
}

export default BasicInfoViewMode
