import styled, { css } from 'styled-components'

import { ConnectedAccountType } from 'features/Navbar/NavbarLiteRetailer/NavbarLiteRetailer.queries'
import AccountInfo from 'features/Navbar/NavbarLiteRetailer/components/AccountOption/AccountInfo'

interface MenuTextProps {
  isCurrentAccount: boolean
}

export const MenuTextAction = styled.button<MenuTextProps>`
  background-color: var(--color-primary-100);
  border: none;
  text-align: left;
  padding: 0;
  cursor: pointer;
  text-decoration-line: none;
  margin: auto 0;
  display: flex;
  line-height: var(--line-height-16);
  width: 100%;

  border: 1px solid var(--color-primary-200);
  border-radius: 10px;

  &.primary {
    border: 1px solid var(--color-accent-beta-700);
    border-radius: 10px;
  }
  ${(props) =>
    props.isCurrentAccount &&
    css`
      cursor: auto;
    `}
`

const MenuText = styled.div<MenuTextProps>`
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-alpha);
  letter-spacing: var(--letter-spacing-tighter);
  line-height: var(--line-height-16);
  padding: 8px;
  width: 100%;

  &:hover {
    border: ${(props) =>
      props.isCurrentAccount ? 'none' : '1px solid var(--color-primary-400)'};
    border-radius: 10px;
    padding: ${(props) => (props.isCurrentAccount ? '8px' : '7px')};
  }
`
interface IAccountDropdownOption {
  accountName: string
  connectedAccounts?: ConnectedAccountType[]
  className?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  checked?: boolean
  privacy: boolean
  createdAt: string
  isPrimary: boolean
  isCurrentAccount?: boolean
  hideVisibilityIcon?: boolean
  createdBy?: string | null
}

const AccountDropdownOption = ({
  className,
  accountName,
  connectedAccounts = [],
  onClick,
  checked = false,
  isPrimary,
  createdAt,
  privacy,
  isCurrentAccount = false,
  hideVisibilityIcon = false,
  createdBy,
}: IAccountDropdownOption) => {
  return (
    <MenuTextAction
      className={`menuTextLink ${isPrimary ? 'primary' : ''}`}
      isCurrentAccount={isCurrentAccount}
    >
      <MenuText
        className={className}
        isCurrentAccount={isCurrentAccount}
        onClick={(event) => onClick && onClick(event)}
      >
        <AccountInfo
          accountName={accountName}
          isPrimary={isPrimary}
          checked={checked}
          createdBy={createdBy}
          createdAt={createdAt}
          connectedAccounts={connectedAccounts}
          hideVisibilityIcon={hideVisibilityIcon}
          privacy={privacy}
        />
      </MenuText>
    </MenuTextAction>
  )
}

export default AccountDropdownOption
