import styled from 'styled-components'

import { SubmissionSent } from '../SubmissionLink/SubmissionLink'
import { BuyerCard } from 'features/LeadGen/RetailerProfile/components/BuyerCard/BuyerCard'

type PeopleEmptyProps = {
  isRetailer?: boolean
  contactUrl?: string
  submissionSent?: SubmissionSent
}

const StyledBuyerCard = styled(BuyerCard)`
  display: flex;
  margin-top: 24px;
`

const PeopleEmpty = ({
  isRetailer,
  contactUrl,
  submissionSent,
}: PeopleEmptyProps) => {
  const canSendSubmission = contactUrl && !submissionSent

  return (
    <StyledBuyerCard
      name="Administrator"
      contactUrl={canSendSubmission ? contactUrl : undefined}
      submissionSent={submissionSent}
      isRetailer={isRetailer || false}
      iconName="storefront"
    />
  )
}

export default PeopleEmpty
