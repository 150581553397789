import find from 'lodash/find'
import get from 'lodash/get'
import size from 'lodash/size'
import moment from 'moment'

import ArrayOfIdsFilter from 'utils/filters/arrayOfIdsFilter'
import DateFilter from 'utils/filters/dateFilter'
import SingleIdFilter from 'utils/filters/singleIdFilter'
import { toServerDate } from 'utils/formatters'

import {
  BrandDashBoardFilterIds,
  BrandDashBoardHeaderIds,
} from './../BrandDashboard.ids'
import styles from './FilterPanel.component.module.css'
import messages from './FilterPanel.messages'

export const DEFAULT_PRICE_TYPE = {
  text: 'ALL CURRENCIES',
  value: 'ALL_CURRENCIES',
  code: 'ALL_CURRENCIES',
  id: 'ALL_CURRENCIES',
}
export const DEFAULT_CURRENCY_CODE = 'USD'
const DEFAULT_DAYS = 30

export const getCurrencyCode = (brandCurrencies, currency) => {
  let selectedCurrency = DEFAULT_PRICE_TYPE
  if (currency) {
    selectedCurrency = find(brandCurrencies, { id: currency.id })
  }

  return size(brandCurrencies) === 1
    ? brandCurrencies[0].code
    : get(selectedCurrency, 'code', '')
}

export const getAggregateInCurrency = (
  brandCurrencies,
  currency,
  defaultCurrency,
) => {
  const selectedCurrency = currency || DEFAULT_PRICE_TYPE
  return size(brandCurrencies) !== 1 &&
    selectedCurrency.id === DEFAULT_PRICE_TYPE.id
    ? defaultCurrency.code
    : ''
}

export const getDateRangeDefault = () => ({
  startDate: toServerDate(moment().subtract(DEFAULT_DAYS, 'days')),
  endDate: toServerDate(moment().add(1, 'days')),
  isDefault: true,
})

export const dashboardFilters = {
  salesRepresentatives: new ArrayOfIdsFilter({
    filterKey: 'salesRepresentatives',
    label: 'Sales Rep',
    dataKey: 'salesRepresentatives',
    nameFormatter: (filterOption) => filterOption.text,
    styles: styles.selectedFilterDropdown,
  }),
  collections: new ArrayOfIdsFilter({
    filterKey: 'collections',
    label: 'Linesheet',
    dataKey: 'collections',
    nameFormatter: (filterOption) => filterOption.text,
    styles: styles.selectedFilterDropdown,
  }),
  seasonYears: new ArrayOfIdsFilter({
    filterKey: 'seasonYears',
    label: 'Season',
    dataKey: 'seasonYears',
    nameFormatter: (filterOption) => filterOption.text,
    styles: styles.selectedFilterDropdown,
  }),
  retailers: new ArrayOfIdsFilter({
    filterKey: 'retailers',
    label: 'Retailer',
    dataKey: 'retailers',
    nameFormatter: (filterOption) => filterOption.text,
    styles: styles.selectedFilterDropdown,
  }),
  dateRange: new DateFilter({
    filterKey: 'dateRange',
    label: 'Created',
    dataKey: 'dateRange',
    styles,
    messages,
    defaultState: getDateRangeDefault(),
  }),
  currency: new SingleIdFilter({
    filterKey: 'currency',
    label: 'Currency',
    dataKey: 'brandCurrencies',
    styles: styles.selectedFilterDropdown,
  }),
  shippingCountries: new ArrayOfIdsFilter({
    filterKey: 'shippingCountries',
    label: 'Country',
    dataKey: 'shippingCountries',
    nameFormatter: (filterOption) => filterOption.text,
    styles: styles.selectedFilterDropdown,
  }),
}

export const filterSetup = [
  {
    key: 'salesRepresentatives',
    title: 'Sales Rep',
    filters: [dashboardFilters.salesRepresentatives],
  },
  {
    key: 'collections',
    title: 'Linesheet',
    filters: [dashboardFilters.collections],
  },
  {
    key: 'seasonYears',
    title: 'Season',
    filters: [dashboardFilters.seasonYears],
  },
  {
    key: 'retailers',
    title: 'Retailer',
    filters: [dashboardFilters.retailers],
  },
  {
    key: 'dateRange',
    title: 'Created Date',
    filters: [dashboardFilters.createdDateRange],
  },
  {
    key: 'currency',
    title: 'Currency',
    filters: [dashboardFilters.currency],
  },
  {
    key: 'shippingCountries',
    title: 'Country',
    filters: [dashboardFilters.shippingCountries],
  },
]

export const filtersIds = {
  [null]: BrandDashBoardHeaderIds.DashboardFilterBy,
  ['salesRepresentatives']: BrandDashBoardFilterIds.DashboardSalesRep,
  ['collections']: BrandDashBoardFilterIds.DashboardLinesheet,
  ['seasonYears']: BrandDashBoardFilterIds.DashboardSeason,
  ['retailers']: BrandDashBoardFilterIds.DashboardRetailer,
  ['dateRange']: BrandDashBoardFilterIds.DashboardCreated,
  ['currency']: BrandDashBoardFilterIds.DashboardFilterCurrency,
  ['shippingCountries']: BrandDashBoardFilterIds.DashboardCountry,
}
