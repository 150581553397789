import { GammaBadge, Icon } from '@joor/design-system'
import styled from 'styled-components'

import { ITrigger } from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

export enum TriggerTestIds {
  TriggerAnchor = 'trigger-anchor',
  TriggerChevron = 'trigger-chevron',
  TriggerCount = 'trigger-count',
}

const TriggerContainer = styled.div`
  text-decoration-line: none;
  padding: 5px 8px;
  align-self: center;
  display: flex;
  align-items: center;
  line-height: 16px;
  font-weight: var(--font-weight-medium);
  color: var(--color-primary-100);

  @media only screen and (max-width: 1250px) {
    padding: 5px 4px;
  }

  @media only screen and (max-width: 1160px) {
    padding: 5px 2px;
  }

  @media only screen and (max-width: 1100px) {
    padding: 5px 1px;
  }

  @media only screen and (max-width: 1024px) {
    padding: 5px 4px;
  }
`

interface IChevron {
  isInsideDropdown?: boolean
}

const Chevron = styled.div<IChevron>`
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
    fill: ${({ isInsideDropdown }) =>
      isInsideDropdown ? 'var(--color-secondary-800)' : 'white'};
  }

  > span {
    width: 16px;
    height: 16px;
  }

  @media only screen and (max-width: 1024px) {
    margin-left: 4px;
  }
`

const Trigger = ({
  label,
  withArrow = true,
  id,
  isInsideDropdown = false,
  count,
}: ITrigger) => (
  <TriggerContainer data-testid={TriggerTestIds.TriggerAnchor} id={id}>
    {label}
    {!!count && (
      <span
        style={{ marginLeft: '10px' }}
        data-testid={TriggerTestIds.TriggerCount}
      >
        <GammaBadge value={count} />
      </span>
    )}
    {withArrow && (
      <Chevron
        data-testid={TriggerTestIds.TriggerChevron}
        isInsideDropdown={isInsideDropdown}
      >
        <Icon iconName="chevron-down" type="semantic" />
      </Chevron>
    )}
  </TriggerContainer>
)

export default Trigger
