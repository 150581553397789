import { PureComponent } from 'react'

import classNames from 'classnames'
import { filter, flatten, get, isEmpty, map, reduce } from 'lodash'
import PropTypes from 'prop-types'

import { decimalWithCommas, formatNameWithCode } from 'utils/formatters'
import getDisplayName from 'utils/getDisplayName'
import { translateIfFeatureFlag } from 'utils/sdks/localize'
import {
  EMPTY_DELIVERY_WINDOW,
  getAggregatedSkuDeliveryRange,
} from 'utils/transformations/deliveryWindows'
import { fromGlobalId } from 'utils/transformations/graphql'

import { getCopyButton, getPasteButton } from 'shop/TableFormatter'
import {
  getAllProductSkus,
  getSkusPriceRangeByTrade,
} from 'shop/products/getters'
import {
  EMPTY_VALUE,
  PRODUCT_COLOR_CODE,
  PRODUCT_RETAIL_ATTRIBUTE,
  PRODUCT_SIZE_CODE,
  PRODUCT_WHOLESALE_ATTRIBUTE,
} from 'shop/products/traits'
import { getCasepackMultiplier, getVariantByCode } from 'shop/products/utils'

import { Checkbox, Icon, Swatch, Tooltip } from 'components/Core'

import styles from './ProductDetailTableWrapper/ProductDetailTableWrapper.module.css'
import {
  ACCORDION_DOOR_ROW_ID,
  DISCOUNT_ROW_ID,
  FIRST_COLUMN_ID,
  PRICE_ROW_ID,
  QUANTITY_COLUMN_ID,
  SIZED_TABLE_ID,
  SUGG_RETAILER_COLUMN_ID,
  TOTAL_ROW_ID,
  TOTAL_SECTION_ID,
  VARIANT_ROW_ID,
  WHOLESALE_COLUMN_ID,
} from './TablesConfiguration'

const DOOR_NAME_MAX_LENGTH = 25

export const withFormatter = (WrappedComponent) => {
  class Wrapper extends PureComponent {
    state = {
      doorsOpenStatus: {},
      doorsAreInitialized: false,
    }

    componentDidMount = () => {
      this.initializeDoorAccordions()
    }

    componentDidUpdate = () => {
      this.initializeDoorAccordions()
    }

    setDoorOpenState = (isOpenStatus) => {
      const { product, variantRowCode } = this.props
      const { variants } = product
      const variantRow = getVariantByCode(variants, variantRowCode)
      const sectionsIds = map(get(variantRow, 'values'), (value) => value.id)

      const doorsOpenStatus = reduce(
        sectionsIds,
        (acc, id) => ({
          ...acc,
          [id]: isOpenStatus,
        }),
        {},
      )
      this.setState({
        doorsOpenStatus,
        doorsAreInitialized: true,
      })
    }

    getSimpleCell = (value, translate = true, className) => ({
      props: {
        className: classNames(styles.column, translate ? '' : 'notranslate'),
      },
      children: <div className={className}>{value}</div>,
    })

    getCellWithProps = (value, extraProps, translate = true) => ({
      props: {
        className: classNames(styles.column, translate ? '' : 'notranslate'),
        ...extraProps,
      },
      children: <div>{value}</div>,
    })

    getDoorHeader = () => {
      const { doors } = this.props
      const isOpenStatus = this.checkDoorsOpenStatus()
      const accordionLabel = (
        <div
          className={styles.doorsHeader}
          onClick={() => this.setDoorOpenState(isOpenStatus)}
        >
          <Icon name={isOpenStatus ? 'plus' : 'minus'} />
          <span>{isOpenStatus ? 'Show all doors' : 'Hide all doors'}</span>
        </div>
      )

      return doors.length <= 1
        ? this.getSimpleCell()
        : this.getSimpleCell(accordionLabel)
    }

    getQuantityHeader = () => {
      const {
        product: { orderMinimum },
      } = this.props
      const quantityTooltipContent = (
        <span>
          Style minimum:{' '}
          <var count="true" pluralize={orderMinimum}>
            {orderMinimum}
          </var>{' '}
          unit
        </span>
      )
      const quantityTooltipTrigger = (
        <div>
          <span>Quantity</span>
          <span className={styles.orderMin}>*</span>
        </div>
      )
      return orderMinimum > 0 ? (
        <Tooltip
          content={quantityTooltipContent}
          trigger={quantityTooltipTrigger}
          position="top center"
        />
      ) : (
        <span>Quantity</span>
      )
    }

    getCommonHeaderColumns = () => {
      const { currencyCode, retailCurrency } = this.props
      const currencyLabel = <span className="notranslate">{currencyCode}</span>
      const wholesaleLabel = <span>Wholesale ({currencyLabel})</span>
      const retCurrLabel = <span className="notranslate">{retailCurrency}</span>
      const suggRetailLabel = <span>Sugg. Retail ({retCurrLabel})</span>
      const commonExtraProps = {
        className: classNames(styles.commonColumns, styles.column),
      }

      return [
        this.getDoorHeader(),
        this.getCellWithProps(this.getQuantityHeader(), commonExtraProps),
        this.getCellWithProps(wholesaleLabel, commonExtraProps),
        this.getCellWithProps(suggRetailLabel, commonExtraProps),
      ]
    }

    generatePriceRow = (id, wholesaleValue, retailValue) => ({
      id,
      props: {
        small: true,
        secondary: true,
        secondaryBorder: true,
        skipNavigation: true,
      },
      columns: [
        this.getSimpleCell('Price'),
        this.getSimpleCell('', false),
        this.getSimpleCell(this.getFormattedPrice(wholesaleValue), false),
        this.getSimpleCell(this.getFormattedPrice(retailValue), false),
      ],
    })

    getPriceRow = (variantValue) => {
      const { product, priceTypeId } = this.props
      const productSkus = getAllProductSkus(product, variantValue)
      const wholesaleValue = getSkusPriceRangeByTrade(
        productSkus,
        priceTypeId,
        PRODUCT_WHOLESALE_ATTRIBUTE,
      )
      const retailValue = getSkusPriceRangeByTrade(
        productSkus,
        priceTypeId,
        PRODUCT_RETAIL_ATTRIBUTE,
      )
      return this.generatePriceRow(PRICE_ROW_ID, wholesaleValue, retailValue)
    }

    getDiscountRow = () => ({
      id: DISCOUNT_ROW_ID,
      props: {
        small: true,
        skipNavigation: true,
        primaryBorder: false,
        secondaryBorder: true,
      },
      columns: [
        this.getSimpleCell('Discounts'),
        this.getSimpleCell(''),
        this.getSimpleCell(''),
        this.getSimpleCell(''),
      ],
    })

    getVariantRow = (variantValue, localizeUserData) => {
      const {
        doors,
        trackCheckboxClick,
        skuDeliveries,
        setRowToCopy,
        pasteToRow,
        flags: { skuDeliveries: skuDeliveriesFlag },
        userDateFormat,
      } = this.props
      const firstColumn = this.getSimpleCell(get(variantValue, 'value'))
      const { id: sectionId } = variantValue
      const hasDoors = !isEmpty(doors)

      if (get(variantValue, 'trait.code') === PRODUCT_COLOR_CODE) {
        const swatch = {
          colorNumber: get(variantValue, 'displayDetails.hexColor'),
          url: get(variantValue, 'displayDetails.swatchImage.url', null),
        }
        const {
          value: skuName,
          externalIdentifier: skuCode,
          orderMinimum: orderMin,
        } = variantValue
        const tooltipContent = (
          <span>
            Color minimum (for each door):{' '}
            <var count="true" pluralize={orderMin}>
              {orderMin}
            </var>{' '}
            unit
          </span>
        )
        const tooltipTrigger = (
          <div>
            <span className={styles.orderMin}>*</span>
          </div>
        )
        const formattedVariantName = formatNameWithCode(skuName, skuCode)
        const innerContent = hasDoors ? (
          <span>{formattedVariantName}</span>
        ) : (
          <div className={styles.variantTextContainer}>
            <span>{formattedVariantName}</span>
            <div className={styles.buttonContainer}>
              {getCopyButton(() => {
                setRowToCopy({ variantId: variantValue?.id })
              })}
              {getPasteButton(() => {
                pasteToRow({
                  variantId: variantValue?.id,
                })
              })}
            </div>
          </div>
        )
        const skuDeliveryRange = getAggregatedSkuDeliveryRange(
          skuDeliveries[fromGlobalId(fromGlobalId(variantValue.id).id).id],
          userDateFormat,
        )
        const shouldDisplayDeliveryTooltip =
          skuDeliveryRange !== EMPTY_DELIVERY_WINDOW && skuDeliveriesFlag
        firstColumn.children = (
          <div className={styles.firstVariantCellContent}>
            <Checkbox
              checked={this.isVariantNoted(sectionId)}
              className={styles.checkbox}
              onClick={(e, { checked }) => {
                if (checked) {
                  trackCheckboxClick(formattedVariantName)
                }
                this.handleVariantRowNotes(sectionId, checked)
              }}
            />
            <div className={styles.swatch}>
              <Swatch
                swatch={swatch}
                color={{ colorName: formattedVariantName }}
                width={20}
                height={20}
              />
            </div>
            <span
              className={classNames(
                styles.variantName,
                translateIfFeatureFlag(localizeUserData),
              )}
            >
              {shouldDisplayDeliveryTooltip ? (
                <Tooltip
                  content={`Delivery: ${skuDeliveryRange}`}
                  trigger={innerContent}
                  position="right center"
                  className={styles.tooltipSize}
                />
              ) : (
                innerContent
              )}
            </span>
            {orderMin > 0 && (
              <Tooltip
                content={tooltipContent}
                trigger={tooltipTrigger}
                position="right center"
                className={styles.tooltipSize}
              />
            )}
          </div>
        )
      }

      return {
        id: VARIANT_ROW_ID,
        props: {
          secondaryBorder: true,
          skipNavigation: hasDoors,
        },
        columns: [firstColumn],
      }
    }

    getDoorAccordionRow = (sectionId) => {
      const { doorsOpenStatus } = this.state
      const columns = []
      const isDoorOpen = doorsOpenStatus[sectionId]
      const firstColumn = this.getSimpleCell()
      firstColumn.children = (
        <div
          className={styles.doorsAccordion}
          onClick={() => this.toggleDoorState(sectionId)}
        >
          <Icon
            name={isDoorOpen ? 'minus' : 'plus'}
            className={styles.plusIcon}
          />
          <span>{isDoorOpen ? 'Hide all doors' : 'Show all doors'}</span>
        </div>
      )
      columns.push(firstColumn) // TODO: add rest columns
      return {
        id: ACCORDION_DOOR_ROW_ID,
        props: { secondaryBorder: true, skipNavigation: true },
        columns,
      }
    }

    getDoorRows = (variantValue) => {
      const { doors, setRowToCopy, pasteToRow } = this.props
      const { doorsOpenStatus } = this.state

      const doorRows = map(doors, (door) => {
        const innerContent = (
          <div className={styles.variantTextContainer}>
            <span className={styles.doorRow}>{door.text}</span>
            <div className={styles.buttonContainer}>
              {getCopyButton(() => {
                setRowToCopy({
                  variantId: variantValue.id,
                  door: door.value,
                })
              })}
              {getPasteButton(() => {
                pasteToRow({
                  variantId: variantValue.id,
                  door: door.value,
                })
              })}
            </div>
          </div>
        )
        const cellContent =
          door.text.length <= DOOR_NAME_MAX_LENGTH ? (
            innerContent
          ) : (
            <Tooltip
              content={<span>{door.text}</span>}
              trigger={innerContent}
              position="top center"
              className={styles.tooltipSize}
              data-testid="doorTooltip"
            />
          )

        return [
          {
            id: door.key,
            props: { secondaryBorder: true },
            columns: [this.getSimpleCell(cellContent, false, styles.doorCell)],
          },
        ]
      })

      return [
        ...(doors.length > 1
          ? [this.getDoorAccordionRow(variantValue.id)]
          : []),
        ...(doorsOpenStatus[variantValue.id] ? flatten(doorRows) : []),
      ]
    }

    getSections = (localizeUserData) => {
      const { product, variantRowCode, discounts } = this.props
      const { variants } = product
      const variantRow = getVariantByCode(variants, variantRowCode)

      return reduce(
        get(variantRow, 'values'),
        (sections, variantValue) => {
          const { id } = variantValue
          const section = {
            id,
            variantValue,
            rows: [
              this.getPriceRow(variantValue),
              ...(!isEmpty(discounts) ? [this.getDiscountRow()] : []),
              this.getVariantRow(variantValue, localizeUserData),
              ...this.getDoorRows(variantValue),
            ],
          }
          sections.set(id, section)
          return sections
        },
        new Map(),
      )
    }

    getNewPrice = (value = { min: 0, max: 0 }, quantity = 0) => ({
      min: value.min * quantity,
      max: value.max * quantity,
    })

    // TODO: use the function that is in shop/formatters
    getFormattedPrice = (price) => {
      if (price) {
        const min = decimalWithCommas(price.min) || EMPTY_VALUE
        const max = decimalWithCommas(price.max) || EMPTY_VALUE
        return min === max ? `${min}` : `${min} - ${max}`
      }
      return ''
    }

    setTotalRowValues = (cellParams, previousValue, newValue) => {
      const quantity = newValue - (previousValue || 0)

      this.setTotalValueFromColumn(cellParams, quantity)
      this.setTotalValueOfQuantityColumn(cellParams, quantity)
      this.setTotalValueOfWholesaleAndRetailColumns(cellParams, quantity)
    }

    setTotalValueFromColumn = (cellParams, quantity) => {
      const { getCellValueFromTable, updateCellValue } = this.props
      const oldTotalFromColumn = getCellValueFromTable({
        ...cellParams,
        sectionId: TOTAL_SECTION_ID,
        rowId: TOTAL_ROW_ID,
      })
      updateCellValue({
        ...cellParams,
        sectionId: TOTAL_SECTION_ID,
        rowId: TOTAL_ROW_ID,
        value: oldTotalFromColumn + quantity,
      })
    }

    setTotalValueOfQuantityColumn = (cellParams, quantity) => {
      const {
        getCellValueFromTable,
        updateCellValue,
        product: { casepacks },
      } = this.props
      const totalRowCellParams = {
        ...cellParams,
        sectionId: TOTAL_SECTION_ID,
        rowId: TOTAL_ROW_ID,
        columnId: QUANTITY_COLUMN_ID,
      }
      const casepackMultiplier = getCasepackMultiplier(
        cellParams.columnId,
        casepacks,
      )
      const oldTotalQuantity = getCellValueFromTable(totalRowCellParams)
      updateCellValue({
        ...totalRowCellParams,
        value: oldTotalQuantity + quantity * casepackMultiplier,
      })
    }

    setTotalValueOfWholesaleAndRetailColumns = (cellParams, quantity) => {
      const { getCellValueFromTable, updateCellValue } = this.props
      const totalRowCellParams = {
        ...cellParams,
        sectionId: TOTAL_SECTION_ID,
        rowId: TOTAL_ROW_ID,
      }
      const {
        wholesalePrice: oldTotalWholesalePrice,
        retailPrice: oldTotalRetailPrice,
      } = this.getRowPrices(totalRowCellParams)
      const prices = getCellValueFromTable({
        ...cellParams,
        rowId: PRICE_ROW_ID,
      })
      const addedWholesalePrice = this.getNewPrice(
        prices.wholesalePrice,
        quantity,
      )
      const addedRetailPrice = this.getNewPrice(prices.retailPrice, quantity)

      updateCellValue({
        ...totalRowCellParams,
        columnId: WHOLESALE_COLUMN_ID,
        value: {
          min: oldTotalWholesalePrice.min + addedWholesalePrice.min,
          max: oldTotalWholesalePrice.max + addedWholesalePrice.max,
        },
      })
      updateCellValue({
        ...totalRowCellParams,
        columnId: SUGG_RETAILER_COLUMN_ID,
        value: {
          min: oldTotalRetailPrice.min + addedRetailPrice.min,
          max: oldTotalRetailPrice.max + addedRetailPrice.max,
        },
      })
    }

    getRowQuantity = (cellParams) => {
      const { getCellValueFromTable } = this.props
      return getCellValueFromTable({
        ...cellParams,
        columnId: QUANTITY_COLUMN_ID,
      })
    }

    getRowPrices = (cellParams) => {
      const { getCellValueFromTable } = this.props
      const wholesalePrice = getCellValueFromTable({
        ...cellParams,
        columnId: WHOLESALE_COLUMN_ID,
      })
      const retailPrice = getCellValueFromTable({
        ...cellParams,
        columnId: SUGG_RETAILER_COLUMN_ID,
      })
      return { wholesalePrice, retailPrice }
    }

    isVariantNoted = (sectionId) => {
      const { getCellValueFromTable, tableId } = this.props
      return getCellValueFromTable({
        tableId,
        sectionId,
        rowId: VARIANT_ROW_ID,
        columnId: FIRST_COLUMN_ID,
      })
    }

    handleVariantRowNotes = (sectionId, checked) => {
      const { updateCellValue, tableId } = this.props

      updateCellValue({
        tableId,
        sectionId,
        rowId: VARIANT_ROW_ID,
        columnId: FIRST_COLUMN_ID,
        value: checked,
      })
    }

    initializeDoorAccordions = () => {
      const { isProductReady } = this.props
      const { doorsAreInitialized } = this.state
      if (isProductReady && !doorsAreInitialized) {
        this.setDoorOpenState(true)
      }
    }

    checkDoorsOpenStatus = () => {
      const { doorsOpenStatus } = this.state
      return !isEmpty(filter(doorsOpenStatus, (isOpen) => !isOpen))
    }

    toggleDoorState = (id) => {
      this.setState((prevState) => ({
        doorsOpenStatus: {
          ...prevState.doorsOpenStatus,
          [id]: !prevState.doorsOpenStatus[id],
        },
      }))
    }

    render() {
      const {
        variantRowCode: _variantRowCode,
        variantColumnCode: _variantColumnCode,
        availabilityGroupsItems,
        tableId,
        setRowToCopy: _setRowToCopy,
        pasteToRow: _pasteToRow,
        ...rest
      } = this.props
      const {
        flags: { localizeUserData },
      } = this.props

      return (
        <WrappedComponent
          {...{
            headerCols: this.getCommonHeaderColumns(),
            sections: this.getSections(localizeUserData),
            getSimpleCell: this.getSimpleCell,
            getCellWithProps: this.getCellWithProps,
            setTotalRowValues: this.setTotalRowValues,
            getRowQuantity: this.getRowQuantity,
            getRowPrices: this.getRowPrices,
            getNewPrice: this.getNewPrice,
            getFormattedPrice: this.getFormattedPrice,
            tableId,
            ...(tableId === SIZED_TABLE_ID && { availabilityGroupsItems }),
          }}
          {...rest}
        />
      )
    }
  }

  Wrapper.displayName = `withFormatter(${getDisplayName(WrappedComponent)})`
  Wrapper.propTypes = {
    product: PropTypes.object.isRequired,
    doors: PropTypes.array,
    currencyCode: PropTypes.string.isRequired,
    retailCurrency: PropTypes.string.isRequired,
    priceTypeId: PropTypes.string.isRequired,
    tableId: PropTypes.string,
    isProductReady: PropTypes.bool,
    variantRowCode: PropTypes.string,
    variantColumnCode: PropTypes.string,
    updateCellValue: PropTypes.func,
    availabilityGroupsItems: PropTypes.object,
    discounts: PropTypes.array,
    getCellValueFromTable: PropTypes.func,
    trackCheckboxClick: PropTypes.func,
    skuDeliveries: PropTypes.object,
  }
  Wrapper.defaultProps = {
    doors: [],
    flags: {
      skuDeliveries: false,
      localizeUserData: false,
    },
    tableId: SIZED_TABLE_ID,
    isProductReady: true,
    variantRowCode: PRODUCT_COLOR_CODE,
    variantColumnCode: PRODUCT_SIZE_CODE,
    updateCellValue: () => {},
    availabilityGroupsItems: new Map(),
    discounts: [],
    getCellValueFromTable: () => {},
    trackCheckboxClick: () => {},
    skuDeliveries: {},
  }
  return Wrapper
}

export default withFormatter
