import { ChangeEvent, useState } from 'react'

import {
  Checkbox,
  Modal,
  ModalProvider,
  PrimaryButton,
  SquareDotLoadingIndicator,
} from '@joor/design-system'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { fromGlobalId } from 'utils/transformations/graphql'

import usePortal from 'hooks/usePortal'
import { useRenderToasters } from 'hooks/useRenderToasters'
import { getAccountId } from 'store/currentUser/selectors'

import {
  LiteRetailerAccountsType,
  useGetLiteRetailerAccountsInfo,
} from '../../NavbarLiteRetailer.queries'
import AccountOption from '../../components/AccountOption/AccountOption'
import { useUpdateAccountsPrivacy } from 'features/Navbar/NavbarLiteRetailer/NavbarLiteRetailer.mutations'

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const AccountsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  max-height: 330px;
  overflow-y: auto;
`

const AccountContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledCheckbox = styled(Checkbox)`
  margin-right: 10px;
`

const ModalContent = ({
  accounts,
  onClose = () => {},
}: {
  accounts: LiteRetailerAccountsType[]
  onClose: () => void
}) => {
  const accountId = useSelector(getAccountId)
  const { updateAccountsPrivacy } = useUpdateAccountsPrivacy()
  const { renderSuccessToast, renderErrorToast } = useRenderToasters()

  // None primary accounts can be hidden
  const selectableAccounts = accounts.filter((item) => !item.isPrimary)

  const checkAccountsDataChecked = selectableAccounts
    ? Object.fromEntries(selectableAccounts.map((item) => [item.id, true]))
    : {}
  const checkAccountsDataUnchecked = selectableAccounts
    ? Object.fromEntries(selectableAccounts.map((item) => [item.id, false]))
    : {}

  /**
   * If privacy === false => hidden (checkbox checked)
   * if privacy === true => visible (checkbox unchecked)
   */
  const checkAccountPrivacy = (idToCompare: string) => {
    const account = selectableAccounts?.find(
      (account) => account.id === idToCompare,
    )

    return !account?.privacy
  }

  const accountsPrivacyChecked =
    selectableAccounts?.reduce(
      (acc, item) => {
        acc[item.id] = checkAccountPrivacy(item.id)
        return acc
      },
      {} as {
        [key: string]: boolean
      },
    ) || {}

  const allCheckedInitialState =
    selectableAccounts.length > 0 &&
    Object.values(accountsPrivacyChecked).every((privacy) => !!privacy)

  const [allAccountsChecked, setAllAccountsChecked] = useState(
    allCheckedInitialState,
  )
  const [accountsChecked, setAccountsChecked] = useState(
    selectableAccounts?.length
      ? accountsPrivacyChecked
      : checkAccountsDataChecked,
  )

  const handleHideAccountCheckbox = (
    event: ChangeEvent<HTMLInputElement>,
    userAccount: LiteRetailerAccountsType,
  ) => {
    if (!event.target.checked && allAccountsChecked) {
      setAllAccountsChecked(false)
    }

    if (
      Object.values(accountsChecked).filter((check) => !!check).length ===
        Object.keys(accountsChecked).length - 1 &&
      event.target.checked
    ) {
      setAllAccountsChecked(true)
    }

    setAccountsChecked({
      ...accountsChecked,
      [userAccount.id]: event.target.checked,
    })
  }

  const saveAccounts = () => {
    const changedAccounts = Object.entries(accountsChecked)
      .filter(([id, isChecked]) => {
        const userAccount = selectableAccounts.find((u) => u.id === id)
        const privacy = userAccount && checkAccountPrivacy(userAccount.id)
        return isChecked !== privacy
      })
      .map(([id, isChecked]) => ({
        accountId: id,
        privacy: !isChecked,
      }))

    if (changedAccounts.length) {
      const accountsHidden = changedAccounts.reduce((num, account) => {
        return account.privacy ? num : num + 1
      }, 0)
      if (accountsHidden > 0) {
        window.pendo.track('Account_Visibility_Modal', {
          accountsHidden,
        })
      }
      updateAccountsPrivacy(changedAccounts).then((resp) => {
        if (resp.errors && !isEmpty(resp.errors)) {
          renderErrorToast({
            title: 'Account Settings Not Updated',
            description: 'There was an error hiding your selected accounts.',
          })
        } else {
          renderSuccessToast({
            title: 'Account Settings Updated',
            description:
              'Your selected accounts have been hidden from being found by new brands.' +
              ' It may take 30 minutes for this change to take effect.',
          })
        }
      })
    }
    onClose()
  }

  return (
    <>
      <Modal.Content>
        Hide accounts from being discovered:
        <AccountsWrapper>
          {accounts?.map((account) => (
            <AccountContainer key={account.id}>
              <StyledCheckbox
                id={account.id}
                onChange={(event) => handleHideAccountCheckbox(event, account)}
                size="regular"
                checked={accountsChecked[account.id] ?? false}
                disabled={account.isPrimary}
              />
              <AccountOption
                accountName={`${account.name} (${fromGlobalId(account.id).id})`}
                isPrimary={account.isPrimary}
                checked={String(accountId) === fromGlobalId(account.id).id}
                privacy={account.privacy}
                createdAt={account.createdAt.toString()}
                connectedAccounts={account.connectedAccounts}
                createdBy={
                  account.accountCreationSource?.requestedByAccount &&
                  account.accountCreationSource.requestedByAccount.id ===
                    account.id
                    ? 'you'
                    : account.accountCreationSource?.requestedByAccount?.name
                }
              />
            </AccountContainer>
          ))}
        </AccountsWrapper>
      </Modal.Content>
      <Modal.Actions>
        <ActionsWrapper>
          <Checkbox
            id={'check-all-accounts'}
            label="All Accounts"
            onChange={(event) => {
              setAccountsChecked(
                event.target.checked
                  ? checkAccountsDataChecked
                  : checkAccountsDataUnchecked,
              )
              setAllAccountsChecked(event.target.checked)
            }}
            size="regular"
            checked={allAccountsChecked}
          />
          <PrimaryButton onClick={saveAccounts}>Save</PrimaryButton>
        </ActionsWrapper>
      </Modal.Actions>
    </>
  )
}

type ModalProps = {
  isOpen: boolean
  onClose?: () => void
}

const AccountsVisibilityModal = ({
  isOpen,
  onClose = () => {},
}: ModalProps) => {
  const Portal = usePortal()
  const { accounts, accountsPrivacyLoading } = useGetLiteRetailerAccountsInfo()

  const subtitle = `You have marked this account as primary, 
  but you are also associated with the accounts listed below. 
  Checked accounts below will be hidden from being discovered by new brands. 
  Unchecked accounts will be visible to new brands.
  It may take 30 minutes for any changes to take effect.`

  return (
    <Portal>
      <ModalProvider>
        <Modal
          isOpen={isOpen}
          title={{ text: 'Account Visibility Settings' }}
          subtitle={subtitle}
          onClose={onClose}
          size="medium"
        >
          {accountsPrivacyLoading ? (
            <>
              <SquareDotLoadingIndicator />
              <Modal.Actions>
                <ActionsWrapper>
                  <Checkbox
                    id={'check-all-accounts'}
                    label="All Accounts"
                    size="regular"
                    disabled
                  />
                  <PrimaryButton disabled>Save</PrimaryButton>
                </ActionsWrapper>
              </Modal.Actions>
            </>
          ) : (
            <ModalContent accounts={accounts} onClose={onClose} />
          )}
        </Modal>
      </ModalProvider>
    </Portal>
  )
}

export default AccountsVisibilityModal
