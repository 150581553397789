import { useMutation, useQuery } from '@apollo/client'
import { useNotifications } from '@joor/design-system'

import {
  ConnectionModalDataDocument,
  LGConnectionStatus,
  RequestConnectionToRetailerDocument,
  RequestConnectionToRetailerInput,
} from '__generated__/atlas-types'

import { formatNameWithCodeAndDash } from 'utils/formatters'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

atlas`#graphql
  query ConnectionModalData ($brandId: ID!, $retailerId: ID!) {
    lgGetRequestConnectionData(brandId: $brandId, retailerId: $retailerId) {
      inviteMessage
      priceTypes {
        id
        name
        currencyName
      }
      termsConditions {
        id
        name
      }
      otherUserBrands {
        id
        name
        priceTypes {
          id
          name
          currencyName
        }
      }
    }
  }
`

export const useModalData = (brandId: string, retailerId: string) => {
  const { data, loading } = useQuery(ConnectionModalDataDocument, {
    client: atlasClient,
    variables: {
      retailerId,
      brandId,
    },
    skip: !brandId || !retailerId,
    fetchPolicy: 'no-cache',
  })
  const inviteMessage = data?.lgGetRequestConnectionData?.inviteMessage ?? ''
  const termsAndConditions =
    data?.lgGetRequestConnectionData?.termsConditions ?? []
  const priceTypes = data?.lgGetRequestConnectionData?.priceTypes ?? []
  const otherUserBrands =
    data?.lgGetRequestConnectionData?.otherUserBrands ?? []

  return {
    inviteMessage,
    termsAndConditions: Boolean(termsAndConditions.length)
      ? termsAndConditions.map((term) => ({
          label: term?.name,
          value: term?.id,
        }))
      : termsAndConditions,
    priceTypes: Boolean(priceTypes.length)
      ? priceTypes.map((priceType) => ({
          label: formatNameWithCodeAndDash(
            priceType?.currencyName,
            priceType?.name,
          ),
          value: priceType?.id,
        }))
      : priceTypes,
    otherUserBrands,
    loading,
  }
}

atlas`#graphql
  mutation RequestConnectionToRetailer($input: RequestConnectionToRetailerInput!) {
    requestConnectionToRetailer(input: $input) {
      brandId
      connectionStatus
      error
    }
  }
`
export const useSendConnectionRequest = (
  retailerId: string,
  brandId: string,
) => {
  const [requestConnectionToRetailer, { loading }] = useMutation(
    RequestConnectionToRetailerDocument,
    {
      client: atlasClient,
      update(cache, { data }) {
        const updatedConnection = data?.requestConnectionToRetailer.find(
          (el) => el.brandId === brandId,
        )
        if (!updatedConnection?.error) {
          cache.modify({
            id: cache.identify({
              __typename: 'LGRetailer',
              id: retailerId,
            }),
            fields: {
              connectionStatus: () =>
                updatedConnection?.connectionStatus ??
                LGConnectionStatus.PENDING_RETAILER,
            },
          })
        }
      },
    },
  )
  const { addToasts } = useNotifications()

  const handleRequestConnectionToRetailer = async (
    input: RequestConnectionToRetailerInput,
    retailerName: string,
  ) => {
    const result = await requestConnectionToRetailer({ variables: { input } })

    const error = !!result?.data?.requestConnectionToRetailer.find(
      (x) => x.error,
    )

    if (error) {
      addToasts([
        {
          type: 'warning',
          title: 'Oh no! Some of your connection requests failed.',
          description: 'There was an error sending your request',
        },
      ])
      return
    }
    const connectedAccounts = result?.data?.requestConnectionToRetailer.filter(
      (el) => el.connectionStatus === LGConnectionStatus.CONNECTED,
    )
    const pendingAccounts = result?.data?.requestConnectionToRetailer.filter(
      (el) => el.connectionStatus === LGConnectionStatus.PENDING_RETAILER,
    )
    addToasts([
      {
        type: 'success',
        title: 'Success!',
        description: Boolean(connectedAccounts?.length)
          ? `You are now connected to ${retailerName}.`
          : pendingAccounts?.length === 1
          ? 'Your connection request has been sent.'
          : 'Your connection requests has been sent.',
      },
    ])
  }

  return { handleRequestConnectionToRetailer, loading }
}
