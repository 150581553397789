import messages from './ConnectionRequestModal.messages'
import { FileErrors, SupportedMimeTypes } from './ConnectionRequestModal.types'

export const CONSOLE_LOG_KEY = '[ConnectionRequestModal]'

export const HELP_CENTER_URL =
  'https://help.jooraccess.com/hc/en-us/articles/25745896324251-Adding-Customer-Specific-Data-to-each-Retailer-Connection'

export const CUSTOMER_DATA_TEMPLATE_URL =
  'https://cdn.jooraccess.com/external/templates/JOOR_Pro_Customer_Data_Template.xlsx'
export const TEMPLATE_FILE_MAX_SIZE = 4 * 1024 * 1024 // 4MB in bytes
export const TEMPLATE_FILE_ACCEPTED_FORMAT = [
  SupportedMimeTypes.CSV,
  SupportedMimeTypes.XLS,
  SupportedMimeTypes.XLSX,
  SupportedMimeTypes.NUMBERS,
].join(',')
export const MAX_AMOUNT_OF_FILES = 1

export const FILE_ERROR_MESSAGES: Record<string, string> = {
  [FileErrors.TOO_MANY_FILES]: messages.errorToManyFiles,
  [FileErrors.FILE_INVALID_TYPE]: messages.errorInvalidFileType,
  [FileErrors.FILE_TOO_LARGE]: messages.errorFileTooLarge,
}
