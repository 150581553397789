import { ChangeEvent, useCallback, useMemo, useState } from 'react'

import {
  Checkbox,
  Modal,
  ModalProvider,
  PrimaryButton,
} from '@joor/design-system'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { fromGlobalId } from 'utils/transformations/graphql'

import usePortal from 'hooks/usePortal'
import { useRenderToasters } from 'hooks/useRenderToasters'
import { getAccountId } from 'store/currentUser/selectors'

import {
  LiteRetailerAccountsType,
  RecentlyCreatedAccountType,
} from '../../NavbarLiteRetailer.queries'
import AccountOption from '../../components/AccountOption/AccountOption'
import { useAcknowledgeRecentlyCreatedAccounts } from './recentCreatedAccountsModal.mutations'
import { useUpdateAccountsPrivacy } from 'features/Navbar/NavbarLiteRetailer/NavbarLiteRetailer.mutations'

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const AccountsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  max-height: 330px;
  overflow-y: auto;
`

const AccountContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledCheckbox = styled(Checkbox)`
  margin-right: 10px;
`

const ModalContent = ({
  accounts,
  onClose = () => {},
}: {
  accounts: LiteRetailerAccountsType[]
  onClose: () => void
}) => {
  const accountId = useSelector(getAccountId)
  const {
    updateAccountsPrivacy,
    loading: updateAccountsPrivacyLoading,
  } = useUpdateAccountsPrivacy()

  const { renderSuccessToast, renderErrorToast } = useRenderToasters()

  // Primary accounts cannot be hidden
  const selectableAccounts = accounts.filter((item) => !item.isPrimary)

  const checkAccountsDataChecked = selectableAccounts
    ? Object.fromEntries(selectableAccounts.map((item) => [item.id, true]))
    : {}
  const checkAccountsDataUnchecked = selectableAccounts
    ? Object.fromEntries(selectableAccounts.map((item) => [item.id, false]))
    : {}

  /**
   * The opposite logic we have in the account visibility modal since in this case we
   * want to select by default the accounts that are visible.
   *
   * If privacy === false => hidden (checkbox unChecked)
   * if privacy === true => visible (checkbox checked)
   *
   */
  const checkAccountPrivacy = useCallback(
    (idToCompare: string) => {
      const account = selectableAccounts?.find(
        (account) => account.id === idToCompare,
      )

      return !!account?.privacy
    },
    [selectableAccounts],
  )

  const accountsPrivacyChecked =
    selectableAccounts?.reduce(
      (acc, item) => {
        acc[item.id] = checkAccountPrivacy(item.id)
        return acc
      },
      {} as {
        [key: string]: boolean
      },
    ) || {}

  const allCheckedInitialState =
    selectableAccounts.length > 0 &&
    Object.values(accountsPrivacyChecked).every((privacy) => !!privacy)

  const [allAccountsChecked, setAllAccountsChecked] = useState(
    allCheckedInitialState,
  )
  const [accountsChecked, setAccountsChecked] = useState(
    selectableAccounts?.length
      ? accountsPrivacyChecked
      : checkAccountsDataChecked,
  )

  const changedAccounts = useMemo(() => {
    return Object.entries(accountsChecked).filter(([id, isChecked]) => {
      const userAccount = selectableAccounts.find((u) => u.id === id)
      const privacy = userAccount && checkAccountPrivacy(userAccount.id)
      // we only want to hide accounts, not to make them visible
      // in case they were already hiden we don't want to update them.
      return isChecked && isChecked === privacy
    })
  }, [accountsChecked, checkAccountPrivacy, selectableAccounts])

  // console.log('changedAccounts', changedAccounts)

  const handleHideAccountCheckbox = (
    event: ChangeEvent<HTMLInputElement>,
    userAccount: LiteRetailerAccountsType,
  ) => {
    if (!event.target.checked && allAccountsChecked) {
      setAllAccountsChecked(false)
    }

    if (
      Object.values(accountsChecked).filter((check) => !!check).length ===
        Object.keys(accountsChecked).length - 1 &&
      event.target.checked
    ) {
      setAllAccountsChecked(true)
    }

    setAccountsChecked({
      ...accountsChecked,
      [userAccount.id]: event.target.checked,
    })
  }

  const saveAccounts = () => {
    const accountsToUpdate = changedAccounts.map(([id, isChecked]) => ({
      accountId: id,
      privacy: !isChecked,
    }))

    if (accountsToUpdate.length) {
      const accountsHidden = accountsToUpdate.reduce(
        (num: number, account: { privacy: boolean }) => {
          return account.privacy ? num : num + 1
        },
        0,
      )
      if (accountsHidden > 0) {
        window.pendo.track('New_Accounts_Created_Modal', {
          accountsHidden,
        })
      }
      updateAccountsPrivacy(accountsToUpdate).then((resp) => {
        if (resp.errors && !isEmpty(resp.errors)) {
          renderErrorToast({
            title: 'Account Settings Not Updated',
            description: 'There was an error hiding your selected accounts.',
          })
        } else {
          renderSuccessToast({
            title: 'Account Settings Updated',
            description:
              'Your selected accounts have been hidden from being found by new brands.' +
              ' It may take 30 minutes for this change to take effect.',
          })
        }
      })
    }
    onClose()
  }

  return (
    <>
      <Modal.Content>
        <AccountsWrapper>
          {accounts?.map((account) => (
            <AccountContainer key={account.id}>
              <StyledCheckbox
                id={account.id}
                onChange={(event) => handleHideAccountCheckbox(event, account)}
                size="regular"
                checked={accountsChecked[account.id] ?? false}
                disabled={account.isPrimary}
              />
              <AccountOption
                accountName={`${account.name} (${fromGlobalId(account.id).id})`}
                isPrimary={account.isPrimary}
                checked={String(accountId) === fromGlobalId(account.id).id}
                privacy={account.privacy}
                createdAt={account.createdAt?.toString()}
                connectedAccounts={account.connectedAccounts}
                createdBy={
                  account.accountCreationSource?.requestedByAccount &&
                  account.accountCreationSource.requestedByAccount.id ===
                    account.id
                    ? 'you'
                    : account.accountCreationSource?.requestedByAccount?.name
                }
              />
            </AccountContainer>
          ))}
        </AccountsWrapper>
      </Modal.Content>
      <Modal.Actions>
        <ActionsWrapper>
          <Checkbox
            id={'check-all-accounts'}
            label="All Accounts"
            onChange={(event) => {
              setAccountsChecked(
                event.target.checked
                  ? checkAccountsDataChecked
                  : checkAccountsDataUnchecked,
              )
              setAllAccountsChecked(event.target.checked)
            }}
            size="regular"
            checked={allAccountsChecked}
          />
          <PrimaryButton
            onClick={saveAccounts}
            loading={updateAccountsPrivacyLoading}
            disabled={changedAccounts.length === 0}
          >
            Save
          </PrimaryButton>
        </ActionsWrapper>
      </Modal.Actions>
    </>
  )
}

type ModalProps = {
  isOpen: boolean
  onClose?: () => void
  accounts: LiteRetailerAccountsType[]
  recentlyCreatedAccounts?: RecentlyCreatedAccountType[]
}

const RecentCreatedAccountsModal = ({
  isOpen,
  onClose = () => {},
  accounts,
  recentlyCreatedAccounts = [],
}: ModalProps) => {
  const Portal = usePortal()
  const {
    updateAcknowledgeRecentlyCreatedAccounts,
  } = useAcknowledgeRecentlyCreatedAccounts()

  const subtitle = `Since your last login, you have been linked to new accounts. 
  You can edit the visibility of those accounts below.  
  Any checked accounts will be hidden from brands.`

  const recentAccounts: LiteRetailerAccountsType[] = recentlyCreatedAccounts.map(
    (recentAccount) => {
      const account =
        accounts.find((account) => account.id === recentAccount.id) || {}

      return {
        ...recentAccount,
        ...account,
      } as LiteRetailerAccountsType
    },
  )

  const onCloseModal = () => {
    updateAcknowledgeRecentlyCreatedAccounts(
      recentAccounts.map((account) => account.id),
    )
    onClose()
  }

  return (
    <Portal>
      <ModalProvider>
        <Modal
          isOpen={isOpen}
          title={{ text: 'New Accounts Created' }}
          subtitle={subtitle}
          onClose={onCloseModal}
          size="medium"
        >
          <ModalContent accounts={recentAccounts} onClose={onCloseModal} />
        </Modal>
      </ModalProvider>
    </Portal>
  )
}

export default RecentCreatedAccountsModal
