import styled from 'styled-components'

const HamburgerButton = styled.button`
  display: block;
  background: none;
  border: none;
  cursor: pointer;

  &:active {
    background: none;
  }

  span {
    display: block;
    width: 18px;
    height: 2px;
    background-color: white;
    margin: 5px 0;
    transition: all 0.3s ease;
    border-radius: 1px;
    transform-origin: center;
  }

  &.open span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  &.open span:nth-child(2) {
    opacity: 0;
  }

  &.open span:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
`
type HamburgerProps = {
  onClick: () => void
  className?: string
}

export function Hamburger({ onClick, className }: HamburgerProps) {
  return (
    <HamburgerButton onClick={onClick} className={className}>
      <span />
      <span />
      <span />
    </HamburgerButton>
  )
}
