import { useState } from 'react'

import { Modal, ModalProvider, PrimaryButton } from '@joor/design-system'
import { Text } from '@joor/design-system'
import { FileError } from 'react-dropzone'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import usePortal from 'hooks/usePortal'
import { isCoreBrand } from 'store/currentUser/selectors'

import { useUploadConnections } from './ConnectionRequestModal.hooks'
import { ConnectionsModalIds } from './ConnectionRequestModal.ids'
import messages from './ConnectionRequestModal.messages'
import BestPracticesCard from './components/BestPracticesCard/BestPracticesCard'
import CustomerDataTemplate from './components/CustomerDataTemplate/CustomerDataTemplate'
import DropOrSelectTemplate from './components/DropOrSelectTemplate/DropOrSelectTemplate'
import StepSection from './components/StepSection/StepSection'

const ModalSubtitle = () => (
  <Text fontSize="small" fontWeight="medium" letterSpacing="tighter">
    {messages.connectionsWillBeProcessed + ' '}
    <strong>{messages.hours}</strong>
  </Text>
)

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid var(--color-primary-200);
`

const StyledPrimaryButton = styled(PrimaryButton)`
  &.loading {
    width: auto;
  }
`

interface ConnectionRequestModalProps {
  onClose: () => {}
}

const ConnectionRequestModal = ({ onClose }: ConnectionRequestModalProps) => {
  const Portal = usePortal()
  const [file, setFile] = useState<File | null>(null)
  const [fileErrors, setFileErrors] = useState<FileError[]>([])
  const [loading, setLoading] = useState(false)
  const { uploadConnections } = useUploadConnections()
  const isCoreBrandUser = useSelector(isCoreBrand)

  if (!isCoreBrandUser) {
    return null
  }

  const handleSetFile = (file: File | null = null) => {
    setFile(file)
  }

  const handleSetFileErrors = (fileErrors: FileError[] = []) => {
    setFileErrors(fileErrors)
  }

  const handleMutation = async () => {
    setLoading(true)
    const error = await uploadConnections(file)

    if (!error) {
      setFile(null)
      setFileErrors([])
      onClose()
    } else if ((error as FileError).code) {
      setFileErrors([error as FileError])
    }

    setLoading(false)
  }

  return (
    <Portal>
      <ModalProvider>
        <Modal
          title={{
            id: ConnectionsModalIds.ModalTitle,
            text: messages.addConnections,
          }}
          subtitle={<ModalSubtitle />}
          isOpen
          onClose={onClose}
          size="large"
          disableBackgroundClick
        >
          <Modal.Content>
            <StepSection sectionNumber="1." header={<CustomerDataTemplate />} />
            <StepSection sectionNumber="2." header={messages.fillInTheTemplate}>
              <BestPracticesCard />
            </StepSection>
            <StepSection sectionNumber="3." header={messages.uploadFile}>
              <DropOrSelectTemplate
                file={file}
                setFile={handleSetFile}
                fileErrors={fileErrors}
                setFileErrors={handleSetFileErrors}
              />
            </StepSection>
          </Modal.Content>
          <ActionsContainer>
            <StyledPrimaryButton
              id={ConnectionsModalIds.SubmitRequestButton}
              loading={loading}
              disabled={!file}
              onClick={handleMutation}
            >
              {messages.submitRequest}
            </StyledPrimaryButton>
          </ActionsContainer>
        </Modal>
      </ModalProvider>
    </Portal>
  )
}

export default ConnectionRequestModal
