export const ASSORTMENT_REVIEW_FLAG = 'refactor18-assortmentreview'
export const ORDER_LIST_FLAG = 'refactor18-orderlist'

export const HOLT_DROPDOWN = 'dev-assortment-attribute'
export const ASSORTMENT_REVIEW_NMG_CUSTOM_FIELDS =
  'dev-assortment-review-nmg-custom-fields'

export const ASSORTMENT_REVIEW_RECEIPT_MONTH =
  'dev-assortment-review-receipt-month'
export const EXTERNAL_WEBSITE = 'external-website'
export const BRAND_DASHBOARD = 'brand-dashboard'

export const RETAILER_CREATED_ORDERS_FLAG = 'dev-retailer-created-orders'
export const REPORTING_POC = 'poc-reporting'

export const JOOR_PAY_REACH = 'JOOR_Pay_Use_Reach'
export const BRAND_PAYMENTS_SETTINGS = 'brand-payments-settings'

export const SELF_SERVE = 'self-serve'
export const SELF_SERVE_PERMISSIONING = 'self-serve-permissioning'
export const SELF_SERVE_CONFIG_EXPORT = 'self-serve-config-export'
export const ENABLE_MANAGE_PERMISSIONS = 'new-permissions-page'
export const ENABLE_MANAGE_USER_MEMBERSHIP_REQUESTS =
  'user-membership-request-management'
export const RETAIL_INTEGRATION_SETTINGS = 'retail-integration-settings'

export const FLEXIBLE_ORDER_IMPORT_MODAL = 'use-import-modal'

export const SSO_SETTINGS = 'sso-management'

export const ASSORTMENTS_UI = 'assortments-ui'

export const BRAND_REPORTING = 'brand-reporting'
export const DELIVERY_REPORT = 'delivery-report-poc'
export const ANALYTICS_DASHBOARD = 'analytics-dashboard'
export const JOOR_PAY_ANALYTICS = 'joorPayAnalyticsReport'
export const REPORTING_CONFIGURATIONS = 'reporting-configurations'

export const VIRTUAL_SHOWROOM = 'virtual-showroom'

export const VNTANA = 'vntana'

export const NEW_CONNECTIONS_UI = 'new-connections-ui'

export const BUDGET_REPORT = 'budget-report'

export const WORKSPACE_LIST_PAGE = 'workspace-list-page'

export const ORDER_REVIEW_2023 = 'order-review-2023'

export const PROSPECTS = 'prospects'

export const BRAND_SUBMISSIONS_FLAG = 'brand-submissions-flag'
export const SUBMISSIONS_LIST_FLAG = 'submissions-list'
export const SHOPIFY_INTEGRATION_FLAG = 'shopify-integration'

export const SWATCH_CREATION_AND_MANAGEMENT = 'swatch-creation-and-management'

export const FIND_NEW_BRANDS = 'wayfinding-find-new-brands'
