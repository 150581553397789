import { createAction } from 'redux-actions'

import {
  APPLY_SEARCH_FILTER,
  APPLY_SELECTED_FILTERS,
  SET_APPLIED_FILTERS_TO_CREATED_BY_RETAILERS,
  TOGGLE_DESELECT_ALL_FILTERS,
  TOGGLE_SELECT_FILTER,
  TOGGLE_UNASSIGNED_ORDERS_FILTER,
} from './constants'

export const toggleSelectFilter = createAction(TOGGLE_SELECT_FILTER)
export const toggleDeselectAllFilters = createAction(
  TOGGLE_DESELECT_ALL_FILTERS,
)
export const toggleOnlyUnassignedOrdersFilter = createAction(
  TOGGLE_UNASSIGNED_ORDERS_FILTER,
)

export const applySelectedFilters = createAction(APPLY_SELECTED_FILTERS)
export const applySearchFilter = createAction(APPLY_SEARCH_FILTER)
export const setAppliedFiltersToCreatedByRetailers = createAction(
  SET_APPLIED_FILTERS_TO_CREATED_BY_RETAILERS,
)
