import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'

import {
  isAccountTypeRetailer,
  isCoreBrand,
  isLiteBrand,
  isProBrand,
} from 'store/currentUser/selectors'

interface IAccountTypes {
  isRetailerAccount: boolean
  isLiteBrandAccount: boolean
  isProBrandAccount: boolean
  isCoreBrandAccount: boolean
}

export const useImportStylesExperience = () => {
  const {
    basicStyleUploader,
    earlyAccessBasicStyleUploader,
    earlyAccessBasicStyleUploaderAdmin,
    selfServe,
  } = useFlags()

  const {
    isRetailerAccount,
    isLiteBrandAccount,
    isProBrandAccount,
    isCoreBrandAccount,
  } = useSelector<object, IAccountTypes>((state) => ({
    isRetailerAccount: isAccountTypeRetailer(state),
    isLiteBrandAccount: isLiteBrand(state),
    isProBrandAccount: isProBrand(state),
    isCoreBrandAccount: isCoreBrand(state),
  }))

  if (isRetailerAccount) {
    return {
      oldExperience: false,
      newExperience: false,
    }
  }

  if (isCoreBrandAccount) {
    // For Core Brands, we will always show the new experience
    return {
      oldExperience: false,
      newExperience: true,
    }
  }

  if (isLiteBrandAccount) {
    if (earlyAccessBasicStyleUploaderAdmin) {
      // For Lite Brands with admin flag enabled, the new experience will depend on the earlyAccessBasicStyleUploader
      return {
        oldExperience: !earlyAccessBasicStyleUploader,
        newExperience: earlyAccessBasicStyleUploader,
      }
    } else {
      // For Lite Brands without admin flag enabled, the new experience will be default true
      return {
        oldExperience: false,
        newExperience: true,
      }
    }
  }

  if (isProBrandAccount) {
    // For Pro Brands, the new experience will depend on the basicStyleUploader
    if (basicStyleUploader) {
      return {
        oldExperience: false,
        newExperience: true,
      }
    } else if (selfServe) {
      // And if the Pro Brands have selfServe flag enabled, they should see the old experience
      return {
        oldExperience: true,
        newExperience: false,
      }
    }
  }

  return {
    oldExperience: false,
    newExperience: false,
  }
}
