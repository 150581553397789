import { Caption, Icon, Subtitle, Text } from '@joor/design-system'
import { FileError, useDropzone } from 'react-dropzone'
import styled from 'styled-components'

import {
  FILE_ERROR_MESSAGES,
  MAX_AMOUNT_OF_FILES,
  TEMPLATE_FILE_ACCEPTED_FORMAT,
  TEMPLATE_FILE_MAX_SIZE,
} from '../../ConnectionRequestModal.constants'
import { ConnectionsModalIds } from '../../ConnectionRequestModal.ids'
import messages from '../../ConnectionRequestModal.messages'
import { FileErrors } from '../../ConnectionRequestModal.types'

const DropContainer = styled.div`
  min-height: 96px;
  border: 1px dashed var(--color-secondary-800);
  border-radius: var(--size-border-radius-medium);
  background-color: var(--color-secondary-200);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`

const DragAndDropText = styled.div`
  display: flex;
  gap: 4px;
`

const StyledCaption = styled(Caption)`
  &&& {
    margin: 8px 0;
    line-height: var(--line-height-12);
  }
`

const Chip = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 2px 4px 2px 8px;
  border-radius: 15px;
  background: var(--color-neutral-000-full);
  height: 16px;

  > p {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis;
  }
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
`

const ErrorText = styled(Text)`
  &&& {
    color: var(--color-error-500);
  }
`

interface DropOrSelectTemplateProps {
  file: File | null
  setFile: (file: File | null) => void
  fileErrors: FileError[]
  setFileErrors: (errors: FileError[]) => void
}

const DropOrSelectTemplate = ({
  file,
  setFile,
  fileErrors,
  setFileErrors,
}: DropOrSelectTemplateProps) => {
  const onDrop = (
    acceptedFiles: File[],
    fileRejections: { errors: FileError[] }[],
  ) => {
    if (fileRejections?.length > 0) {
      setFileErrors(fileRejections[0].errors)
      setFile(null)
    } else if (acceptedFiles.length > 0) {
      setFileErrors([])
      setFile(acceptedFiles[0])
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: TEMPLATE_FILE_ACCEPTED_FORMAT,
    maxSize: TEMPLATE_FILE_MAX_SIZE,
    maxFiles: MAX_AMOUNT_OF_FILES,
  })

  return (
    <DropContainer
      id={ConnectionsModalIds.DropTemplateZone}
      {...getRootProps()}
    >
      <>
        <input
          data-testid={ConnectionsModalIds.UploadTemplateInput}
          {...getInputProps()}
        />
        <DragAndDropText>
          <Subtitle type="3">{messages.dragAndDrop}</Subtitle>
          <Subtitle type="3" color="secondary-800">
            {messages.selectFile}
          </Subtitle>
        </DragAndDropText>
      </>
      <StyledCaption>{messages.maxFileSize}</StyledCaption>
      {file && (
        <Chip>
          <Text fontSize="small" fontWeight="medium" letterSpacing="tighter">
            {file.name}
          </Text>
          <StyledIcon
            iconName="x-close"
            iconSize="small"
            onClick={(e) => {
              e.stopPropagation()
              setFile(null)
              setFileErrors([])
            }}
          />
        </Chip>
      )}
      {fileErrors.map((error: FileError, index) => (
        <ErrorText
          id={ConnectionsModalIds[error.code as FileErrors]}
          key={index}
          fontSize="extrasmall"
          letterSpacing="tight"
        >
          {FILE_ERROR_MESSAGES[error.code]}
        </ErrorText>
      ))}
    </DropContainer>
  )
}

export default DropOrSelectTemplate
