import styled, { css } from 'styled-components'

export const sharedTextStyles = css`
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-base);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-normal);
  font-weight: var(--font-weight-medium);
`
export const PrimaryWrapper = styled.div`
  display: flex;
  align-items: center;
  .primary-icon path {
    fill: var(--color-primary-400) !important;
  }
  .accent-icon path {
    fill: var(--color-accent-beta-700) !important;
  }
`

export const PrivacyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const MenuTextMessage = styled.span`
  ${sharedTextStyles}
  color: var(--color-primary-800);
  text-align: left;
  &.disabled {
    color: var(--color-primary-400);
  }
  &.checked {
    color: var(--color-secondary-800);
  }
`
export const TextIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`

export const MenuTextMessageCheck = styled.div`
  ${sharedTextStyles}
  color: var(--color-secondary-800);
  text-align: center;
  display: flex;

  svg {
    width: 16px;
    height: 16px;
  }

  &.checked > span > svg {
    --main-color: var(--color-secondary-800);
  }

  &.disabled > span > svg {
    --main-color: var(--color-primary-400);
  }
`
export const MenuConnectedAccounts = styled.span`
  color: var(--color-primary-600);
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-tighter);
  text-align: left;
  margin-top: 4px;
  &.disabled {
    color: var(--color-primary-400);
  }
`

interface InfoProps {
  createdBy: boolean
}

export const CreationInfoContainer = styled.div<InfoProps>`
  margin-top: 4px;
  display: flex;
  margin-left: auto;
  justify-content: ${(props) => (props.createdBy ? 'space-between' : 'end')};
`

export const Info = styled.div`
  ${sharedTextStyles}
  color: var(--color-primary-600);
  font-size: var(--size-font-small);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.disabled {
    color: var(--color-primary-400);
  }
`
