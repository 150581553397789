import { handleActions } from 'redux-actions'

export const defaultState = {
  selectedOrders: {},
  orderBy: [
    {
      field: 'MODIFIED',
      order: 'DESCENDING',
    },
  ],
  pageSize: 20,
  mergeOrdersModalOpen: false,
  mergeOrderMessages: [],
  shouldCheckMergeOrders: false,
  shouldDisplayDivisionColumn: false,
}

const reducer = handleActions(
  {
    'ORDER/TOGGLE_ORDER': (state, action) => {
      const { selectedOrders } = state
      const { payload } = action
      const orderIsSelected = payload in selectedOrders

      if (orderIsSelected) {
        const {
          [payload]: _isOrderSelected,
          ...selectionWithoutPayload
        } = selectedOrders
        return {
          ...state,
          selectedOrders: selectionWithoutPayload,
        }
      }

      return {
        ...state,
        selectedOrders: {
          ...selectedOrders,
          [payload]: true,
        },
      }
    },
    'ORDER/TOGGLE_SELECT_ORDERS_ON_PAGE': (state, action) => {
      const { selectedOrders } = state
      const { payload } = action
      const allOrdersAreSelected = payload.every(
        (orderId) => orderId in state.selectedOrders,
      )
      const ordersSelectedOnOtherPages = payload.reduce((result, orderId) => {
        if (orderId in state.selectedOrders) {
          const {
            [orderId]: _isOrderSelected,
            ...selectionWithoutPayload
          } = result
          return selectionWithoutPayload
        }
        return result
      }, selectedOrders)

      if (allOrdersAreSelected) {
        return {
          ...state,
          selectedOrders: ordersSelectedOnOtherPages,
        }
      }

      const ordersInPage = payload.reduce(
        (result, orderId) => ({
          ...result,
          [orderId]: true,
        }),
        {},
      )

      return {
        ...state,
        selectedOrders: {
          ...ordersSelectedOnOtherPages,
          ...ordersInPage,
        },
      }
    },
    'ORDER/RESET_SELECTED_ORDERS': (state) => ({
      ...state,
      selectedOrders: defaultState.selectedOrders,
    }),
    'ORDER/SORT_ORDERS': (state, action) => {
      const { payload } = action

      return {
        ...state,
        orderBy: [...payload],
      }
    },
    'ORDER/PAGE_SIZE': (state, action) => {
      const { payload } = action

      return {
        ...state,
        pageSize: payload,
      }
    },
    'ORDER/TOGGLE_MERGE_ORDER_MODAL': (state) => {
      return {
        ...state,
        mergeOrdersModalOpen: !state.mergeOrdersModalOpen,
      }
    },
    'ORDER/SET_MERGE_ORDER_MESSAGES': (state, action) => {
      const { payload } = action
      return {
        ...state,
        mergeOrderMessages: payload,
      }
    },
    'ORDER/TOGGLE_CHECK_MERGE_ORDERS': (state, action) => {
      const { payload } = action
      return {
        ...state,
        shouldCheckMergeOrders: payload,
      }
    },
    'ORDER/TOGGLE_DISPLAY_DIVISION_COLUMN': (state, action) => {
      const { payload } = action
      return {
        ...state,
        shouldDisplayDivisionColumn: payload,
      }
    },
  },
  defaultState,
)

export default reducer
