import { useRef } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './ProductDetailModal.module.css'
import HLSSource from 'containers/Showroom/GridLayout/GridElements/MediaGridElement/HLSSource'

const ThumbnailVideo = ({ url, format, id, selectedMedia, onClick }) => {
  const videoRef = useRef(null)

  return (
    <div key={id}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={videoRef}
        height="75"
        width="56"
        muted
        onClick={() => onClick(url, format)}
        className={classNames(styles.image, {
          [styles.selected]: url === selectedMedia?.url,
        })}
      >
        {format?.toLowerCase() === 'm3u8' ? (
          <HLSSource isVideoChild src={url} video={videoRef?.current} />
        ) : (
          <source src={url} type="video/mp4" />
        )}
      </video>
    </div>
  )
}

ThumbnailVideo.propTypes = {
  url: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  selectedMedia: PropTypes.object,
}

ThumbnailVideo.defaultProps = {
  selectedMedia: null,
}

export default ThumbnailVideo
