import { ComponentProps } from 'react'

import { Card, Icon, Link, Text, Tooltip } from '@joor/design-system'
import styled from 'styled-components'

import { ORDER_SETTINGS } from 'routes/paths'

import { HELP_CENTER_URL } from '../../ConnectionRequestModal.constants'
import { ConnectionsModalIds } from '../../ConnectionRequestModal.ids'
import messages from '../../ConnectionRequestModal.messages'

type TextProps = ComponentProps<typeof Text>
const textProps: Pick<
  TextProps,
  'fontSize' | 'fontWeight' | 'letterSpacing'
> = {
  fontSize: 'small',
  fontWeight: 'medium',
  letterSpacing: 'tighter',
}

const StyledCard = styled(Card)`
  header {
    width: unset;
    max-width: 100%;
    padding: 0 16px;
  }
`

const UnorderedList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px 0 16px;
  padding: 0;
`

const ListItem = styled.div`
  display: flex;
  align-items: center;
`
const Dot = styled.div`
  width: 16px;
  height: 16px;
  &::before {
    content: '•';
    padding: 0 6px;
  }
`

const StyledIcon = styled(Icon)`
  margin-left: 4px;
`

const StyledLink = styled(Link)`
  text-decoration: none;

  &&&:visited {
    color: var(--color-secondary-800);
  }
`

const BestPracticesCard = () => (
  <StyledCard title={messages.bestPractices} iconName="lightbulb">
    <Text {...textProps}>{messages.theFollowingInfo}</Text>
    <UnorderedList>
      <ListItem>
        <Dot />
        <div>
          <strong>{messages.customerName}</strong>
          {' ' + messages.and + '/' + messages.or + ' '}
          <strong>{messages.storeName}</strong>
        </div>
      </ListItem>
      <ListItem>
        <Dot />
        <div>
          {messages.primaryAddress + ': '}
          <strong>{messages.addressFormat}</strong>
          {' ' + messages.ifApplicable + ' ' + messages.and + ' '}
          <strong>{messages.country}</strong>
        </div>
      </ListItem>
      <ListItem>
        <Dot />
        <div>
          <strong>{messages.buyerEmail}</strong>
        </div>
      </ListItem>
      <ListItem>
        <Dot />
        <div>
          {messages.priceType + ': '}
          <strong>
            {messages.wholesaleCurrency + ', ' + messages.retailCurrency}
          </strong>
          {', ' + messages.and + ' '}
          <strong>{messages.priceLabel}</strong>
          {' ' + messages.ifApplicable}
        </div>
        <Tooltip
          customTooltipStyles={{ width: '75%' }}
          content={
            <Text {...textProps}>
              {messages.priceTooltipText + ' '}
              <StyledLink
                href={ORDER_SETTINGS}
                target="_blank"
                color="secondary-800"
                {...textProps}
              >
                {messages.orderSettings}
              </StyledLink>
            </Text>
          }
        >
          <StyledIcon
            id={ConnectionsModalIds.PriceInfo}
            iconName="information"
            variant="ACTIVE"
            iconSize="small"
          />
        </Tooltip>
      </ListItem>
    </UnorderedList>
    <Text {...textProps}>
      {messages.referToOur + ' '}
      <StyledLink
        href={HELP_CENTER_URL}
        color="secondary-800"
        target="_blank"
        {...textProps}
      >
        {messages.helpCenter}
      </StyledLink>
      {' ' + messages.toLearnMore}
    </Text>
  </StyledCard>
)

export default BestPracticesCard
