import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'

import { getAccountId } from 'store/currentUser/selectors'

import { MY_SETTINGS, RETAILER_PROFILE, SSO_SETTINGS } from 'routes/paths'

import DropdownMenuTextLink from 'features/Navbar/NavbarRevamp/components/DropdownMenuTextLink/DropdownMenuTextLink'
import IntegrationSettings from 'features/Navbar/NavbarRevamp/components/SettingsDropdown/components/IntegrationsSettings'
import RetailerSettings from 'features/Navbar/NavbarRevamp/components/SettingsDropdown/components/RetailerSettings'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { RetailAccountsDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

const CurrentAccountSettings = () => {
  const { ssoManagement } = useFlags()
  const accountId = useSelector(getAccountId)
  return (
    <>
      <DropdownMenuTextLink
        to={MY_SETTINGS}
        message={messages.accountSettings}
        id={RetailAccountsDropdownIds.AccountSettings}
      />
      <IntegrationSettings />
      {ssoManagement && (
        <DropdownMenuTextLink
          to={SSO_SETTINGS}
          message={messages.ssoSettings}
          id={RetailAccountsDropdownIds.SSO}
        />
      )}
      <RetailerSettings />
      <DropdownMenuTextLink
        to={generatePath(RETAILER_PROFILE, {
          accountId,
        })}
        message={messages.manageProfile}
        id={RetailAccountsDropdownIds.ManageProfile}
      />
    </>
  )
}

export default CurrentAccountSettings
