import ArabicFlag from 'assets/images/CircleFlags/icon_arabic_flag_circle.png'
import BrasilFlag from 'assets/images/CircleFlags/icon_brasil_flag_circle.png'
import ChineseFlag from 'assets/images/CircleFlags/icon_chinese_flag_circle.png'
import FrenchFlag from 'assets/images/CircleFlags/icon_french_flag_circle.png'
import GermanFlag from 'assets/images/CircleFlags/icon_german_flag_circle.png'
import ItalianFlag from 'assets/images/CircleFlags/icon_italian_flag_circle.png'
import JapaneseFlag from 'assets/images/CircleFlags/icon_japanese_flag_circle.png'
import RussianFlag from 'assets/images/CircleFlags/icon_russian_flag_circle.png'
import SpanishFlag from 'assets/images/CircleFlags/icon_spanish_flag_circle.png'
import UnitedStatesFlag from 'assets/images/CircleFlags/icon_united_states_flag_circle.png'

export const flagsMap: Record<string, string> = {
  de: GermanFlag,
  en: UnitedStatesFlag,
  es: SpanishFlag,
  fr: FrenchFlag,
  it: ItalianFlag,
  'pt-BR': BrasilFlag,
  ru: RussianFlag,
  ar: ArabicFlag,
  zh: ChineseFlag,
  ja: JapaneseFlag,
}
