import { FetchResult, useMutation } from '@apollo/client'
import { ToastToAdd, useNotifications } from '@joor/design-system'
import { FileError } from 'react-dropzone'

import {
  ConnectAccountsThroughJiraErrorCode,
  ConnectAccountsThroughJiraMutationDocument,
  ConnectAccountsThroughJiraMutationMutation,
} from '__generated__/atlas-types'

import { atlasClient } from 'graphql/client'

import { CONSOLE_LOG_KEY } from './ConnectionRequestModal.constants'
import messages from './ConnectionRequestModal.messages'
import { FileErrors } from './ConnectionRequestModal.types'

const handleResponse = (
  response: FetchResult<ConnectAccountsThroughJiraMutationMutation>,
  addToasts: (toastsToAdd: ToastToAdd[]) => void,
): boolean => {
  if (response?.data?.connectAccountsThroughJira?.error) {
    throw new Error(
      response.data.connectAccountsThroughJira.error
        .code as ConnectAccountsThroughJiraErrorCode,
    )
  }

  if (response?.errors && response.errors.length > 0) {
    if (response.errors[0].message.includes('must be less than')) {
      throw new Error(ConnectAccountsThroughJiraErrorCode.FILE_SIZE_EXCEEDED)
    } else {
      throw new Error(
        ConnectAccountsThroughJiraErrorCode.FAILED_TO_CREATE_CONNECTION_REQUEST,
      )
    }
  }

  addToasts([
    {
      title: messages.submitSuccessTitle,
      description: messages.submitSuccessDesc,
      type: 'success',
      shouldCloseManually: true,
    } as ToastToAdd,
  ])

  return false
}

const handleErrors = (
  message: string,
  addToasts: (toastsToAdd: ToastToAdd[]) => void,
): boolean | FileError => {
  if (message === ConnectAccountsThroughJiraErrorCode.FILE_SIZE_EXCEEDED) {
    return { code: FileErrors.FILE_TOO_LARGE, message: '' } as FileError
  }

  if (
    message === ConnectAccountsThroughJiraErrorCode.UNSUPPORTED_FILE_EXTENSION
  ) {
    return { code: FileErrors.FILE_INVALID_TYPE, message: '' } as FileError
  }

  addToasts([
    {
      title: messages.submitFailedTitle,
      description: messages.submitFailedDesc,
      type: 'error',
      shouldCloseManually: true,
    } as ToastToAdd,
  ])

  return true
}

export const useUploadConnections = () => {
  const [mutate] = useMutation(ConnectAccountsThroughJiraMutationDocument, {
    client: atlasClient,
  })

  const { addToasts } = useNotifications()

  const uploadConnections = async (
    file: File | null,
  ): Promise<boolean | FileError> => {
    try {
      if (!file) {
        throw new Error('')
      }

      const response = await mutate({
        variables: { file },
      })

      return handleResponse(response, addToasts)
    } catch (error) {
      const message =
        error instanceof Error
          ? error.message
          : typeof error === 'string'
          ? error
          : ConnectAccountsThroughJiraErrorCode.FAILED_TO_CREATE_CONNECTION_REQUEST

      console.error(CONSOLE_LOG_KEY + '[UploadConnections]' + message)

      return handleErrors(message, addToasts)
    }
  }

  return { uploadConnections }
}
