import { PureComponent, createRef } from 'react'

import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import classNames from 'classnames'
import {
  find,
  get,
  intersection,
  isEmpty,
  size as lSize,
  last,
  map,
  reduce,
} from 'lodash'
import PropTypes from 'prop-types'
import ReactImageMagnify from 'react-image-magnify'

import {
  decimalWithCommas,
  formatDate,
  formatDropdownOptions,
  formatNameWithCode,
  formatTextWithMDash,
} from 'utils/formatters'
import {
  BADGE_CODE,
  BADGE_HEIGHT,
  BADGE_WIDTH,
  IMAGE_CODE,
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
  getSizedImage,
} from 'utils/images'
import { translateIfFeatureFlag } from 'utils/sdks/localize'
import {
  EMPTY_DELIVERY_WINDOW,
  getAggregatedSkuDeliveryRange,
  getDeliveryWindowText,
} from 'utils/transformations/deliveryWindows'
import { fromGlobalId } from 'utils/transformations/graphql'

import {
  EMPTY_VALUE,
  PRODUCT_COLOR_CODE,
  PRODUCT_SIZE_CODE,
} from 'shop/products/traits'
import { getVariantByCode, getVariantValueFromId } from 'shop/products/utils'
import { getAttributeIdFromVariant } from 'shop/products/utils.js'

import {
  Accordion,
  Dropdown,
  Icon,
  Iframe,
  Image,
  Loader,
  Modal,
  Swatch,
  Tooltip,
  Video,
} from 'components/Core'
import Orb360EmbedPlayer from 'components/Orb360Embed'

import ProductDetailTableWrapper from '../ProductDetailTableWrapper/ProductDetailTableWrapper.container'
import { BULK_TABLE_ID } from '../TablesConfiguration'
import styles from './ProductDetailModal.module.css'
import { getSkuDeliveryItems } from './ProductDetailModal.transformations'
import ThumbnailVideo from './ThumbnailVideo'
import { ProductDetailPage } from 'containers/ProductCatalog/productCatalog.ids'

const DATE_FORMAT = 'MM/DD/YYYY'
const ORB360 = 'orb360'
const JSON = 'json'
export const VIDEO = 'video'
export const IMAGE = 'image'
export const IFRAME = 'iframe'
export const M3U8 = 'm3u8'
export const MP4 = 'mp4'

const LargeIframe = ({ videoUrl, disableContextMenu }) => (
  <Iframe
    loop
    muted
    autoPlay
    disableContextMenu={disableContextMenu}
    height={450}
    src={videoUrl}
    title={videoUrl}
    width={330}
  />
)

export const getFirstMediaFile = (
  videos,
  externalMedia,
  images,
  selectedColor,
) => {
  if (!videos.length && !images.length && !externalMedia.length) {
    return null
  }

  const mediaType =
    externalMedia?.length && externalMedia?.find((ex) => ex?.format === IFRAME)
      ? IFRAME
      : externalMedia?.find((ex) => ex?.format === JSON)
      ? ORB360
      : externalMedia?.length || videos?.length
      ? VIDEO
      : IMAGE

  let mediaUrl = ''
  let format = ''
  let payload = ''

  if (mediaType === IFRAME) {
    const exMedia = externalMedia?.find((ex) => ex?.format === IFRAME)
    mediaUrl = exMedia?.url || ''
    format = exMedia?.format || ''
  } else if (mediaType === VIDEO) {
    const media = externalMedia.length ? externalMedia : videos
    mediaUrl = media?.[0]?.url || ''
    format = media?.[0]?.format || ''
  } else if (mediaType === ORB360) {
    const media = externalMedia?.length ? externalMedia : videos
    payload = media?.[0]?.payload || ''
    format = media?.[0]?.format || ''
  } else {
    const image = find(images, (image) => image.code === selectedColor)
    mediaUrl = image?.url || images?.[0]?.url || ''
  }

  return {
    type: mediaType,
    url: mediaUrl,
    format,
    payload,
  }
}

export const ProductId = ({ id }) => {
  const { istaging } = useFlags()

  if (!istaging) return null

  return (
    <div className={styles.infoRow}>
      <div className={styles.infoContent}>
        <span data-testid="product-id-title">Product ID</span>
        <span
          data-testid="product-id-value"
          className={classNames(styles.value, 'notranslate')}
        >
          {fromGlobalId(id).id}
        </span>
      </div>
    </div>
  )
}

class ProductDetailModal extends PureComponent {
  state = {
    selectedMedia: getFirstMediaFile(
      this.props.product.videos,
      this.props.product.externalMedia,
      this.props.product.images,
      this.props.selectedColor,
    ),
    selectedColor:
      this.props.selectedColor || get(this.props.product, 'images[0].code', ''),
    activeDetailSection: false,
    activeVariantDetailSection: false,
    imageRefs: [],
    selectedWarehouseId: this.props.defaultWarehouseId,
    selectedDeliveryWindowId: null,
    variantRowCode: PRODUCT_COLOR_CODE,
    variantColumnCode: PRODUCT_SIZE_CODE,
    userDateFormat: this.props.userDateFormat,
  }
  componentDidMount() {
    this.setImageRefs()
    if (this.props.areWarehousesReady) {
      this.setDefaultDeliveryWindow()
    }
  }

  componentDidUpdate({ productLoading: prevProductLoading }) {
    const {
      productLoading,
      product: { images, videos, externalMedia },
      defaultWarehouseId,
      areWarehousesFromConnectionReady,
      areWarehousesReady,
      selectedColor,
    } = this.props

    if (prevProductLoading && !productLoading) {
      /* eslint-disable react/no-did-update-set-state */
      this.setImageRefs()

      this.setState({
        selectedColor: selectedColor || get(images, '[0].code', ''),
        selectedMedia: getFirstMediaFile(
          videos,
          externalMedia,
          images,
          selectedColor,
        ),
      })
    }

    /**
     * this is were sc-177724 happens
     * `this.state.selectedWarehouseId` is always initialized to the
     * `this.props.defaultWarehouseId` value and turns out that
     * `this.props.defaultWarehouseId` always initializes to null.
     * the `if` block below takes care of updating
     * `this.state.selectedWarehouseId` when the value of
     * `this.props.defaultWarehouseId` changes
     *
     * we need to double check that the defaultWarehouseId is not null and
     * is different from the selectedWarehouseId to consider all the different scenarios.
     */

    if (
      areWarehousesReady &&
      areWarehousesFromConnectionReady &&
      defaultWarehouseId !== null &&
      defaultWarehouseId !== this.state.selectedWarehouseId
    ) {
      this.setState({
        selectedWarehouseId: defaultWarehouseId,
      })
      this.setDefaultDeliveryWindow()
    }
  }

  setDefaultDeliveryWindow = () => {
    const { defaultWarehouseId } = this.props
    const deliveryWindowId = this.getDefaultDeliveryWindowId(defaultWarehouseId)
    this.setState({
      selectedDeliveryWindowId: deliveryWindowId,
    })
    this.setItemsFromSelectedDeliveryWindow(
      defaultWarehouseId,
      deliveryWindowId,
    )
  }

  getDefaultDeliveryWindowId = (selectedWarehouseId) => {
    const { brandWarehousesWithInventory } = this.props
    const warehouse = find(
      brandWarehousesWithInventory,
      (brandWarehouse) => brandWarehouse.id === selectedWarehouseId,
    )
    const availabilityGroups = get(warehouse, 'availabilityGroups', [])
    const immediateDelivery = find(availabilityGroups, (availabilityGroup) =>
      get(availabilityGroup, 'availability.isImmediate'),
    )

    return immediateDelivery
      ? immediateDelivery.id
      : get(last(availabilityGroups), 'id')
  }

  setItemsFromSelectedDeliveryWindow = (warehouseId, availabilityGroupId) => {
    const {
      brandWarehousesWithInventory,
      setWarehouseDeliveryDate,
    } = this.props
    const warehouse = find(
      brandWarehousesWithInventory,
      (brandWarehouse) => brandWarehouse.id === warehouseId,
    )
    const availabilityGroups = get(warehouse, 'availabilityGroups', [])
    const selectedDeliveryWindow = find(
      availabilityGroups,
      (availabilityGroup) => availabilityGroup.id === availabilityGroupId,
    )
    setWarehouseDeliveryDate(
      get(selectedDeliveryWindow, 'availability.availableOn', ''),
    )
  }

  setImageRefs = () => {
    const {
      product: { images },
    } = this.props
    const refs = reduce(
      images,
      (acc, value) => {
        acc[value.id] = createRef()
        return acc
      },
      {},
    )

    this.setState({ imageRefs: refs })
  }

  getWarehouseDropdown = () => {
    const { selectedWarehouseId } = this.state
    const { warehouses } = this.props

    const tooltipContent = (
      <span className={styles.tooltipContent}>
        Changing the order warehouse will split your selections into a new
        order.
      </span>
    )
    const tooltipTrigger = (
      <div>
        <Icon className={styles.dropdownIcon} name="infoBubble" />
      </div>
    )

    return (
      <div className={styles.dropdownContainer}>
        <div className={styles.dropdownLabelContainer}>
          <div className={styles.dropdownLabel}>Order Warehouse</div>
          <Tooltip
            content={tooltipContent}
            trigger={tooltipTrigger}
            position="right center"
          />
        </div>
        <Dropdown
          className={styles.dropdown}
          id="warehouses"
          onChange={this.handleWarehouseChange}
          options={warehouses}
          disabled={warehouses.length === 1}
          value={selectedWarehouseId}
        />
      </div>
    )
  }

  getDeliveryWindowDropdown = () => {
    const {
      warehouses,
      brandWarehousesWithInventory,
      userDateFormat,
    } = this.props
    const { selectedWarehouseId, selectedDeliveryWindowId } = this.state
    const warehouse = find(
      brandWarehousesWithInventory,
      (brandWarehouse) => brandWarehouse.id === selectedWarehouseId,
    )
    const deliveryDates = formatDropdownOptions(
      get(warehouse, 'availabilityGroups', []),
      (deliveryDate) => {
        const isImmediate = get(deliveryDate, 'availability.isImmediate', false)
        const availableOn = get(deliveryDate, 'availability.availableOn', '')
        return isImmediate && isEmpty(availableOn)
          ? 'Available to Sell'
          : formatDate(availableOn, userDateFormat || DATE_FORMAT, true)
      },
      false,
      'notranslate',
    )

    const showDeliveryWindowDropdown =
      !isEmpty(warehouses) && !isEmpty(deliveryDates)

    return (
      showDeliveryWindowDropdown && (
        <div className={styles.dropdownContainer}>
          <div
            className={classNames(
              styles.dropdownLabelContainer,
              styles.labelWithoutIcon,
            )}
          >
            Warehouse Delivery Date
          </div>
          <Dropdown
            className={styles.dropdown}
            id="deliveryWindows"
            onChange={this.handleDeliveryWindowChange}
            options={deliveryDates}
            disabled={deliveryDates.length === 1}
            value={selectedDeliveryWindowId}
            selectOnBlur={false}
          />
        </div>
      )
    )
  }

  getBulkQuantities = () => {
    const {
      product: { variants, id: productId },
      getBulkQuantities,
    } = this.props
    const { variantRowCode } = this.state
    return map(getBulkQuantities(), (bulkQuantity) => {
      const { variantValueId, ...rest } = bulkQuantity
      return {
        ...rest,
        productId,
        bulkVariantValue: get(
          getVariantValueFromId(variants, variantRowCode, variantValueId),
          'value',
        ),
      }
    })
  }

  getSkuQuantities = () => {
    const {
      product: { variants },
      getSkuQuantities,
    } = this.props
    const { variantColumnCode, variantRowCode } = this.state
    const variantColumnValues = get(
      getVariantByCode(variants, variantColumnCode),
      'values',
    )

    return map(getSkuQuantities(variantColumnValues), (skuQuantity) => {
      const { variantRowValueId, variantColumnValueId, ...rest } = skuQuantity
      const variantRowValue = getVariantValueFromId(
        variants,
        variantRowCode,
        variantRowValueId,
      )
      const variantColumnValue = getVariantValueFromId(
        variants,
        variantColumnCode,
        variantColumnValueId,
      )

      return {
        ...rest,
        skuId: intersection(
          get(variantRowValue, 'skusIds'),
          get(variantColumnValue, 'skusIds'),
        )[0],
      }
    })
  }

  getCasepackQuantities = () => {
    const {
      product: { id: productId, casepacks },
      getCasepackQuantities,
    } = this.props
    return map(getCasepackQuantities(casepacks), (casepackQuantity) => {
      const { variantValueId, casepackId, ...rest } = casepackQuantity
      return {
        ...rest,
        productId,
        casepackId: get(
          casepacks,
          `[${casepackId}].colors[${variantValueId}].originalCasepackId`,
        ),
      }
    })
  }

  getOrderFieldsForMutation = () => {
    const {
      retailerAccount: { id: retailerId },
      priceTypeId,
      collectionId,
      eventId,
      brandAccount: { id: brandId },
      typeId,
    } = this.props
    const { selectedWarehouseId, selectedDeliveryWindowId } = this.state
    return {
      retailerId,
      brandId,
      priceTypeId,
      collectionId,
      ...(selectedWarehouseId && {
        warehouseId: selectedWarehouseId,
      }),
      ...(selectedDeliveryWindowId && {
        inventoryAvailabilityId: selectedDeliveryWindowId,
      }),
      ...(eventId && { eventId }),
      ...(typeId && { typeId }),
    }
  }

  getSizeVariantDetails = () => {
    const {
      product: { variants },
    } = this.props
    const sizeVariantValues = get(
      getVariantByCode(variants, PRODUCT_SIZE_CODE),
      'values',
      [],
    )
    return sizeVariantValues.map((sizeVariant) => ({
      id: sizeVariant.id,
      name: sizeVariant.value,
      externalIdentifier: sizeVariant.externalIdentifier,
      description: sizeVariant.description,
    }))
  }

  changeImage = (colorName, displayDetails) => {
    const {
      product: { images },
    } = this.props
    const imageMatchColor = find(images, (image) => image.code === colorName)
    this.setState({
      selectedColor: colorName,
      selectedMedia: imageMatchColor
        ? { type: IMAGE, url: imageMatchColor.url }
        : null,
      imageFooter:
        !imageMatchColor && !displayDetails.colorNumber && !displayDetails.url
          ? 'No color or swatch image available'
          : 'No color image available',
    })

    if (imageMatchColor) {
      this.goToImage(imageMatchColor.id)
    }
  }

  toggleDetails = () => {
    this.props.trackOpenStyleDetail(!this.state.activeDetailSection)
    this.setState({ activeDetailSection: !this.state.activeDetailSection })
  }

  toggleVariantDetails = () =>
    this.setState({
      activeVariantDetailSection: !this.state.activeVariantDetailSection,
    })

  formatPrice = (price) =>
    price === EMPTY_VALUE ? price : decimalWithCommas(price)

  formatPriceRanges = (priceMin, priceMax) =>
    priceMin === priceMax ? priceMin : `${priceMin} - ${priceMax}`

  handleWarehouseChange = (e, { value: warehouseId }) => {
    const { setWarehouseId } = this.props
    setWarehouseId(warehouseId)
    const selectedDeliveryWindowId = this.getDefaultDeliveryWindowId(
      warehouseId,
    )
    this.setState({
      selectedWarehouseId: warehouseId,
      selectedDeliveryWindowId,
    })
    this.setItemsFromSelectedDeliveryWindow(
      warehouseId,
      selectedDeliveryWindowId,
    )
  }

  goToImage = (id) => {
    const { imageRefs } = this.state
    const ref = get(imageRefs[id], 'current')
    if (ref) {
      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  handleDeliveryWindowChange = (_, { value: availabilityGroupId }) => {
    this.setItemsFromSelectedDeliveryWindow(
      this.state.selectedWarehouseId,
      availabilityGroupId,
    )

    this.setState({ selectedDeliveryWindowId: availabilityGroupId })
  }

  handleAddToOrder = () => {
    if (this.props.selectedTabId === BULK_TABLE_ID) {
      this.addOrderWithBulkQuantities()
    } else {
      this.addOrderWithSizedQuantities()
    }
  }

  addOrderWithBulkQuantities = () => {
    const {
      handleAddToOrder,
      setShouldUpdateCart,
      trackAddToOrder,
      refetchVariantsInCart,
      onAddToOrder,
      setShouldDisableCart,
    } = this.props

    setShouldDisableCart(true)
    handleAddToOrder({
      orderInfo: this.getOrderFieldsForMutation(),
      bulkQuantities: this.getBulkQuantities(),
    })
      .then(() => {
        setShouldUpdateCart(true)
        refetchVariantsInCart && refetchVariantsInCart()
        onAddToOrder && onAddToOrder()
      })
      .catch((_) => {
        setShouldDisableCart(false)
        onAddToOrder && onAddToOrder({ error: true })
      })
    trackAddToOrder()
  }

  addOrderWithSizedQuantities = () => {
    const {
      handleAddToOrder,
      setShouldUpdateCart,
      trackAddToOrder,
      refetchVariantsInCart,
      onAddToOrder,
      setShouldDisableCart,
    } = this.props
    setShouldDisableCart(true)
    handleAddToOrder({
      orderInfo: this.getOrderFieldsForMutation(),
      skuQuantities: this.getSkuQuantities(),
      casepackQuantities: this.getCasepackQuantities(),
    })
      .then(() => {
        setShouldUpdateCart(true)
        refetchVariantsInCart && refetchVariantsInCart()
        onAddToOrder && onAddToOrder()
      })
      .catch((_) => {
        setShouldDisableCart(false)
        onAddToOrder && onAddToOrder({ error: true })
      })

    trackAddToOrder()
  }

  createSizeVariantRows = (sizes, localizeUserData) =>
    map(sizes, (size) => (
      <tr className={styles.row} key={size.id}>
        <td className={styles.sizeValue}>{formatTextWithMDash(size.name)}</td>
        <td className={styles.sizeValue}>
          {formatTextWithMDash(size.externalIdentifier)}
        </td>
        <td
          className={classNames(
            styles.sizeDescription,
            translateIfFeatureFlag(localizeUserData),
          )}
        >
          {formatTextWithMDash(size.description)}
        </td>
      </tr>
    ))

  renderWholesaleAndRetailerValues = () => {
    const {
      currencyCode,
      retailCurrency,
      product: { wholesaleRange, retailRange },
      externalPriceTypeId,
      showPriceDisclaimer,
    } = this.props

    const wholesaleValueMin = this.formatPrice(wholesaleRange.min)
    const wholesaleValueMax = this.formatPrice(wholesaleRange.max)
    const retailValueMin = this.formatPrice(retailRange.min)
    const retailValueMax = this.formatPrice(retailRange.max)

    const wholesaleValue = this.formatPriceRanges(
      wholesaleValueMin,
      wholesaleValueMax,
    )
    const retailValue = this.formatPriceRanges(retailValueMin, retailValueMax)
    const isAvailable = !isEmpty(wholesaleValueMin)

    if (!externalPriceTypeId && showPriceDisclaimer && !isAvailable) {
      return this.renderPriceDisclaimer()
    }

    return (
      <div className={styles.infoRow}>
        <div className={styles.infoContent}>
          <span>Wholesale</span>
          {isAvailable ? (
            <span className={classNames(styles.value, 'notranslate')}>
              {`${currencyCode} ${wholesaleValue}`}
            </span>
          ) : (
            <span className={styles.value}>
              <span className="isolate">Not available in </span>
              <var>{currencyCode}</var>
            </span>
          )}
        </div>
        <div className={styles.infoContent}>
          <span>Sugg. Retail</span>
          {isAvailable ? (
            <span className={classNames(styles.value, 'notranslate')}>
              {`${retailCurrency} ${retailValue}`}
            </span>
          ) : (
            <span className={styles.value}>
              <span className="isolate">Not available in </span>
              <var>{retailCurrency}</var>
            </span>
          )}
        </div>
      </div>
    )
  }

  renderPriceDisclaimer = () => (
    <div className={styles.infoRow}>
      <div className={classNames(styles.infoContent, styles.fullWidth)}>
        <span>
          <i>Please select a price type to see prices for this product.</i>
        </span>
      </div>
    </div>
  )

  renderDeliveryWindow = () => {
    const {
      product: {
        deliveryWindow: {
          start: minDeliveryWindow,
          complete: maxDeliveryWindow,
        },
      },
      userDateFormat,
    } = this.props
    return (
      <div className={styles.infoRow}>
        <div className={styles.infoContent}>
          <span>Delivery Window</span>
          <span
            className={classNames(styles.value, 'notranslate')}
            id="deliveryWindow"
          >
            {getDeliveryWindowText(
              minDeliveryWindow,
              maxDeliveryWindow,
              undefined,
              undefined,
              userDateFormat,
            )}
          </span>
        </div>
      </div>
    )
  }

  renderVariants = () => {
    const {
      product: { variants, casepacks, casepackIds },
      trackSwatchClick,
      skuDeliveries,
      flags: { skuDeliveries: skuDeliveriesFlag, localizeUserData },
      userDateFormat,
    } = this.props
    return variants.map(({ code, name, values }) => (
      <div
        className={classNames(styles.infoRow, styles.variantContainer)}
        key={code}
      >
        <div>{name}</div>
        <div className={styles.variantValues}>
          <div
            className={classNames({
              [styles.columnAlignment]: skuDeliveriesFlag,
              [styles.rowAlignment]: !skuDeliveriesFlag,
            })}
          >
            {values.map(
              ({ id, value, externalIdentifier, displayDetails }, index) => {
                if (code === PRODUCT_COLOR_CODE) {
                  const rawId = getAttributeIdFromVariant(id)
                  const swatch = {
                    colorNumber: get(displayDetails, 'hexColor'),
                    url: get(displayDetails, 'swatchImage.url'),
                  }
                  const swatchSize = skuDeliveriesFlag ? 35 : 20
                  const items = getSkuDeliveryItems({
                    variants,
                    casepacks,
                    skuDeliveries,
                    variantId: rawId,
                    className: styles.colorSkuDelivery,
                    userDateFormat,
                  })
                  const content = (
                    <Accordion.List categoryName="skuDelivery" items={items} />
                  )

                  const colorSkuDelivery = getAggregatedSkuDeliveryRange(
                    skuDeliveries[rawId],
                    userDateFormat,
                  )
                  return (
                    <div
                      key={id}
                      className={classNames({
                        [styles.colorVariantInfo]: skuDeliveriesFlag,
                        [styles.colorSkuDeliveryAlignment]:
                          colorSkuDelivery === EMPTY_DELIVERY_WINDOW,
                      })}
                    >
                      <div
                        className={classNames({
                          [styles.selected]: value === this.state.selectedColor,
                          [styles.smallSwatch]: !skuDeliveriesFlag,
                          [styles.bigSwatch]: skuDeliveriesFlag,
                        })}
                        key={value}
                        onClick={() => {
                          trackSwatchClick(value)
                          this.changeImage(value, swatch)
                        }}
                      >
                        <Swatch
                          swatch={swatch}
                          color={{
                            colorName: formatNameWithCode(
                              value,
                              externalIdentifier,
                            ),
                          }}
                          width={swatchSize}
                          height={swatchSize}
                          small
                          tooltipPosition="bottom center"
                          tooltipClassName="notranslate"
                        />
                      </div>
                      {skuDeliveriesFlag && (
                        <div className={styles.skuDeliveryInfo}>
                          <div>
                            {formatNameWithCode(value, externalIdentifier)}
                          </div>
                          {colorSkuDelivery !== EMPTY_DELIVERY_WINDOW && (
                            <Accordion
                              panels={[
                                {
                                  title: colorSkuDelivery,
                                  content,
                                },
                              ]}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )
                }

                return (
                  <span
                    key={value}
                    className={translateIfFeatureFlag(localizeUserData)}
                  >
                    {`${value}${
                      index === values.length - 1 && isEmpty(casepackIds)
                        ? ''
                        : ','
                    }`}
                    &nbsp;
                  </span>
                )
              },
            )}
          </div>
          {code === PRODUCT_SIZE_CODE &&
            casepackIds.map((casepackId, index) => {
              const { name: casepackName } = casepacks[casepackId]
              return (
                <span
                  key={casepackName}
                  className={translateIfFeatureFlag(localizeUserData)}
                >
                  {`${casepackName}${
                    index === casepackIds.length - 1 ? '' : ','
                  }`}
                  &nbsp;
                </span>
              )
            })}
        </div>
      </div>
    ))
  }

  renderSizeVariantDetails = () => {
    const { activeVariantDetailSection } = this.state
    const {
      product: { casepacks },
      flags: { createSizeVariantRows },
    } = this.props
    return (
      <div className={styles.sizeVariantDetailAccordion}>
        <div
          onClick={() => this.toggleVariantDetails()}
          className={styles.header}
          id={ProductDetailPage.SizeDetails}
        >
          <Icon
            name={activeVariantDetailSection ? 'minus' : 'plus'}
            className={styles.plusIcon}
          />
          <span>Size Details</span>
        </div>
        {activeVariantDetailSection && (
          <table className={styles.table}>
            <thead>
              <tr className={styles.row}>
                <th className={styles.sizeValueHeader}>Size</th>
                <th className={styles.sizeValueHeader}>Size Code</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {this.createSizeVariantRows(
                this.getSizeVariantDetails(),
                createSizeVariantRows,
              )}
              {this.createSizeVariantRows(casepacks, createSizeVariantRows)}
            </tbody>
          </table>
        )}
      </div>
    )
  }

  renderThumbnailImage = (image) => (
    <div key={image.id} ref={image.id}>
      <Image
        src={image.url}
        onClick={() =>
          this.setState({
            selectedMedia: { type: IMAGE, url: image.url },
            selectedColor: image.code,
          })
        }
        className={classNames(styles.image, {
          [styles.selected]:
            image.url === get(this.state.selectedMedia, 'url', '') &&
            this.state.selectedColor === image.code,
        })}
      />
    </div>
  )

  renderThumbnailIframe = ({
    thumbnailUrl,
    onIframeClick,
    selectedMedia,
    url,
  }) => (
    <div key={thumbnailUrl} ref={thumbnailUrl}>
      <Image
        src={thumbnailUrl}
        onClick={() => onIframeClick(url, IFRAME)}
        className={classNames(styles.image, {
          [styles.selected]: url === selectedMedia?.url,
        })}
      />
    </div>
  )

  renderThumbnailPlaceholder = () => (
    <Image containerClassName={classNames(styles.image, styles.selected)} />
  )

  renderLargePlaceholder = (legend) => (
    <div className={styles.noMediaContainer}>
      <Image containerClassName={classNames(styles.image, styles.selected)} />
      <span>{legend}</span>
    </div>
  )

  renderLargeImage = (imageUrl) => (
    <ReactImageMagnify
      className={styles.imageMagnify}
      imageClassName={styles.imageSmallMagnify}
      {...{
        smallImage: {
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT,
          src: getSizedImage(imageUrl, IMAGE_CODE),
        },
        largeImage: {
          src: getSizedImage(imageUrl, IMAGE_CODE),
          width: 1320,
          height: 1800,
        },
      }}
    />
  )

  renderLargeVideo = (videoUrl, disableContextMenu, format) => {
    const isSafari =
      /Safari/.test(navigator.userAgent) &&
      /Apple Computer/.test(navigator.vendor)
    return (
      <Video
        height={450}
        width={330}
        autoPlay
        muted
        controls
        src={videoUrl}
        format={format}
        loop
        disableFullScreen={isSafari}
        disableContextMenu={disableContextMenu}
      />
    )
  }

  renderLargeIframe = (videoUrl, disableContextMenu) => {
    return (
      <Iframe
        loop
        muted
        autoPlay
        disableContextMenu={disableContextMenu}
        height={450}
        src={videoUrl}
        title={videoUrl}
        width={330}
      />
    )
  }

  renderBadge = (badge) => (
    <Image
      src={getSizedImage(get(badge, 'image.url', ''), BADGE_CODE)}
      className={styles.badge}
      height={BADGE_HEIGHT}
      width={BADGE_WIDTH}
    />
  )

  renderMedia = () => {
    const {
      product: { badges, images, videos, externalMedia },
      isBrandUser,
    } = this.props
    const { selectedMedia, imageFooter } = this.state
    const orb360embed = externalMedia.filter(
      (media) => media?.type === ORB360 && media?.format === JSON,
    )
    const iframeMedia = externalMedia.filter(
      (media) => media?.format === IFRAME,
    )
    const videoMedia = externalMedia.filter(
      (media) => media?.format !== IFRAME && media?.format !== JSON,
    )

    const onVideoClick = (url, format) => {
      this.setState({
        selectedMedia: {
          type: VIDEO,
          url,
          format,
        },
      })
    }

    const onIframeClick = (url, format) => {
      this.setState({
        selectedMedia: {
          type: IFRAME,
          url,
          format,
        },
      })
    }

    const onOrb360EmbedClick = (payload) => {
      this.setState({
        selectedMedia: {
          type: ORB360,
          format: JSON,
          payload: payload,
        },
      })
    }

    return (
      <>
        <div className={styles.carouselContainer}>
          <div className={styles.carousel}>
            {!videos.length &&
              !images.length &&
              !externalMedia.length &&
              this.renderThumbnailPlaceholder()}
            {iframeMedia.map((media) => {
              return this.renderThumbnailIframe({
                thumbnailUrl: media?.thumbnailUrl,
                url: media?.url,
                onIframeClick: onIframeClick,
                selectedMedia: this.state.selectedMedia,
              })
            })}
            {orb360embed?.map((media) => (
              <Image
                src={media.thumbnailUrl}
                onClick={() => onOrb360EmbedClick(media?.payload)}
                className={classNames(styles.image, {
                  [styles.selected]: selectedMedia?.payload === media?.payload,
                })}
              />
            ))}
            {videoMedia.map((media) => (
              <ThumbnailVideo
                {...media}
                selectedMedia={this.state.selectedMedia}
                onClick={onVideoClick}
              />
            ))}
            {videos.map((video) => (
              <ThumbnailVideo
                {...video}
                key={video.id}
                selectedMedia={this.state.selectedMedia}
                onClick={onVideoClick}
              />
            ))}
            {images.map((image) => this.renderThumbnailImage(image))}
          </div>
        </div>
        <div
          className={classNames(styles.largeMediaFile, {
            [styles.noMedia]: !selectedMedia,
            [styles.video]: selectedMedia && selectedMedia.type === VIDEO,
          })}
        >
          {selectedMedia &&
            selectedMedia.type === IMAGE &&
            this.renderLargeImage(selectedMedia.url)}
          {selectedMedia &&
            // Vids dont have format as current graphql type doesn't brings them.
            selectedMedia.type === VIDEO &&
            this.renderLargeVideo(
              selectedMedia.url,
              !isBrandUser,
              selectedMedia.format,
            )}
          {selectedMedia && selectedMedia.type === IFRAME && (
            <LargeIframe
              videoUrl={selectedMedia.url}
              disableContextMenu={!isBrandUser}
            />
          )}
          {selectedMedia?.type === ORB360 && selectedMedia?.format === JSON && (
            <Orb360EmbedPlayer playerData={selectedMedia?.payload} />
          )}
          {!selectedMedia && this.renderLargePlaceholder(imageFooter)}
          {!isEmpty(badges) && this.renderBadge(badges[0])}
        </div>
      </>
    )
  }

  renderTraitValues = (localizeUserData) => {
    const {
      product: { categories, traitValues, description, tagValues },
    } = this.props
    const { activeDetailSection } = this.state
    const displayedValues = lSize(traitValues) + lSize(tagValues)
    const categoryName = categories?.[0]?.parentName
    const subCategoryName = categories?.[0]?.name

    return (
      <div className={styles.detailAccordion}>
        <div
          onClick={() => this.toggleDetails()}
          className={styles.detailHeader}
          id={ProductDetailPage.StyleDetails}
        >
          <Icon
            name={activeDetailSection ? 'minus' : 'plus'}
            className={styles.plusIcon}
          />
          <span>Style Details</span>
        </div>
        {activeDetailSection && (
          <div className={styles.traitValuesContainer}>
            {categoryName && (
              <div className={styles.traitContainer} key={categoryName}>
                <span>Category</span>
                <span className={styles.value}>{categoryName}</span>
              </div>
            )}
            {subCategoryName && (
              <div className={styles.traitContainer} key={subCategoryName}>
                <span>Sub Category</span>
                <span className={styles.value}>{subCategoryName}</span>
              </div>
            )}
            {traitValues.map((trait) => (
              <div className={styles.traitContainer} key={trait.name}>
                <span>{trait.name}</span>
                <span className={styles.value}>{trait.value}</span>
              </div>
            ))}
            {tagValues.map((tagValue) => {
              const { id, tag, value, externalIdentifier } = tagValue
              const tagText = `${externalIdentifier || ''} ${
                externalIdentifier && value ? '-' : ''
              } ${value || ''}`

              return (
                <div className={styles.traitContainer} key={id}>
                  <span>{get(tag, 'name', '')}</span>
                  <span
                    className={classNames(
                      styles.value,
                      translateIfFeatureFlag(localizeUserData),
                    )}
                  >
                    {tagText}
                  </span>
                </div>
              )
            })}
            {(description || displayedValues % 2 === 1) && (
              <div
                className={classNames(styles.traitContainer, {
                  [styles.description]: description,
                })}
              >
                {description && (
                  <>
                    <span>Description</span>
                    <span
                      className={classNames(
                        styles.value,
                        translateIfFeatureFlag(localizeUserData),
                      )}
                    >
                      {description}
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  renderDropdowns = () => {
    const { warehouses } = this.props
    const warehouseDropdown =
      !isEmpty(warehouses) && this.getWarehouseDropdown()
    const deliveryWindowDropdown =
      warehouseDropdown && this.getDeliveryWindowDropdown()
    return (
      <div className={styles.dropdownsContainer}>
        {warehouseDropdown}
        {deliveryWindowDropdown}
      </div>
    )
  }

  renderDoors = () => {
    const { selectedDoors } = this.props
    const doorsLength = selectedDoors.length
    return (
      doorsLength > 0 && (
        <div className={styles.doorsContainer}>
          <span>
            <var count="true" pluralize={doorsLength}>
              {`${doorsLength} `}
            </var>
            {`door selected: `}
          </span>
          <span className="notranslate">
            {map(selectedDoors, (door) => door.text).join(', ')}
          </span>
        </div>
      )
    )
  }

  render() {
    const {
      onClose,
      product,
      discounts,
      discountFactor,
      enableQuantifying,
      productLoading,
      currencyCode,
      retailCurrency,
      selectedDoors,
      priceTypeId,
      isProRetailer,
      isProductReady,
      warehouseQueryLoading,
      hasValuesInTable,
      areDiscountsReady,
      skuInventoryItems,
      skuInventoryItemsLoading,
      displaySizeVariantDetails,
      hasNotedVariants,
      flags,
      collectionsLoading,
      showShopToAssortmentFeatures,
      skuDeliveries,
      hideSecondaryButton,
      userDateFormat,
      warehousesFromConnectionLoading,
    } = this.props
    const { localizeUserData } = flags
    const { variantRowCode, variantColumnCode } = this.state
    const isAvailable = !isEmpty(get(product, 'wholesaleRange.min'), '')
    const isAbleToQuantify =
      enableQuantifying && isAvailable && !showShopToAssortmentFeatures
    const queriesAreLoading =
      productLoading ||
      warehouseQueryLoading ||
      skuInventoryItemsLoading ||
      collectionsLoading ||
      warehousesFromConnectionLoading

    return (
      <Modal
        title={isAbleToQuantify ? 'View and Quantify' : 'Product Details'}
        id={
          isAbleToQuantify
            ? ProductDetailPage.ViewAndQuantifyHeader
            : ProductDetailPage.ProductDetailsHeader
        }
        onClose={onClose}
        open
        className={styles.ProductDetailModal}
        primaryActionLabel="Add to Order"
        secondaryActionLabel={isAbleToQuantify ? 'Back to Catalog' : 'Done'}
        secondaryActionHidden={!enableQuantifying || hideSecondaryButton}
        primaryActionOnClick={this.handleAddToOrder}
        primaryDisabled={!hasValuesInTable && !hasNotedVariants}
        primaryActionHidden={!isAbleToQuantify}
        primaryActionId={ProductDetailPage.SizeAddtoOrder}
        secondaryActionId={ProductDetailPage.BackToCatalog}
      >
        <Loader active={queriesAreLoading}>
          <div className={styles.detailContainer}>
            <div className={styles.imagesContainer}>{this.renderMedia()}</div>
            <div className={styles.infoContainer}>
              <div className={styles.headerContainer}>
                <span
                  className={classNames(
                    styles.productName,
                    translateIfFeatureFlag(localizeUserData),
                  )}
                >
                  {product.name}
                </span>
                <span className={translateIfFeatureFlag(localizeUserData)}>
                  {product.code}
                </span>
              </div>
              {product.deliveryWindow && this.renderDeliveryWindow()}
              {this.renderWholesaleAndRetailerValues()}
              <ProductId id={product.id} />
              {this.renderVariants()}
              {displaySizeVariantDetails && this.renderSizeVariantDetails()}
              {this.renderTraitValues(localizeUserData)}
            </div>
          </div>
          {isAbleToQuantify && (
            <>
              {this.renderDropdowns()}
              {this.renderDoors()}
              <ProductDetailTableWrapper
                product={product}
                discounts={discounts}
                discountFactor={discountFactor}
                currencyCode={currencyCode}
                retailCurrency={retailCurrency}
                doors={selectedDoors}
                priceTypeId={priceTypeId}
                isProRetailer={isProRetailer}
                isProductReady={isProductReady}
                areDiscountsReady={areDiscountsReady}
                availabilityGroupsItems={skuInventoryItems}
                variantRowCode={variantRowCode}
                variantColumnCode={variantColumnCode}
                flags={flags}
                skuDeliveries={skuDeliveries}
                userDateFormat={userDateFormat}
              />
            </>
          )}
        </Loader>
      </Modal>
    )
  }
}

ProductDetailModal.propTypes = {
  areDiscountsReady: PropTypes.bool,
  areWarehousesFromConnectionReady: PropTypes.bool,
  areWarehousesReady: PropTypes.bool,
  brandWarehousesWithInventory: PropTypes.array,
  collectionId: PropTypes.string.isRequired,
  collectionsLoading: PropTypes.bool,
  currencyCode: PropTypes.string.isRequired,
  defaultWarehouseId: PropTypes.string,
  hideSecondaryButton: PropTypes.bool,
  discountFactor: PropTypes.number,
  discounts: PropTypes.array,
  displaySizeVariantDetails: PropTypes.bool,
  enableQuantifying: PropTypes.bool,
  eventId: PropTypes.string,
  externalPriceTypeId: PropTypes.bool,
  flags: PropTypes.shape({
    skuDeliveries: PropTypes.bool,
  }),
  getBulkQuantities: PropTypes.func.isRequired,
  getCasepackQuantities: PropTypes.func.isRequired,
  getSkuQuantities: PropTypes.func.isRequired,
  handleAddToOrder: PropTypes.func.isRequired,
  hasNotedVariants: PropTypes.bool,
  hasValuesInTable: PropTypes.bool,
  ignoresInventory: PropTypes.bool,
  isBrandUser: PropTypes.bool,
  isDataForRetailerReady: PropTypes.bool,
  isProductReady: PropTypes.bool,
  isProRetailer: PropTypes.bool,
  onAddToOrder: PropTypes.func,
  onClose: PropTypes.func,
  priceTypeId: PropTypes.string.isRequired,
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    wholesaleRange: PropTypes.shape({
      min: PropTypes.string,
      max: PropTypes.string,
    }).isRequired,
    retailRange: PropTypes.shape({
      min: PropTypes.string,
      max: PropTypes.string,
    }).isRequired,
    deliveryWindow: PropTypes.shape({
      start: PropTypes.string,
      complete: PropTypes.string,
    }),
    variants: PropTypes.arrayOf(PropTypes.object).isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({ code: PropTypes.string, url: PropTypes.string }),
    ),
    traitValues: PropTypes.array.isRequired,
    description: PropTypes.string,
    skus: PropTypes.object.isRequired,
    badges: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        image: PropTypes.shape({ url: PropTypes.string }),
      }),
    ),
    casepacks: PropTypes.object,
    casepackIds: PropTypes.array,
    tagValues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
        externalIdentifier: PropTypes.string,
        tag: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
      }),
    ),
    videos: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, url: PropTypes.string }),
    ),
    externalMedia: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        url: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
  }).isRequired,
  productLoading: PropTypes.bool,
  retailCurrency: PropTypes.string.isRequired,
  retailerAccount: PropTypes.object,
  selectedColor: PropTypes.string,
  selectedDoors: PropTypes.array,
  selectedTabId: PropTypes.string.isRequired,
  setShouldUpdateCart: PropTypes.func,
  setShouldDisableCart: PropTypes.func,
  setWarehouseDeliveryDate: PropTypes.func.isRequired,
  setWarehouseId: PropTypes.func.isRequired,
  showPriceDisclaimer: PropTypes.bool,
  showShopToAssortmentFeatures: PropTypes.bool,
  skuDeliveries: PropTypes.object,
  skuInventoryItems: PropTypes.object,
  skuInventoryItemsLoading: PropTypes.bool,
  trackAddToOrder: PropTypes.func,
  trackOpenStyleDetail: PropTypes.func,
  trackSwatchClick: PropTypes.func,
  warehouseDeliveryDate: PropTypes.string,
  warehouseQueryLoading: PropTypes.bool,
  warehouses: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  warehousesFromConnectionLoading: PropTypes.bool,
  refetchVariantsInCart: PropTypes.func.isRequired,
  userDateFormat: PropTypes.string,
}

ProductDetailModal.defaultProps = {
  areDiscountsReady: false,
  areWarehousesFromConnectionReady: true,
  areWarehousesReady: false,
  brandWarehousesWithInventory: [],
  collectionsLoading: false,
  defaultWarehouseId: null,
  hideSecondaryButton: false,
  discountFactor: 1,
  discounts: [],
  displaySizeVariantDetails: false,
  enableQuantifying: true,
  eventId: '',
  externalPriceTypeId: false,
  flags: {
    proRetailProductDetail: false,
    skuDeliveries: false,
  },
  hasNotedVariants: false,
  hasValuesInTable: false,
  ignoresInventory: true,
  isBrandUser: false,
  isDataForRetailerReady: true,
  isProductReady: true,
  isProRetailer: false,
  onAddToOrder: undefined,
  onClose: () => {},
  productLoading: false,
  retailerAccount: {},
  selectedColor: '',
  selectedDoors: [],
  setShouldUpdateCart: () => {},
  setShouldDisableCart: () => {},
  showPriceDisclaimer: false,
  showShopToAssortmentFeatures: false,
  skuDeliveries: [],
  skuInventoryItems: new Map(),
  skuInventoryItemsLoading: false,
  trackAddToOrder: () => {},
  trackOpenStyleDetail: () => {},
  trackSwatchClick: () => {},
  warehouseDeliveryDate: null,
  warehouseQueryLoading: false,
  warehouses: [],
  warehousesFromConnectionLoading: false,
}

export default ProductDetailModal
