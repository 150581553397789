import { Component } from 'react'

import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import qs from 'qs'

import { formatRetailerWithLocation } from 'formatters/orders'
import { formatDropdownOptions } from 'utils/formatters'
import { forceFileDownload, makeRequest } from 'utils/requests'
import { getKeyFromFirstElement } from 'utils/transformations/array'
import { fromGlobalId } from 'utils/transformations/graphql'

import Button from 'components/Core/Button/Button'
import Divider from 'components/Core/Divider/Divider'
import Dropdown from 'components/Core/Dropdown/Dropdown'
import Input from 'components/Core/Input/Input'
import Loader from 'components/Core/Loader/Loader'
import Modal from 'components/Core/Modal'
import Uploader from 'components/Core/Uploader/Uploader'

import messages from './ExcelOrdersModal.messages'
import styles from './ExcelOrdersModal.module.css'
import { MINIMUM_CHARACTERS_QUERY } from './ExcelOrdersModal.queries'
import { UploadExcelModalIds } from './excelOrdersModal.ids'

const DEBOUNCE_TIME = 500

export default class ExcelOrdersModal extends Component {
  state = {
    doorId: null,
    files: [],
    orderTypeId: null,
    poNumber: '',
    uploadingFiles: false,
    warehouseId: getKeyFromFirstElement(this.props.brandWarehouses, 'key'),
    modalIsLoading: false,
    retailerOptions: [],
  }

  componentDidUpdate = (prevProps) => {
    const finishedLoading =
      prevProps.connectedAccountsQueryLoading &&
      !this.props.connectedAccountsQueryLoading
    const searchTextChanged =
      prevProps.retailerSearchText !== this.props.retailerSearchText
    const minimumCharactersReached =
      this.props.retailerSearchText.length < MINIMUM_CHARACTERS_QUERY

    if (prevProps.loadingInitialData && !this.props.loadingInitialData) {
      this.updateSelectedWarehouseId(this.props.brandWarehouses)
    } else if (
      (prevProps.loadingConnectionsForRetailer &&
        !this.props.loadingConnectionsForRetailer) ||
      prevProps.selectedRetailerId !== this.props.selectedRetailerId
    ) {
      const usedWarehouses =
        this.props.retailerWarehouses.length > 0
          ? this.props.retailerWarehouses
          : this.props.brandWarehouses
      this.updateSelectedWarehouseId(usedWarehouses)
    }

    if (finishedLoading || searchTextChanged) {
      this.setState({
        retailerOptions: minimumCharactersReached
          ? []
          : this.getRetailerOptions(),
      })
    }
  }

  componentWillUnmount() {
    this.props.changeRetailerSearchText('')
    this.props.setSelectedRetailerId(null)
  }

  onRetailerDropdownChange = (_, { value }) => {
    if (this.props.selectedRetailerId !== value) {
      this.setState({
        doorId: null,
        warehouseId: null,
      })
      this.props.setSelectedRetailerId(value)
    }
  }

  onClose = () => {
    this.props.setSelectedRetailerId(null)
    this.props.onClose()
  }

  getRetailerOptions = () =>
    // TODO (jfernandez) sort will come from the backend
    formatDropdownOptions(
      this.props.retailers,
      formatRetailerWithLocation,
      true,
      'notranslate',
      'id',
    )

  getImportedFileFromResponse = (response) => {
    const { data, config } = response
    const filenameMatch = config.url.match(/file_to_import=(.+)&order_type/)
    const filename = filenameMatch && decodeURIComponent(filenameMatch[1])

    if (data.type !== 'application/json') {
      forceFileDownload(data, filename)
      return Promise.resolve({
        filename,
        orders: [],
        error: messages.failedImport,
      })
    }
    return this.getImportedFileFromBlob(data, filename)
  }

  getImportedFileFromBlob = (blob, filename) => {
    const file = { filename, error: null, orders: [] }
    const fileReader = new window.FileReader()
    return new Promise((resolve) => {
      fileReader.onload = (event) => {
        const body = JSON.parse(event.target.result)

        if (!body) {
          file.error = messages.incorrectFileType
        } else {
          file.error = body.error

          if (body.order_ids) {
            file.orders = body.order_ids.map((order) => ({
              id: order.jw_order_id,
              poNumber: order.reference_number,
            }))
          }
        }
        resolve(file)
      }
      fileReader.readAsText(blob)
    })
  }

  updateSelectedWarehouseId = (usedWarehouses) => {
    this.setState({
      warehouseId: getKeyFromFirstElement(usedWarehouses, 'key'),
    })
  }

  storeValueInState = (formValueType) => (_, { value }) => {
    this.setState({ [formValueType]: value })
  }

  importOrders = () => {
    this.setState({ modalIsLoading: true })

    const { files, warehouseId, orderTypeId, poNumber } = this.state

    const {
      openExcelSummaryModal,
      trackExcelOrderModalImportExcel,
    } = this.props

    const warehouseOptions = this.props.brandWarehouses
      ? this.props.brandWarehouses
      : this.props.retailerWarehouses
    const retailersOptions = this.getRetailerOptions()

    const imports = files.map((file) => {
      const url = `/orders/import_excel_single_file/?${this.importRequestParams(
        file,
      )}`
      // TODO(ch15463) rebuild endpoint
      return makeRequest({ method: 'get', url, responseType: 'blob' })
    })

    trackExcelOrderModalImportExcel({
      retailersOptions,
      warehouseOptions,
      warehouseId,
      orderTypeId,
      poNumber,
    })

    return Promise.all(imports)
      .then((responses) => {
        const importedFiles = Promise.all(
          responses.map(this.getImportedFileFromResponse),
        )
        return Promise.all([importedFiles, this.props.handleResetOrder()])
      })
      .then(([importedFiles]) => {
        this.setState({ modalIsLoading: false })
        this.props.setImportedFiles(importedFiles)
        openExcelSummaryModal()
        return importedFiles
      })
  }

  importRequestParams = (file) => {
    const { selectedRetailerId } = this.props
    const { doorId, orderTypeId, poNumber, warehouseId } = this.state
    const params = {
      file_to_import: file,
      order_type: orderTypeId ? fromGlobalId(orderTypeId).id : '',
      retailer_account_id: fromGlobalId(selectedRetailerId).id,
      warehouse_name: warehouseId ? fromGlobalId(warehouseId).id : '',
      door_name: doorId ? fromGlobalId(doorId).id : '',
      po_number: poNumber || '',
    }
    return qs.stringify(params)
  }

  uploadOrdersFiles = (files) => {
    this.setState({ uploadingFiles: true })

    const uploads = files.map((file) => {
      const data = new window.FormData()
      data.append('files[]', file)

      return makeRequest({
        headers: {
          Accept: 'application/json; text/javascript',
          'content-type': 'application/json',
        },
        method: 'post',
        url:
          '/orders/process_excel_single_file?format=xlsx&order_id=0&newUploader=0',
        data,
      })
    })

    return Promise.all(uploads).then((result) => {
      const newFiles = result
        .filter((response) => response.data && response.data[0])
        .map((response) => response.data[0].name)

      this.setState((currentState) => ({
        files: [...currentState.files, ...newFiles],
        uploadingFiles: false,
      }))

      return result
    })
  }

  handleSearchRetailer = debounce((e, { searchQuery }) => {
    if (searchQuery !== this.state.retailerSearchText) {
      this.props.changeRetailerSearchText(searchQuery)
    }
  }, DEBOUNCE_TIME)

  renderWarehouses = (useBrandWarehouses) => [
    <p className={styles.label} key="warehousesLabel">
      {messages.selectWarehouse}
    </p>,
    <Dropdown
      key="warehousesDropdown"
      onChange={this.storeValueInState('warehouseId')}
      options={
        useBrandWarehouses
          ? this.props.brandWarehouses
          : this.props.retailerWarehouses
      }
      placeholder={messages.selectOne}
      value={this.state.warehouseId}
      className="notranslate"
    />,
  ]

  render() {
    const {
      doors,
      loadingInitialData,
      loadingConnectionsForRetailer,
      orderTypes,
      selectedRetailerId,
      permissions: { restrictCreateCustomPo },
      retailerWarehouses,
      connectedAccountsQueryLoading,
      openCreateOrderTemplateModal,
    } = this.props

    const {
      doorId,
      orderTypeId,
      poNumber,
      uploadingFiles,
      files,
      modalIsLoading,
      retailerOptions,
    } = this.state

    const useBrandWarehouses = !selectedRetailerId || !retailerWarehouses.length
    const primaryDisabled = !files.length || modalIsLoading

    return (
      <Modal
        large
        loading={modalIsLoading}
        onClose={this.onClose}
        open
        primaryActionClose={false}
        primaryActionLabel={messages.upload}
        primaryActionOnClick={this.importOrders}
        primaryDisabled={primaryDisabled}
        title={messages.excelOrders}
        className={styles.ExcelOrders}
        id={UploadExcelModalIds.ModalTitleId}
        primaryActionId={UploadExcelModalIds.UploadButtonId}
      >
        <p className={styles.label}>{messages.step1}</p>
        <Button
          className={styles.wide}
          primary
          withGrid
          onClick={openCreateOrderTemplateModal}
        >
          {messages.createTemplate}
        </Button>
        <Divider />
        <p className={styles.label}>{messages.step2}</p>
        <Loader active={loadingConnectionsForRetailer || loadingInitialData}>
          <p className={styles.label}>{messages.selectRetailer}</p>
          <Dropdown
            onChange={this.onRetailerDropdownChange}
            onSearchChange={this.handleSearchRetailer}
            loading={connectedAccountsQueryLoading}
            options={retailerOptions}
            placeholder={messages.startTyping}
            search
            value={selectedRetailerId}
            className={selectedRetailerId ? 'notranslate' : ''}
          />
          {doors.length > 0 && [
            <p className={styles.label} key="doorsLabel">
              <span className={styles.required}>*</span>
              {messages.selectDoor}
            </p>,
            <Dropdown
              key="doorsDropdown"
              onChange={this.storeValueInState('doorId')}
              options={doors}
              placeholder={messages.selectOne}
              search
              value={doorId}
              className={doorId ? 'notranslate' : ''}
            />,
          ]}
          {this.renderWarehouses(useBrandWarehouses)}
          <p className={styles.label}>{messages.selectOrderType}</p>
          <Dropdown
            onChange={this.storeValueInState('orderTypeId')}
            options={orderTypes}
            placeholder={messages.selectOne}
            value={orderTypeId}
            className={orderTypeId ? 'notranslate' : ''}
          />
          <p className={styles.label}>{messages.poNumber}</p>
          <Input
            onChange={this.storeValueInState('poNumber')}
            placeholder={messages.customPo}
            value={poNumber}
            disabled={restrictCreateCustomPo}
          />
        </Loader>
        <p className={styles.label}>{messages.selectDragFiles}</p>
        <Loader className={styles.loader} active={uploadingFiles}>
          <Uploader multi onUploadedFiles={this.uploadOrdersFiles} />
        </Loader>
      </Modal>
    )
  }
}

ExcelOrdersModal.propTypes = {
  accountId: PropTypes.number.isRequired,
  doors: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  loadingInitialData: PropTypes.bool,
  loadingConnectionsForRetailer: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  orderTypes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleResetOrder: PropTypes.func,
  retailers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayName: PropTypes.string,
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          city: PropTypes.string,
          state: PropTypes.string,
        }),
      ),
    }),
  ),
  selectedRetailerId: PropTypes.string,
  setImportedFiles: PropTypes.func.isRequired,
  setSelectedRetailerId: PropTypes.func.isRequired,
  retailerWarehouses: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  brandWarehouses: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  permissions: PropTypes.shape({
    restrictCreateCustomPo: PropTypes.bool.isRequired,
  }).isRequired,
  changeRetailerSearchText: PropTypes.func.isRequired,
  retailerSearchText: PropTypes.string.isRequired,
  connectedAccountsQueryLoading: PropTypes.bool,
  openCreateOrderTemplateModal: PropTypes.func,
  openExcelSummaryModal: PropTypes.func,
  trackExcelOrderModalImportExcel: PropTypes.func,
}

ExcelOrdersModal.defaultProps = {
  retailers: [],
  doors: [],
  loadingInitialData: false,
  loadingConnectionsForRetailer: false,
  selectedRetailerId: null,
  retailerWarehouses: [],
  brandWarehouses: [],
  connectedAccountsQueryLoading: false,
  handleResetOrder: () => {},
  openCreateOrderTemplateModal: () => {},
  openExcelSummaryModal: () => {},
  trackExcelOrderModalImportExcel: () => {},
}
