import { ADD_CONNECTIONS_MODAL as FEATURE_PREFIX } from 'featurePrefix.ids'

import { FileErrors } from './ConnectionRequestModal.types'

const MODAL_PREFIX = 'connections-modal'

export const ConnectionsModalIds = {
  ModalTitle: `${FEATURE_PREFIX}__${MODAL_PREFIX}__title`,
  PriceInfo: `${FEATURE_PREFIX}__${MODAL_PREFIX}__price-info`,
  DataTemplateLink: `${FEATURE_PREFIX}__${MODAL_PREFIX}__data-template-link`,
  DropTemplateZone: `${FEATURE_PREFIX}__${MODAL_PREFIX}__drop-template-zone`,
  UploadTemplateInput: `${FEATURE_PREFIX}__${MODAL_PREFIX}__upload-template-input`,
  SubmitRequestButton: `${FEATURE_PREFIX}__${MODAL_PREFIX}__submit-request-button`,
  [FileErrors.TOO_MANY_FILES]: `${FEATURE_PREFIX}__${MODAL_PREFIX}__file-error__too-many-files`,
  [FileErrors.FILE_INVALID_TYPE]: `${FEATURE_PREFIX}__${MODAL_PREFIX}__file-error__file-invalid-type`,
  [FileErrors.FILE_TOO_LARGE]: `${FEATURE_PREFIX}__${MODAL_PREFIX}__file-error__file-too-large`,
} as const
