import PropTypes from 'prop-types'

import { Icon, IconButton } from 'components/Core'
import Input from 'components/Core/Input/Input'

import useInput from './PriceRange.hooks'
import styles from './PriceRange.module.css'

const MAX_INPUT_LENGTH = 8
const NUMBER_REGEX = /^\d*\.?\d*$/

const isValidFilterInput = (number) =>
  parseInt(number) > 0 && !isNaN(number) && number.length <= MAX_INPUT_LENGTH

const isValidNumber = (value) => NUMBER_REGEX.test(value)

const buttonValidations = [
  {
    accept: (minValue, maxValue) =>
      isValidFilterInput(minValue) && isValidFilterInput(maxValue),
  },
  {
    accept: (minValue, maxValue) => !minValue && isValidFilterInput(maxValue),
  },
  {
    accept: (minValue, maxValue) => !maxValue && isValidFilterInput(minValue),
  },
]

const PriceRange = ({ applyFilter, currency }) => {
  const { value: minValue, onChange: handleMinValueChange } = useInput('')
  const { value: maxValue, onChange: handleMaxValueChange } = useInput('')

  const buttonEnabled = buttonValidations.some((strategy) =>
    strategy.accept(minValue, maxValue),
  )
  return (
    <div className={styles.PriceRange}>
      <Input
        name="minValue"
        value={minValue}
        onChange={(e) =>
          isValidNumber(e.target.value) && handleMinValueChange(e)
        }
        placeholder={currency}
        className={styles.rangeMinInputFilter}
        data-testid="minValue"
      />
      <Input
        name="maxValue"
        value={maxValue}
        onChange={(e) =>
          isValidNumber(e.target.value) && handleMaxValueChange(e)
        }
        placeholder={currency}
        className={styles.rangeMaxInputFilter}
        data-testid="maxValue"
      />
      <IconButton
        disabled={!buttonEnabled}
        className={styles.PriceRangeButton}
        onClick={() => applyFilter(minValue, maxValue)}
        testId="filterButton"
      >
        <Icon disabled={!buttonEnabled} name="filter" />
      </IconButton>
    </div>
  )
}

PriceRange.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
}

export default PriceRange
