import { SUBMISSIONS_LIST } from 'routes/paths'

import DropdownMenuTextLink from 'features/Navbar/NavbarRevamp/components/DropdownMenuTextLink/DropdownMenuTextLink'
import { useNewSubmissions } from 'features/Navbar/NavbarRevamp/components/SubmissionsListButton/submissionsListButton.hooks'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { ExploreDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

export const SubmissionsLink = () => {
  const newSubmissionsCount = useNewSubmissions()
  return (
    <DropdownMenuTextLink
      to={SUBMISSIONS_LIST}
      message={messages.interestedInYou}
      id={ExploreDropdownIds.InterestedInYou}
      testId={ExploreDropdownIds.InterestedInYou}
      count={newSubmissionsCount}
    />
  )
}
