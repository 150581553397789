import map from 'lodash/map'
import sortBy from 'lodash/sortBy'

import ArrayOfIdsFilter from 'utils/filters/arrayOfIdsFilter'
import ArrayOfObjectsFilter from 'utils/filters/arrayOfObjectsFilter'
import SingleIdFilter from 'utils/filters/singleIdFilter'
import { formatDateWithInputFormat } from 'utils/formatters'

import {
  PRODUCT_DIVISION_CODE,
  PRODUCT_FABRICATION_NAME_ID,
  PRODUCT_SILHOUETTE_NAME_ID,
} from 'shop/products/traits'

import styles from './CatalogFilters.module.css'
import PriceRangeFilter from 'containers/ProductCatalog/PriceRange/priceRangeFilter'

// import sortByProperty from './utils'

export const COLLECTIONS_KEY = 'collectionIds'
export const SEASONS_KEY = 'seasonIds'
export const DELIVERY_WINDOW_KEY = 'deliveryDates'
export const UNAVAILABLE_PRICE_TYPES_FILTER_KEY = 'priceTypeId'
export const UNAVAILABLE_PRICE_TYPES_DATA_KEY = 'unavailablePriceType'
export const TRAITS_KEY = 'traitValueInputs'
export const INVENTORY_AVAILABILITY_FILTER_KEY = 'inventoryAvailability'
export const EXCLUDE_SOLD_OUT_STYLES_KEY = 'excludeSouldOutStyles'
export const HIDE_SOLD_OUT_STYLES_KEY = 'hideSouldOutStyles'
export const SILHOUETTES_KEY = 'silhouettes'
export const DIVISION_KEY = 'divisionIds'
export const FABRICATION_KEY = 'fabricationIds'
export const STYLE_TAGS_KEY = 'styleTagsFilter'
export const PRICE_RANGE_FILTER_KEY = 'priceRange'
export const BADGES_KEY = 'badgeIds'

// KEYS from all the filters that should not be formatted by appliedFiltersToGraphQLVariables
export const EXCLUDE_FILTER_FROM_GRAPHQL_KEYS = [
  DELIVERY_WINDOW_KEY,
  INVENTORY_AVAILABILITY_FILTER_KEY,
  DIVISION_KEY,
  SILHOUETTES_KEY,
  FABRICATION_KEY,
]

export const TRAIT_FILTER_KEYS = [
  {
    filterKey: DIVISION_KEY,
    traitCode: PRODUCT_DIVISION_CODE,
  },
  {
    filterKey: SILHOUETTES_KEY,
    traitCode: PRODUCT_SILHOUETTE_NAME_ID,
  },
  {
    filterKey: FABRICATION_KEY,
    traitCode: PRODUCT_FABRICATION_NAME_ID,
  },
]

export const getCatalogFilters = (localizeUserData, selectedPriceType) => ({
  [COLLECTIONS_KEY]: new ArrayOfIdsFilter({
    filterKey: COLLECTIONS_KEY,
    label: 'Linesheet',
    dataKey: 'collections',
    nameFormatter: (filterOption) => filterOption.text,
    styles: styles.dynamicFilter,
    parentClassName: styles.filterDropdown,
  }),
  [SEASONS_KEY]: new ArrayOfIdsFilter({
    filterKey: SEASONS_KEY,
    label: 'Season',
    dataKey: 'seasons',
    nameFormatter: (filterOption) => filterOption.text,
    styles: styles.dynamicFilter,
    parentClassName: styles.filterDropdown,
  }),
  [DELIVERY_WINDOW_KEY]: new ArrayOfIdsFilter({
    filterKey: DELIVERY_WINDOW_KEY,
    label: 'Linesheet delivery window',
    dataKey: 'deliveryWindows',
    nameFormatter: (filterOption) => filterOption.text,
    styles: styles.dynamicFilter,
    parentClassName: styles.filterDropdown,
  }),
  [UNAVAILABLE_PRICE_TYPES_FILTER_KEY]: new SingleIdFilter({
    filterKey: UNAVAILABLE_PRICE_TYPES_FILTER_KEY,
    label: 'Price Types',
    dataKey: UNAVAILABLE_PRICE_TYPES_DATA_KEY,
    styles: styles.dynamicFilter,
    parentClassName: styles.filterDropdown,
    translateOptions: true,
  }),
  [INVENTORY_AVAILABILITY_FILTER_KEY]: new SingleIdFilter({
    filterKey: INVENTORY_AVAILABILITY_FILTER_KEY,
    label: 'Inventory Availability',
    dataKey: INVENTORY_AVAILABILITY_FILTER_KEY,
    styles: styles.dynamicFilter,
    parentClassName: styles.filterDropdown,
    translateOptions: true,
  }),
  [TRAITS_KEY]: new ArrayOfObjectsFilter({
    filterKey: TRAITS_KEY,
    label: 'Categories',
    dataKey: 'categories',
    styles: styles.dynamicFilter,
    parentClassName: styles.filterDropdown,
    objectKey: 'traitCode',
    objectValuesKey: 'traitValueIds',
    groupByCode: 'code',
    hoverableSelect: true,
    shouldTranslateValues: true,
  }),
  [SILHOUETTES_KEY]: new ArrayOfIdsFilter({
    filterKey: SILHOUETTES_KEY,
    label: 'Silhouette',
    dataKey: SILHOUETTES_KEY,
    styles: styles.dynamicFilter,
    parentClassName: styles.filterDropdown,
    translateOptions: localizeUserData,
  }),
  [DIVISION_KEY]: new ArrayOfIdsFilter({
    filterKey: DIVISION_KEY,
    label: 'Division',
    dataKey: 'divisions',
    styles: styles.dynamicFilter,
    parentClassName: styles.filterDropdown,
    translateOptions: localizeUserData,
  }),
  [FABRICATION_KEY]: new ArrayOfIdsFilter({
    filterKey: FABRICATION_KEY,
    label: 'Fabrication',
    dataKey: 'fabrications',
    styles: styles.dynamicFilter,
    parentClassName: styles.filterDropdown,
    translateOptions: localizeUserData,
  }),
  [STYLE_TAGS_KEY]: new ArrayOfObjectsFilter({
    filterKey: STYLE_TAGS_KEY,
    label: 'Style Tags',
    dataKey: 'styleTagsFilter',
    styles: styles.dynamicFilter,
    disabledStyles: styles.disabledFilterItem,
    parentClassName: styles.filterDropdown,
    objectKey: 'level',
    objectValuesKey: 'values',
    groupByCode: 'code',
    hoverableSelect: true,
    shouldTranslateValues: true,
  }),
  [PRICE_RANGE_FILTER_KEY]: new PriceRangeFilter({
    filterKey: PRICE_RANGE_FILTER_KEY,
    label: 'Wholesale Price Range',
    dataKey: PRICE_RANGE_FILTER_KEY,
    selectedPriceType,
  }),
  [BADGES_KEY]: new ArrayOfIdsFilter({
    filterKey: BADGES_KEY,
    label: 'Badges',
    dataKey: BADGES_KEY,
    styles: styles.dynamicFilter,
    parentClassName: styles.filterDropdown,
    translateOptions: localizeUserData,
  }),
})

export const getFilterSetup = (localizeUserData, selectedPriceType) => {
  const catalogFilters = getCatalogFilters(localizeUserData, selectedPriceType)
  const arrayOfFilters = [
    {
      key: COLLECTIONS_KEY,
      title: 'Linesheet',
      filters: [catalogFilters[COLLECTIONS_KEY]],
    },
    {
      key: SEASONS_KEY,
      title: 'Season',
      filters: [catalogFilters[SEASONS_KEY]],
    },
    {
      key: DELIVERY_WINDOW_KEY,
      title: 'Linesheet Delivery Window',
      filters: [catalogFilters[DELIVERY_WINDOW_KEY]],
    },
    {
      key: UNAVAILABLE_PRICE_TYPES_FILTER_KEY,
      title: 'Price Types',
      filters: [catalogFilters[UNAVAILABLE_PRICE_TYPES_FILTER_KEY]],
    },
    {
      key: INVENTORY_AVAILABILITY_FILTER_KEY,
      title: 'Inventory Availability',
      filters: [catalogFilters[INVENTORY_AVAILABILITY_FILTER_KEY]],
    },
    {
      key: TRAITS_KEY,
      title: 'Categories',
      filters: [catalogFilters[TRAITS_KEY]],
    },
    {
      key: SILHOUETTES_KEY,
      title: 'Silhouette',
      filters: [catalogFilters[SILHOUETTES_KEY]],
    },
    {
      key: DIVISION_KEY,
      title: 'Division',
      filters: [catalogFilters[DIVISION_KEY]],
    },
    {
      key: FABRICATION_KEY,
      title: 'Fabrication',
      filters: [catalogFilters[FABRICATION_KEY]],
    },
    {
      key: STYLE_TAGS_KEY,
      title: 'Style Tags',
      filters: [catalogFilters[STYLE_TAGS_KEY]],
    },
    {
      key: PRICE_RANGE_FILTER_KEY,
      title: 'Wholesale Price Range',
      filters: [catalogFilters[PRICE_RANGE_FILTER_KEY]],
    },
    {
      key: BADGES_KEY,
      title: 'Badges',
      filters: [catalogFilters[BADGES_KEY]],
    },
  ]
  return sortBy(arrayOfFilters, 'title')
}

export const formatDeliveryWindow = (
  deliveryWindows,
  inputDateFormat = 'MM/DD/YYYY',
) =>
  map(deliveryWindows, (window) => {
    const value = { startDate: null, endDate: null }
    const parts = window.text.split(' - ')
    if (parts[0] !== 'Immediate') {
      value.startDate = formatDateWithInputFormat(
        parts[0],
        inputDateFormat,
        'YYYY-MM-DD',
      )
      value.endDate = parts[1]
        ? formatDateWithInputFormat(parts[1], inputDateFormat, 'YYYY-MM-DD')
        : null
    }
    return value
  })
