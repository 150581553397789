import { Icon, SecondaryButton, TextButton } from '@joor/design-system'
import styled from 'styled-components/macro'

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const SectionTitle = styled.span`
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-base);
  font-weight: var(--font-weight-medium);
  line-height: 16px;
  letter-spacing: var(--letter-spacing-normal);
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`
export const AccountLabel = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;

  @media only screen and (max-width: 830px) {
    max-width: 140px;
  }

  @media only screen and (max-width: 970px) {
    max-width: 160px;
  }
`

export const BadgeWrapper = styled.div`
  > :first-child {
    padding-right: 10px;
  }
`

export const MergeButton = styled(SecondaryButton)`
  align-self: center;
  width: fit-content;
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid var(--color-neutral-000-full);
  background-color: var(--color-neutral-100);
  margin-right: 5px;
`

export const StyledLogo = styled.img`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  object-fit: contain;
`

export const StyledImagePlaceholder = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid var(--color-neutral-000-full);
  background-color: var(--color-primary-100);
  margin-right: 5px;
`

export const StyledPlaceholder = styled(Icon)`
  > svg > g {
    fill: var(--color-primary-400);
  }
`

export const OptionWrapper = styled.div`
  border-bottom: 1px solid var(--color-primary-400);
  padding: 8px 0;

  &:first-child {
    padding-top: 0;

    > .primary-account {
      padding: 8px 0;
    }
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
  }
`
export const OptionsContainer = styled.div`
  border: 1px solid var(--color-primary-200);
  border-radius: 0px 0px 4px 4px;
  background: var(--color-primary-100);
  padding: 10px;
  max-width: 504px;
  width: 360px;
  max-height: 624px;
  overflow-y: auto;
  text-align: initial;

  &.mobile-full-width {
    max-width: unset;
    width: 100%;
    padding: 0;
    border: none;
  }
`
export const AccountOption = styled.div`
  padding: 8px 0;
`

export const SearchBar = styled.div`
  justify-content: center;
  color: var(--color-primary-600);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-tighter);
  > div {
    width: -moz-available; // Firefox
    width: -webkit-fill-available; // Chrome, Safari, Edge
  }
  input {
    text-align: left;
    font-size: 12px;
    font-weight: var(--font-weight-medium);
    font-family: var(--font-family-alpha);
    font-style: normal;
  }
`

export const StyledTextButton = styled(TextButton)`
  font-size: var(--size-font-small) !important;
  margin: 8px 0px !important;
  cursor: pointer !important;
`
