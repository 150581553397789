export const addHttps = (url: string): string => {
  if (url.includes('https')) return url

  if (url.includes('http')) return url.replace('http', 'https')

  return 'https://' + url
}

export const getURLFromString = (url: string): URL | null => {
  try {
    const prepared = addHttps(url)
    return new URL(prepared)
  } catch (err) {
    console.error(err)
    return null
  }
}
