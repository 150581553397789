import { Component } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Truncate from 'react-truncate'
import { Table as BaseTable } from 'semantic-ui-react'

import ConditionalWrap from 'components/Core/ConditionalWrap'
import Tooltip from 'components/Core/Tooltip/Tooltip'

import styles from './Cell.module.css'

// unused var, but imports global style

export const EMPTY_CELL_CONTENT = '—'

class Cell extends Component {
  state = { isTruncated: false }

  handleTruncate = (isTruncated) => {
    if (!this.state.isTruncated && isTruncated) {
      this.setState({ isTruncated: true })
    }
  }

  renderContents = () => {
    const { icon, children, truncatelines } = this.props

    const contentIsString = typeof children === 'string'

    const wrapper = (wrappedChildren) => (
      <div>
        <Truncate lines={truncatelines} onTruncate={this.handleTruncate}>
          {wrappedChildren}
        </Truncate>
      </div>
    )

    const content = this.props.emptyCell
      ? ''
      : this.props.children || EMPTY_CELL_CONTENT

    return (
      <>
        <ConditionalWrap condition={contentIsString} wrap={wrapper}>
          {content}
        </ConditionalWrap>
        {icon}
      </>
    )
  }

  renderLinkedContents = (url, shouldLinkOpenInNewTab, onClick) => {
    const extraProps = { onClick }
    if (shouldLinkOpenInNewTab) extraProps.target = '_blank'

    if (!url.baseURL) {
      return (
        <Link
          to={url}
          style={{ color: 'inherit', display: 'block' }}
          {...extraProps}
        >
          {this.renderContents()}
        </Link>
      )
    }
    return (
      <a
        href={
          new URL(
            (url.pathname || '/') + (url.search || '') + (url.hash || ''),
            url.baseURL,
          )
        }
        style={{ color: 'inherit', display: 'block' }}
        {...extraProps}
      >
        {this.renderContents()}
      </a>
    )
  }

  render() {
    const {
      width,
      className,
      link,
      shouldLinkOpenInNewTab,
      disableTooltip,
      tooltip,
      tooltipPosition,
      tooltipNotranslate,
      rightAlign,
      bottomAlign,
      emptyCell: _emptyCell,
      icon: _icon,
      centerAlign,
      verticalAlign,
      onClickLink,
      thickBorder,
      ...rest
    } = this.props

    const widthClass = width ? `${width} wide` : ''
    const childProps = {
      ...rest,
      width: null,
      className: classNames(widthClass, className, styles.Cell, {
        rightAlign,
        bottomAlign,
        centerAlign,
        verticalAlign,
        [styles.thickBorder]: thickBorder,
      }),
    }

    const cell = (
      <BaseTable.Cell {...childProps} style={this.props.style}>
        {link
          ? this.renderLinkedContents(link, shouldLinkOpenInNewTab, onClickLink)
          : this.renderContents()}
      </BaseTable.Cell>
    )

    const tooltipProps = {
      trigger: cell,
      [tooltipNotranslate && 'className']: 'notranslate',
      content: tooltip || this.props.children,
      [tooltipPosition && 'position']: tooltipPosition,
    }

    const cellWithTooltip = <Tooltip {...tooltipProps} />

    const showTooltip = tooltip || this.state.isTruncated

    return disableTooltip || !showTooltip ? cell : cellWithTooltip
  }
}

Cell.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  width: PropTypes.oneOf([
    'extra-large',
    'large',
    'medium',
    'small',
    'extra-small',
    'small-medium',
    'checkbox',
    'date',
    'total-order-table',
    'assortment-sku',
    'indicator',
  ]),
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  shouldLinkOpenInNewTab: PropTypes.bool,
  disableTooltip: PropTypes.bool,
  tooltip: PropTypes.node,
  tooltipPosition: PropTypes.string,
  tooltipNotranslate: PropTypes.bool,
  rightAlign: PropTypes.bool,
  bottomAlign: PropTypes.bool,
  emptyCell: PropTypes.bool,
  centerAlign: PropTypes.bool,
  truncatelines: PropTypes.number,
  style: PropTypes.object,
  onClickLink: PropTypes.func,
  icon: PropTypes.node,
  thickBorder: PropTypes.bool,
}

Cell.defaultProps = {
  as: '',
  children: null,
  className: '',
  width: null,
  link: null,
  shouldLinkOpenInNewTab: false,
  disableTooltip: false,
  tooltip: null,
  tooltipPosition: null,
  tooltipNotranslate: false,
  rightAlign: false,
  bottomAlign: false,
  emptyCell: false,
  centerAlign: false,
  truncatelines: 2,
  style: {},
  onClickLink: null,
  icon: null,
  thickBorder: false,
}

export default Cell
