import { Icon, Text } from '@joor/design-system'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { formatConnectedAccounts, formatDate } from 'utils/formatters'

import { getDateFormat } from 'store/currentUser/selectors'

import { ConnectedAccountType } from '../../NavbarLiteRetailer.queries'
import {
  CreationInfoContainer,
  Info,
  MenuConnectedAccounts,
  MenuTextMessage,
  MenuTextMessageCheck,
  PrimaryWrapper,
  PrivacyWrapper,
  TextIconsWrapper,
} from './AccountOption.ui'

type AccountInfoType = {
  accountName: string
  connectedAccounts?: ConnectedAccountType[]
  checked?: boolean
  privacy: boolean
  createdAt: string
  isPrimary: boolean
  hideVisibilityIcon?: boolean
  createdBy?: string | null
  disabled?: boolean
}

const AccountInfo = ({
  accountName,
  connectedAccounts = [],
  checked = false,
  isPrimary,
  createdAt,
  privacy,
  hideVisibilityIcon = false,
  createdBy,
  disabled = false,
}: AccountInfoType) => {
  const textClassName = disabled ? 'disabled' : checked ? 'checked' : ''
  const userDateFormat = useSelector(getDateFormat) as string

  const formattedDate = formatDate(createdAt, userDateFormat)
  return (
    <>
      {isPrimary && (
        <PrimaryWrapper>
          <Icon
            iconName="pushpin"
            plain
            iconSize="small"
            className={disabled ? 'primary-icon' : 'accent-icon'}
          />
          <Text
            fontFamily="numeric"
            fontSize="extrasmall"
            color={disabled ? 'primary-400' : 'accent-beta-700'}
          >
            PRIMARY
          </Text>
        </PrimaryWrapper>
      )}
      <TextIconsWrapper>
        <PrivacyWrapper>
          <MenuTextMessage className={classNames(textClassName, 'notranslate')}>
            {accountName}
          </MenuTextMessage>
        </PrivacyWrapper>
        <MenuTextMessageCheck className={textClassName}>
          {!hideVisibilityIcon && (
            <Icon
              iconName={privacy ? 'visibility-on' : 'visibility-off'}
              iconSize="small"
              plain
            />
          )}
          {checked && <Icon iconName="checkmark" plain />}
        </MenuTextMessageCheck>
      </TextIconsWrapper>
      {!!connectedAccounts.length && (
        <MenuConnectedAccounts className={disabled ? 'disabled' : ''}>
          {formatConnectedAccounts(connectedAccounts)}
        </MenuConnectedAccounts>
      )}
      {(createdBy || createdAt) && (
        <CreationInfoContainer createdBy={Boolean(createdBy)}>
          {createdBy && (
            <Info className={disabled ? 'disabled' : ''}>
              Created by {createdBy}
            </Info>
          )}
          <Info className={disabled ? 'disabled' : ''}>
            {!createdBy && 'Created date:'} {formattedDate}
          </Info>
        </CreationInfoContainer>
      )}
    </>
  )
}

export default AccountInfo
