/* eslint-disable react/jsx-filename-extension */
// LaunchDarkly now provides native support for React v16.3.0+ leveraging the React Context API.
// Due to this and limitations of the previous third party, this v2 solution should be used going forward.
// SDK documentation: https://docs.launchdarkly.com/docs/react-sdk-reference
import {
  ComponentType,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react'

import {
  LDClient,
  LDFlagSet,
  asyncWithLDProvider,
  withLDConsumer,
} from '@joor/launchdarkly-react-client-sdk'

import { CurrentUserInfo, getLaunchDarklyUserInfo } from 'utils/userInfo'

import LoaderOverlay from 'components/Loader/LoaderOverlay'

type LDProvider = ({ children }: { children: ReactNode }) => JSX.Element

const REACT_APP_LAUNCH_DARKLY_KEY =
  process.env.REACT_APP_LAUNCH_DARKLY_KEY || ''

const REACT_APP_LAUNCHDARKLY_RELAY_URL =
  process.env.REACT_APP_LAUNCHDARKLY_RELAY_URL

const options = {
  baseUrl: REACT_APP_LAUNCHDARKLY_RELAY_URL,
  streamUrl: REACT_APP_LAUNCHDARKLY_RELAY_URL,
}

export const LaunchDarklyWrapper = ({
  children,
  userInfo,
}: {
  children: ReactElement
  userInfo: CurrentUserInfo
}) => {
  const [LDProvider, setLDProvider] = useState<LDProvider | null>(null)

  useEffect(() => {
    let isMounted = true

    ;(async () => {
      const provider = await asyncWithLDProvider({
        clientSideID: REACT_APP_LAUNCH_DARKLY_KEY,
        context: {
          kind: 'user',
          ...getLaunchDarklyUserInfo(userInfo),
        },
        ...(REACT_APP_LAUNCHDARKLY_RELAY_URL && { options }),
      })

      if (isMounted) {
        setLDProvider(() => provider)
      }
    })()

    return () => {
      isMounted = false
    }
  }, [])

  if (!LDProvider) {
    return <LoaderOverlay />
  }

  return <LDProvider>{children}</LDProvider>
}

export const withLaunchDarkly = (
  Component: ComponentType<{
    flags?: LDFlagSet
    ldClient?: LDClient
  }>,
) => withLDConsumer()(Component)
