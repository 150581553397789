import { Component } from 'react'

import PropTypes from 'prop-types'

import ProductDetailModal from 'modals/Shop/ProductDetailModal'
import SkuDeliveryModal from 'modals/Shop/SkuDeliveryModal'
import { PRODUCT_CATALOG } from 'routes/paths'

import { handleShowOnlyProductsContent } from './LegacyPage.utils'
import NewsBanner from 'containers/NewsBanner'
import ConnectionRequestModal from 'features/Connection/modals/ConnectionRequestModal/ConnectionRequestModal'
import OrderReviewReleaseHeader from 'features/Hubs/Orders/Review/components/OrderReviewReleaseHeader/OrderReviewReleaseHeader'
import { ProductsEditEvents } from 'features/Hubs/Orders/Review/modals/ProductsEditModal/components/ProductsEditEvents'
import { CUSTOMER_DATA_PARAM } from 'features/LeadGen/RetailerProfile/RetailerProfileViewMode/RetailerProfileViewMode'

class LegacyPage extends Component {
  componentDidMount() {
    if (this.requestIsFromReact()) {
      this.refreshPage()
    } else {
      window.addEventListener('openModal', this.handleModalFromPHP, false)
      window.addEventListener('openCatalog', this.handleCatalogFromPHP, false)
      window.addEventListener(
        'newOrderReview',
        this.handleOrderReviewFromPHP,
        false,
      )

      this.showLegacyContent()
    }

    this.props.trackPageView()
  }

  componentDidUpdate(prevProps) {
    if (
      this.isPushAction() ||
      this.hasPageChanged(prevProps.location, this.props.location)
    ) {
      // Refresh the page when going from one legacy page to another one
      this.refreshPage()
    }
  }

  getURLFromLocation = (location) =>
    location.pathname + location.search + location.hash

  handleModalFromPHP = ({ detail }) => {
    if (detail.modalName === 'ProductDetailModal') {
      this.props.openDynamicModal({ component: ProductDetailModal, ...detail })
    }

    if (detail.modalName === 'SkuDeliveryModal') {
      this.props.openDynamicModal({
        component: SkuDeliveryModal,
        ...detail,
      })
    }

    if (detail.modalName === 'ConnectionRequestModal') {
      this.props.openDynamicModal({
        component: ConnectionRequestModal,
        ...detail,
      })
    }
  }

  handleCatalogFromPHP = ({ detail: { collection, brandId } }) => {
    this.props.history.push({
      pathname: PRODUCT_CATALOG,
      state: { collection, brandId, fromPHP: true },
    })
  }

  handleOrderReviewFromPHP = () => {
    this.props.setIsNewOrderReviewExperience(true)
  }

  pagesHaveHash = (sourceLocation, targetLocation) =>
    sourceLocation.hash && targetLocation.hash

  pagesAreException = (sourceURL, targetURL) => {
    const exceptions = ['#!']
    return exceptions.some(
      (exception) =>
        sourceURL.includes(exception) || targetURL.includes(exception),
      false,
    )
  }

  hasPageChanged = (sourceLocation, targetLocation) => {
    // Strip parts of the location from the URLs and compare them to determine
    // whether the page was changed or not.
    // We need to clean the URLs because PHP will sometimes change a URL without
    // taking the user to another page (e.g. selecting linesheets in the shop)
    const regex = /\/?(\d+.*)?(\?.*)?(#.*)/
    const sourceURL = this.getURLFromLocation(sourceLocation)
    const targetURL = this.getURLFromLocation(targetLocation)
    const sourcePath = sourceURL.replace(regex, '')
    const targetPath = targetURL.replace(regex, '')
    const urlHasChanged = sourcePath !== targetPath
    const pagesHaveHash = this.pagesHaveHash(sourceLocation, targetLocation)
    const pagesAreException = this.pagesAreException(sourceURL, targetURL)
    return urlHasChanged && (pagesHaveHash || pagesAreException)
  }

  isPushAction = () => this.props.history.action === 'PUSH'

  refreshPage = () => {
    // Force browser to refresh the page in order to load the content from PHP
    window.location.reload()
  }

  requestIsFromReact = () => {
    const rootContainer = document.querySelector('body > div.joor-react')
    const previousPageUsedReact =
      rootContainer && rootContainer.classList.contains('react-page')
    return this.isPushAction() || previousPageUsedReact
  }

  showLegacyContent = () => {
    // A <div> with the id "content-overlay" hides all the content of the pages except
    // for the navigation bar.
    // This container is always displayed on top of everything and the following code
    // removes it to reveal the content rendered by PHP.
    const contentOverlay = document.getElementById('content-overlay')
    if (contentOverlay) {
      contentOverlay.remove()
    }
  }

  isOnAdmin = () => window.location.pathname.includes('/admin')

  hideReactNavbar = () => {
    if (window.location.href.includes(CUSTOMER_DATA_PARAM)) {
      const navbarEmbeddedInPHP = document.querySelectorAll(
        "[data-testid='navbar']",
      )
      navbarEmbeddedInPHP.style.display = 'none'
    }
  }

  render() {
    // just to make clear that this page content is not rendered by react
    const legacyPageContent = null
    handleShowOnlyProductsContent()
    this.hideReactNavbar()

    const news = []
    return (
      <>
        {legacyPageContent}
        <NewsBanner newsToDisplay={news} />
        <ProductsEditEvents />
        <OrderReviewReleaseHeader />
        {process.env.NODE_ENV === 'test' && <div data-testid="legacy-page" />}
      </>
    )
  }
}

LegacyPage.propTypes = {
  isLiteBrand: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  openDynamicModal: PropTypes.func,
  trackPageView: PropTypes.func,
  setIsNewOrderReviewExperience: PropTypes.func,
}

LegacyPage.defaultProps = {
  openDynamicModal: () => {},
  trackPageView: () => {},
  setIsNewOrderReviewExperience: () => {},
}

export default LegacyPage
