import { Icon, SmallGammaBadge } from '@joor/design-system'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { formatNumber } from 'utils/formatters'

import { MESSAGES } from 'routes/paths'

import { useUnreadMessagesCount } from './messageMenuOption.hooks'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { MessagesOptionIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'
import { IMessageMenu } from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

const MessageMenuLink = styled(Link)`
  position: relative;
  border-bottom: none;
  padding: 0 8px;
  margin: 14px 0;
  margin-right: 4px;
  &:hover {
    border: 1px solid var(--color-primary-400);
    border-radius: 4px;
    margin: 13px -1px;
    margin-right: 3px;
  }


  }
`

const QuantityBadge = styled.span`
  position: absolute;
  top: -10px;
  left: 20px;
  z-index: 999;

  @media only screen and (max-width: 1024px) {
    top: -8px;
    left: 8px;
  }
`

const MessagesMenuLabel = styled.span`
  margin-left: 4px;
  color: var(--color-secondary-800);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  line-height: 16px;
  padding: 6px 0;
  margin-right: auto;
`

const MessageMenuOption = ({ isInsideDropdown }: IMessageMenu) => {
  const { unreadMessagesCount } = useUnreadMessagesCount()

  return (
    <MessageMenuLink
      to={MESSAGES}
      id={MessagesOptionIds.MessagesTrigger}
      data-testid={MessagesOptionIds.MessagesTrigger}
      aria-label={messages.messages}
    >
      <Icon
        iconName="chat"
        variant="DEFAULT"
        data-testid={MessagesOptionIds.MessagesChatIcon}
        type="white"
        plain
      />
      {unreadMessagesCount > 0 && (
        <QuantityBadge>
          <SmallGammaBadge value={formatNumber(unreadMessagesCount)} />
        </QuantityBadge>
      )}
      {isInsideDropdown && (
        <MessagesMenuLabel>{messages.messages}</MessagesMenuLabel>
      )}
    </MessageMenuLink>
  )
}

export default MessageMenuOption
