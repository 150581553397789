import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import {
  Paragraph,
  TabPanel,
  TabularMenu,
  TabularMenuProvider,
} from '@joor/design-system'
import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'

import {
  LGConnectionStatus,
  LGProfileCompleteness,
} from '__generated__/atlas-types'

import { fromGlobalId } from 'utils/transformations/graphql'

import { isAccountTypeRetailer } from 'store/currentUser/selectors'

import { RETAILER_VIEW_OWN_PROFILE } from 'routes/paths'

import { RetailerProfile } from '../retailerProfilePage.types'
import { useVisitToRetailerProfile } from './RetailerProfileViewMode.hooks'
import Header from './components/Header/Header'
import RetailerProfileContent from 'features/LeadGen/RetailerProfile/RetailerProfileViewMode/components/RetailerProfileContent/RetailerProfileContent'

export const StyledRetailerName = styled.div`
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-xxlarge);
  font-weight: var(--font-weight-light);
  line-height: 60px;
`

const StyledContainer = styled.div`
  position: absolute;
  top: -25px;
  max-width: 100%;
`
const StyledTabularMenu = styled(TabularMenu)`
  padding-left: 32px;
`
const StyledProfileTabContainer = styled.div`
  padding-top: 32px;
`
const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  min-height: calc(100vh - 243px);
`

const EmptyProfileWarning = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  background: var(--color-warning-100, #fff6e5);
`

export const CUSTOMER_DATA_PARAM = 'customer-data'

type RetailerProfileViewModeProps = {
  setEditMode: Dispatch<SetStateAction<boolean>>
  retailerData: RetailerProfile
  onHeaderActionSuccess: () => void
}

const RetailerProfileViewMode = ({
  setEditMode,
  retailerData: retailer,
  onHeaderActionSuccess,
}: RetailerProfileViewModeProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const { retailerProfileBrandVisibility } = useFlags()
  const isRetailerAccount = useSelector(isAccountTypeRetailer)
  const { recordVisit } = useVisitToRetailerProfile(retailer.id)
  const isProfileEmpty =
    retailer.profileCompleteness === LGProfileCompleteness.EMPTY &&
    !isRetailerAccount

  const isAccountConnected =
    retailer?.connectionStatus === LGConnectionStatus.CONNECTED

  const retailerName = retailer?.displayName
    ? retailer?.displayName
    : retailer?.name
  const retailerId = fromGlobalId(retailer.id)?.id
  const path = retailerId
    ? generatePath(RETAILER_VIEW_OWN_PROFILE, {
        retailerId,
      })
    : ''
  const legacyUrl = `${window.location.origin}${path}?${CUSTOMER_DATA_PARAM}`

  useEffect(() => {
    if (!isRetailerAccount && !isAccountConnected && retailer.id) {
      recordVisit()
    }
  }, [retailer.id, isRetailerAccount, isAccountConnected])

  useEffect(() => {
    if (isAccountConnected) {
      setSelectedTabIndex(1)
    }
  }, [isAccountConnected])

  return (
    <StyledContainer data-testid="retailer-profile__view-mode">
      <>
        {isProfileEmpty && (
          <EmptyProfileWarning>
            <Paragraph type="3">
              This retailer has not filled out their JOOR profile
            </Paragraph>
          </EmptyProfileWarning>
        )}
        <Header
          retailerId={retailer?.id}
          retailerName={retailerName ?? ''}
          logoUrl={retailer?.logo?.url ?? ''}
          handleClick={() => {
            setEditMode(true)
          }}
          connectionStatus={retailer?.connectionStatus}
          onMutationSuccess={onHeaderActionSuccess}
          autoConnect={retailer?.autoConnect}
        />
        {isAccountConnected && retailerProfileBrandVisibility ? (
          <TabularMenuProvider
            tabs={[
              {
                component: (
                  <StyledProfileTabContainer>
                    <RetailerProfileContent
                      retailer={retailer}
                      isRetailerAccount={isRetailerAccount}
                    />
                  </StyledProfileTabContainer>
                ),
                label: 'Profile',
                onClick: () => setSelectedTabIndex(0),
              },
              {
                component: (
                  <StyledIframe
                    title="Customer Specific Data"
                    src={legacyUrl}
                  />
                ),
                label: 'Customer Specific Data',
                onClick: () => setSelectedTabIndex(1),
              },
            ]}
            index={selectedTabIndex}
          >
            <StyledTabularMenu />
            <TabPanel />
          </TabularMenuProvider>
        ) : (
          <RetailerProfileContent
            retailer={retailer}
            isRetailerAccount={isRetailerAccount}
          />
        )}
      </>
    </StyledContainer>
  )
}

export default RetailerProfileViewMode
