import { TextButton } from '@joor/design-system'
import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const NavContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-height: 53px;
`
export const HomeLink = styled(Link)`
  border-bottom: none;
  padding: 13px 12px;
  img {
    width: 80px;
    height: auto;
  }
  &:hover {
    background: inherit;
  }
`
export const RightOptionsContainer = styled.div`
  display: flex;
  gap: 8px;
  padding-right: 16px;
`
export const LeftOptionsContainer = styled.div`
  display: flex;
`
export const MenuOptions = styled.div`
  width: 200%;
  display: flex;
  flex-direction: row;
  border-radius: 4px;

  &.disabled {
    display: none;
  }

  @keyframes slide-left {
    from {
      left: 0;
    }
    to {
      left: -100%;
    }
  }

  &.submenu {
    position: absolute;
    left: -100vw;
    top: 53px;
    transition: 1s;
    animation: slide-left 0.5s forwards;
  }
`
export const PrimaryMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  .items-holder {
    padding-inline: 12px;
    background-color: var(--color-primary-100);
    border-bottom-left-radius: var(--size-border-radius-small);
    border-bottom-right-radius: var(--size-border-radius-small);
    border: 1px solid var(--color-primary-200);
    border-top: none;
    box-shadow: var(--elevation-8);
  }
`
export const SecondaryMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  > div:nth-child(2) {
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
`

export const SubmenuSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 4px;
  background-color: var(--color-primary-100);
  border: 1px solid var(--color-primary-200);
  border-radius: var(--size-border-radius-small);
  box-shadow: var(--elevation-4);
  padding-inline: 12px;
`
export const SubmenuItem = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--color-primary-400);
  padding: 16px 0;

  &:last-child {
    border-bottom: none;
  }
`
export const MenuItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid var(--color-primary-400);
  width: 50%;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &.menu-item {
    width: 100%;
  }
`
export const NavigationLink = styled(NavLink)`
  color: var(--color-secondary-800) !important;
  padding: 0;
  width: 100%;
`
export const BackButton = styled(TextButton)`
  max-height: 28px;
  padding: 8px 12px;
  background-color: var(--color-primary-800);
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  text-decoration: none !important;

  & svg {
    --main-color: #fff;
  }

  &:hover {
    svg {
      --main-color: #fff;
    }
    background-color: var(--color-primary-600);
  }
`
