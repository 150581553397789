import { Icon, Subtitle } from '@joor/design-system'

import { MenuItemContainer, NavigationLink } from './MobileNavbar.ui'

export function MenuItem({
  label,
  path,
  showChevron = false,
  onClick,
}: {
  label: string
  path?: string
  showChevron?: boolean
  onClick?: () => void
}) {
  return (
    <MenuItemContainer onClick={onClick} className="menu-item">
      {!showChevron && path ? (
        <>
          <NavigationLink to={path}>
            <Subtitle type="3">{label}</Subtitle>
          </NavigationLink>
        </>
      ) : (
        <Subtitle type="3">{label}</Subtitle>
      )}
      {showChevron && <Icon iconName="chevron-right" plain />}
    </MenuItemContainer>
  )
}
