import { useSelector } from 'react-redux'

import { formatNameWithCode } from 'utils/formatters'

import {
  getAccountId,
  getAccountLogoURL,
  getAccountProfileName,
} from 'store/currentUser/selectors'

import {
  AccountLabel,
  LogoContainer,
  StyledImagePlaceholder,
  StyledLogo,
  StyledPlaceholder,
} from '../../retailerAccountsDropdown.ui'
import { RecentlyCreatedAccountType } from 'features/Navbar/NavbarLiteRetailer/NavbarLiteRetailer.queries'
import Trigger from 'features/Navbar/NavbarRevamp/components/Trigger/Trigger'
import { RetailAccountsDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

const AccountMenuTrigger = ({
  recentlyCreatedAccounts = [],
}: {
  recentlyCreatedAccounts?: RecentlyCreatedAccountType[]
}) => {
  const accountId = useSelector(getAccountId)
  const accountName = useSelector(getAccountProfileName)
  const logoUrl = useSelector(getAccountLogoURL)

  return (
    <Trigger
      id={RetailAccountsDropdownIds.AccountsTrigger}
      label={
        <>
          {logoUrl ? (
            <LogoContainer data-testid={RetailAccountsDropdownIds.Logo}>
              <StyledLogo src={logoUrl} alt="retailer-logo" />
            </LogoContainer>
          ) : (
            <StyledImagePlaceholder
              data-testid={RetailAccountsDropdownIds.EmptyLogo}
            >
              <StyledPlaceholder iconName="image" iconSize="small" />
            </StyledImagePlaceholder>
          )}
          <AccountLabel>
            {formatNameWithCode(accountName, accountId)}
          </AccountLabel>
        </>
      }
      count={recentlyCreatedAccounts.length}
    />
  )
}

export default AccountMenuTrigger
