import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'

import { isProBrand as isProBrandSelector } from 'store/currentUser/selectors'

import { SUBMISSIONS_CONTACT } from 'routes/paths'

import { BuyerCard } from '../../../components/BuyerCard/BuyerCard'
import type { RetailerProfile } from '../../../retailerProfilePage.types'
import PeopleEmpty from './PeopleEmpty'
import { ViewModeIds } from 'features/LeadGen/RetailerProfile/retailerProfilePage.ids'

export const USER_SETTINGS_URL = '/accounts/settings'

const SectionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
`

export type PeopleProps = {
  people: RetailerProfile['people']
  buyer: RetailerProfile['buyer']
  isRetailer: boolean
  isRetailerConnected?: boolean
  retailerId?: string
  accountSubmission?: RetailerProfile['accountSubmission']
}

const People = ({
  people,
  buyer,
  isRetailer,
  isRetailerConnected,
  retailerId,
  accountSubmission,
}: PeopleProps) => {
  const hasJobTitle = buyer?.isBuyer || buyer?.isOwner
  const jobTitle = [buyer?.isOwner && 'Owner', buyer?.isBuyer && 'Buyer']
    .filter((x) => x)
    .join(' & ')

  const { brandSubmissionsFlag } = useFlags()

  const isEmpty = !buyer?.name && (!people || people?.length === 0)

  const isProBrand = useSelector(isProBrandSelector)

  const canSendSubmission =
    isProBrand && !isRetailerConnected && brandSubmissionsFlag

  const sentAccountSubmission = accountSubmission?.messageId
    ? {
        rep:
          accountSubmission.senderAccount?.accountUserName ||
          accountSubmission.senderAccount?.accountUserName ||
          '',
        date: accountSubmission.createdAt,
        messageId: accountSubmission.messageId,
      }
    : undefined

  if (isEmpty) {
    return (
      <PeopleEmpty
        isRetailer={isRetailer}
        contactUrl={
          canSendSubmission && retailerId
            ? generatePath(SUBMISSIONS_CONTACT, {
                retailerId: retailerId,
              })
            : undefined
        }
        submissionSent={sentAccountSubmission}
      />
    )
  }

  return (
    <SectionContainer
      id={ViewModeIds.PeopleWidget.Container}
      data-testid="people-section"
    >
      {people && people.length > 0 ? (
        people.map(
          (
            { id, name, jobTitle, phone, profilePictureUrl, submissions },
            index,
          ) => {
            const submission = submissions?.[0]

            const contactUrl =
              canSendSubmission &&
              generatePath(SUBMISSIONS_CONTACT, {
                retailerId: retailerId!,
                accountUserId: id || '',
              })

            const submissionSent = submission?.messageId
              ? {
                  rep:
                    submission.senderAccount?.accountUserName ||
                    submission.senderAccount?.accountName ||
                    '',
                  date: submission.createdAt,
                  messageId: submission.messageId,
                }
              : undefined

            return (
              <BuyerCard
                key={index}
                name={name || ''}
                jobTitle={jobTitle}
                phone={phone}
                profileImageUrl={profilePictureUrl}
                contactUrl={contactUrl}
                submissionSent={submissionSent}
                isRetailer={isRetailer}
              />
            )
          },
        )
      ) : (
        <BuyerCard
          name={buyer?.name || ''}
          jobTitle={hasJobTitle ? jobTitle : ''}
          contactUrl={
            canSendSubmission
              ? generatePath(SUBMISSIONS_CONTACT, {
                  retailerId: retailerId || '',
                })
              : undefined
          }
          submissionSent={sentAccountSubmission}
          isRetailer={isRetailer}
        />
      )}
    </SectionContainer>
  )
}

export default People
