import { createAction } from 'redux-actions'

export const toggleOrder = createAction('ORDER/TOGGLE_ORDER')
export const toggleSelectOrdersOnPage = createAction(
  'ORDER/TOGGLE_SELECT_ORDERS_ON_PAGE',
)
export const resetSelectedOrders = createAction('ORDER/RESET_SELECTED_ORDERS')
export const sortOrders = createAction('ORDER/SORT_ORDERS')
export const changePageSize = createAction('ORDER/PAGE_SIZE')
export const toggleMergeOrderModal = createAction(
  'ORDER/TOGGLE_MERGE_ORDER_MODAL',
)
export const setMergeOrderMessages = createAction(
  'ORDER/SET_MERGE_ORDER_MESSAGES',
)
export const toggleCheckMergeOrders = createAction(
  'ORDER/TOGGLE_CHECK_MERGE_ORDERS',
)
export const toggleDisplayDivisionColumn = createAction(
  'ORDER/TOGGLE_DISPLAY_DIVISION_COLUMN',
)
