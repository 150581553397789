import { useState } from 'react'

import { Modal, ModalProvider, RadioButton } from '@joor/design-system'
import styled from 'styled-components/macro'

import { useStorageLanguages } from 'utils/sdks/localize'
import { switchLanguage as switchLanguageZendesk } from 'utils/sdks/zendesk'

import usePortal from 'hooks/usePortal'

import { useUpdateSelectedLanguage } from './languageModal.mutations'
import { flagsMap } from 'features/Navbar/NavbarRevamp/components/LocalizeDropdown/utils'
import { LanguagesDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

type ModalProps = {
  isOpen: boolean
  onClose?: () => void
}

const FlagImg = styled.img`
  border-radius: 50%;
`

const LanguageGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos salten a la siguiente fila */
  gap: 10px;
`
const LanguageElement = styled.div`
  flex: 0 0 48%;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  align-items: center;

  > img {
    margin: 0px 8px;
    border: 0.5px solid var(--color-primary-200);
  }

  font-family: var(--font-family-alpha);
  font-size: var(--size-font-small);
  font-weight: vat(--font-weight-medium);
  line-height: 16px;
  color: var(--color-secondary-800);
  cursor: pointer !important;
`

const LanguageModal = ({ isOpen, onClose = () => {} }: ModalProps) => {
  const Portal = usePortal()
  const {
    language: storedLanguage,
    languages,
    switchStorageLanguage,
  } = useStorageLanguages()
  const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage)
  const { updateSelectedLanguage } = useUpdateSelectedLanguage()

  const handleOnClickLanguage = () => {
    switchStorageLanguage(selectedLanguage.code)
    switchLanguageZendesk(selectedLanguage.code)
    updateSelectedLanguage(selectedLanguage.code)
    onClose()
  }

  return (
    <Portal>
      <ModalProvider>
        <Modal
          isOpen={isOpen}
          title={{ text: 'Language' }}
          onClose={onClose}
          size="medium"
        >
          <Modal.Content>
            <LanguageGridContainer>
              {languages.map((language) => (
                <LanguageElement
                  key={language.code}
                  className="notranslate"
                  onClick={() => setSelectedLanguage(language)}
                >
                  <RadioButton
                    data-testid={`${LanguagesDropdownIds.LanguagesOptions}__${language.code}`}
                    id={`${LanguagesDropdownIds.LanguagesOptions}__${language.code}`}
                    checked={selectedLanguage.code === language.code}
                    readOnly
                  />
                  <FlagImg
                    width="24px"
                    height="24px"
                    src={flagsMap[language.code]}
                    alt={language.code}
                  />
                  {language.name}
                </LanguageElement>
              ))}
            </LanguageGridContainer>
          </Modal.Content>
          <Modal.Actions
            primaryButton={{
              text: 'Change',
              handler: handleOnClickLanguage,
            }}
          />
        </Modal>
      </ModalProvider>
    </Portal>
  )
}

export default LanguageModal
