import styled from 'styled-components'

import { Language, useStorageLanguages } from 'utils/sdks/localize'
import { switchLanguage as switchLanguageZendesk } from 'utils/sdks/zendesk'

import DropdownMenuGroup from '../DropdownMenuGroup/DropdownMenuGroup'
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown'
import Trigger from '../Trigger/Trigger'
import { useUpdateSelectedLanguage } from './localizeDropdown.hooks'
import { flagsMap } from './utils'
import { LanguagesDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'
import { ILocalizeDropdown } from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

const LocalizeDropdownContainer = styled.div<ILocalizeDropdown>`
  display: flex;

  .trigger > div {
    padding: 0 8px;
  }

  @media only screen and (max-width: 1024px) {
    display: ${({ isInsideDropdown }) => (isInsideDropdown ? 'flex' : 'none')};

    .trigger > div {
      padding: 4px 1px;
    }
  }
`

const LanguageContainer = styled.div`
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-alpha);
  letter-spacing: 0.6px;
  line-height: 16px;
  width: 100%;

  &:not(:first-child) {
    padding-top: 16px;
    border-top: 1px solid var(--color-primary-400);
  }
`

const LanguageAction = styled.button`
  border: none;
  text-align: left;
  padding: 0;
  cursor: pointer;
  text-decoration-line: none;
  margin: auto 0;
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 16px;
  color: var(--color-secondary-800);
  padding: 2px;
  width: 100%;
  height: 32px;
  padding: 4px 8px;

  &:hover {
    border: 1px solid var(--color-primary-400);
    border-radius: 4px;
    margin: 0 -1px;
  }
`

const TriggerFlag = styled.div<ILocalizeDropdown>`
  width: 24px;
  height: 24px;
  margin-right: ${({ isInsideDropdown }) => (isInsideDropdown ? '4px' : '0')};
  padding: 2px;

  > svg {
    width: 20px;
    height: 20px;
  }

  @media only screen and (max-width: 1024px) {
    width: 20px;
    height: 20px;
    padding: 0;
  }
`

const LocalizeMenuLabel = styled.span`
  margin-left: 4px;
  color: var(--color-secondary-800);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  line-height: 16px;
  margin-right: auto;
`

const FlagImg = styled.img`
  border-radius: 50%;
`

interface ICustomLocalizeTrigger {
  selectedLanguage: string
  languages: Language[]
  isInsideDropdown?: boolean
}

const CustomLocalizeTrigger = ({
  selectedLanguage,
  languages,
  isInsideDropdown,
}: ICustomLocalizeTrigger) => {
  let localizeLabel = null

  if (isInsideDropdown) {
    const selectedLanguageName = languages.find(
      (language) => language.code === selectedLanguage,
    )?.name

    localizeLabel = (
      <LocalizeMenuLabel>{selectedLanguageName}</LocalizeMenuLabel>
    )
  }

  return (
    <Trigger
      isInsideDropdown={isInsideDropdown}
      label={
        <>
          <TriggerFlag isInsideDropdown={isInsideDropdown}>
            <FlagImg
              width="20px"
              height="20px"
              src={flagsMap[selectedLanguage]}
              alt={selectedLanguage}
            />
          </TriggerFlag>
          {localizeLabel}
        </>
      }
      id={LanguagesDropdownIds.LanguagesTrigger}
    />
  )
}

const LocalizeDropdown = ({ isInsideDropdown }: ILocalizeDropdown) => {
  const { language, languages, switchStorageLanguage } = useStorageLanguages()
  const { updateSelectedLanguage } = useUpdateSelectedLanguage()

  const handleOnClickLanguage = (code: string) => {
    switchStorageLanguage(code)
    switchLanguageZendesk(code)
    updateSelectedLanguage(code)
  }

  return (
    <LocalizeDropdownContainer isInsideDropdown={isInsideDropdown}>
      <NavbarDropdown
        trigger={
          <CustomLocalizeTrigger
            selectedLanguage={language.code}
            isInsideDropdown={isInsideDropdown}
            languages={languages}
          />
        }
        rightPosition
        isInsideDropdown={isInsideDropdown}
      >
        <DropdownMenuGroup>
          {languages.map((language) => (
            <LanguageContainer
              key={language.code}
              id={`${LanguagesDropdownIds.LanguagesOptions}__${language.code}`}
              data-testid={`${LanguagesDropdownIds.LanguagesOptions}__${language.code}`}
              onClick={() => handleOnClickLanguage(language.code)}
              className="notranslate"
            >
              <LanguageAction>
                <FlagImg
                  width="24px"
                  height="24px"
                  src={flagsMap[language.code]}
                  alt={language.code}
                />
                {language.name}
              </LanguageAction>
            </LanguageContainer>
          ))}
        </DropdownMenuGroup>
      </NavbarDropdown>
    </LocalizeDropdownContainer>
  )
}

export default LocalizeDropdown
