import { useState } from 'react'

import { SecondaryButton, Switch, Tooltip } from '@joor/design-system'
import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { toGlobalId } from 'utils/transformations/graphql'

import { useRenderToasters } from 'hooks/useRenderToasters'
import { getUserId } from 'store/currentUser/selectors'

import messages from 'features/EarlyAccessWidget/constants/messages'
import {
  useSubscribeUserToFeature,
  useUnSubscribeUserToFeature,
} from 'features/EarlyAccessWidget/earlyAccessWidget.hooks'
import { FeatureIds } from 'features/EarlyAccessWidget/earlyAccessWidget.ids'
import { Feature } from 'features/EarlyAccessWidget/earlyAccessWidget.types'
import {
  getErrorDisableToastContent,
  getErrorEnableToastContent,
  getGuideContainer,
  getPendoGuide,
  getSuccessDisableToastContent,
  getSuccessEnableToastContent,
  onRemovePendoGuide,
  showSubscribeErrorToast,
  showUnsubscribeErrorToast,
} from 'features/EarlyAccessWidget/utils/featureList.utils'

const FeatureOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0 16px 8px;

  &:first-child {
    border-top: 1px solid var(--color-primary-400);
  }

  &:not(:first-child) {
    padding-top: 16px;
  }

  &:last-child {
    padding-bottom: 8px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-primary-400);
    padding-bottom: 16px;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled.h4`
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-secondary-800);

  &&& {
    font-family: var(--font-family-alpha);
    font-style: normal;
    font-weight: var(--font-weight-medium);
    font-size: var(--size-font-base);
    line-height: var(--line-height-16);
    letter-spacing: var(--letter-spacing-normal);
    margin: 0;
    padding: 0;
  }
`

const Description = styled.div`
  color: var(--color-primary-300);
  font-family: var(--font-family-alpha);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-small);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-tighter);
`

const FeatureLink = styled(Link)`
  line-height: var(--line-height-16);
  color: var(--color-secondary-800) !important;
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-small);
  letter-spacing: var(--letter-spacing-tighter);
  font-weight: var(--font-weight-semibold);
  font-style: normal;
  margin: 0;
`

const DisableLink = styled.p`
  color: var(--color-primary-200);
  font-family: var(--font-family-alpha);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  font-size: var(--size-font-small);
  line-height: var(--line-height-16) !important;
  letter-spacing: var(--letter-spacing-tighter);
  margin: 0 !important;
`

const WalkthroughLink = styled.p<{ disabled: boolean }>`
  color: ${(props) =>
    props.disabled ? 'var(--color-primary-200)' : 'var(--color-secondary-800)'};
  cursor: ${(props) => (props.disabled ? 'unset' : 'pointer')};
  font-family: var(--font-family-alpha);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  font-size: var(--size-font-small);
  line-height: var(--line-height-16) !important;
  letter-spacing: var(--letter-spacing-tighter);
  margin: 0 !important;
`

const FeedbackButton = styled(SecondaryButton)<{ disabled: boolean }>`
  width: fit-content;
  align-self: center;
  margin-bottom: 8px;

  &&& {
    color: ${(props) => (props.disabled ? '' : 'var(--color-secondary-800)')};
    border-color: ${(props) =>
      props.disabled ? '' : 'var(--color-secondary-800)'};
  }
`

const PreviewImage = styled.img`
  width: 200px;
  height: 132px;
`

const FeatureOption = ({
  feature,
  featureFlagEnabled,
}: {
  feature: Feature
  featureFlagEnabled: boolean
}) => {
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(featureFlagEnabled)
  const history = useHistory()
  const userId = useSelector<object, string>(getUserId)
  const flags = useFlags()
  const { renderErrorToast, renderSuccessToast } = useRenderToasters()
  const successEnableToastContent = getSuccessEnableToastContent(history.push)
  const successDisableToastContent = getSuccessDisableToastContent(history.go)
  const errorEnableToastContent = getErrorEnableToastContent(history.go)
  const errorDisableToastContent = getErrorDisableToastContent(history.go)

  const handleUnsubscribeAndPendo = () => {
    const guideData = getPendoGuide(feature.unsubscribePendoGuideId)
    guideData?.show()
    const oldFeatureLink = feature.oldFeatureLink && feature.oldFeatureLink()
    const guideContainer = getGuideContainer(guideData)
    if (guideContainer) {
      // If we have a Pendo sentiment guide that needs to be completed, we delay the
      // navigation and showing the toast until that guide is removed
      onRemovePendoGuide(guideContainer, window.location.pathname, () => {
        renderSuccessToast(successDisableToastContent)
        if (oldFeatureLink) {
          window.location.href = oldFeatureLink
        }
      })
    } else {
      renderSuccessToast(successDisableToastContent)
      if (oldFeatureLink) {
        window.location.href = oldFeatureLink
      }
    }
  }

  const { subscribeUserToFeature } = useSubscribeUserToFeature({
    onError: ({ graphQLErrors }) =>
      showSubscribeErrorToast(graphQLErrors, history.go, renderErrorToast),
    onCompleted: ({ subscribeUserToFeature: { success } }) => {
      if (!success) {
        setIsFeatureEnabled(false)
        renderErrorToast(errorEnableToastContent)
      } else {
        setIsFeatureEnabled(true)
        renderSuccessToast(successEnableToastContent)
        const newFeatureLink =
          feature.newFeatureLink && feature.newFeatureLink()
        if (newFeatureLink) {
          window.location.href = newFeatureLink
        }
      }
    },
    errorPolicy: 'none',
  })

  const { unSubscribeUserToFeature } = useUnSubscribeUserToFeature({
    onError: ({ graphQLErrors }) =>
      showUnsubscribeErrorToast(graphQLErrors, history.go, renderErrorToast),
    onCompleted: ({ unSubscribeUserToFeature: { success } }) => {
      if (!success) {
        setIsFeatureEnabled(true)
        renderErrorToast(errorDisableToastContent)
      } else {
        setIsFeatureEnabled(false)
        handleUnsubscribeAndPendo()
      }
    },
    errorPolicy: 'none',
  })

  const handleSwitchChange = () => {
    const userGlobalId = toGlobalId('User', userId) as string
    if (isFeatureEnabled) {
      unSubscribeUserToFeature({
        featureName: feature.earlyAccessFeatureFlag,
        userId: userGlobalId,
      })
    } else {
      subscribeUserToFeature({
        featureName: feature.earlyAccessFeatureFlag,
        userId: userGlobalId,
      })
    }
  }

  return (
    <FeatureOptionContainer>
      <TitleContainer>
        {feature.previewImage ? (
          <Tooltip
            defaultPlacement="left"
            content={
              <PreviewImage src={feature.previewImage} alt="feature-preview" />
            }
          >
            <Title data-testid={FeatureIds.Title} id={FeatureIds.Title}>
              {feature.title(flags[feature.conditionallyFeatureFlag || ''])}
            </Title>
          </Tooltip>
        ) : (
          <Title data-testid={FeatureIds.Title} id={FeatureIds.Title}>
            {feature.title(flags[feature.conditionallyFeatureFlag || ''])}
          </Title>
        )}
        <Switch
          id={feature.earlyAccessFeatureFlag}
          testId={feature.earlyAccessFeatureFlag}
          checked={isFeatureEnabled}
          onChange={handleSwitchChange}
        />
      </TitleContainer>
      <Description
        data-testid={FeatureIds.Description}
        dangerouslySetInnerHTML={{
          __html: feature.description(
            flags[feature.conditionallyFeatureFlag || ''],
          ),
        }}
      />
      {feature.customLink &&
        (isFeatureEnabled ? (
          <DisableLink data-testid={FeatureIds.Link}>
            {feature.linkText}
          </DisableLink>
        ) : (
          <FeatureLink
            data-testid={FeatureIds.Link}
            id={FeatureIds.Link}
            to={feature.customLink}
            target="_blank"
          >
            {feature.linkText}
          </FeatureLink>
        ))}
      {feature.walkthroughText && (
        <WalkthroughLink
          disabled={!isFeatureEnabled}
          data-testid={feature.walkthroughId}
          {...(isFeatureEnabled ? { id: feature.walkthroughId } : {})}
        >
          {feature.walkthroughText}
        </WalkthroughLink>
      )}

      <FeedbackButton
        id={feature.feedbackButtonId || FeatureIds.Feedback}
        data-testid={feature.feedbackButtonId || FeatureIds.Feedback}
        disabled={!isFeatureEnabled}
      >
        {messages.feedback}
      </FeedbackButton>
    </FeatureOptionContainer>
  )
}

export default FeatureOption
