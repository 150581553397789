import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import qs from 'qs'

import { AgGridCollection } from 'types'
import { sliceArray } from 'utils/transformations/array'
import { fromGlobalId } from 'utils/transformations/graphql'

import {
  BasicPayloadType,
  isOrderExcelDataType,
  isPdfDataType,
} from './requests.types'
import {
  createCsvExcelPayload,
  createOrderExcelPayload,
  createPdfPayload,
  createURLForPDF,
} from './requests.utils'
import { auth } from './sdks/authKeycloak'
import {
  CsvDataType,
  ExcelDataType,
  FileFormats,
  OrderExcelDataType,
  PdfDataType,
} from 'features/Linesheets/Modals/ExportModal/exportModal.types'

export const MAX_ORDERS_PER_EXPORT = 50
export const STATUS_CODE_SUCCESS = 200

export const ERROR_INVALID_HEADER = 'Invalid header'
export const ERROR_INVALID_RESPONSE = 'Invalid response'
export const ERROR_INVALID_STATUS = 'Invalid status'
export const ERROR_NOT_FOUND = 'Not found'

export const FORMAT_PDF = 'pdfPrintOption'
export const FORMAT_EXCEL = 'excelPOPrintOption'
export const FORMAT_EXPORT_ORDER_OR_LINESHEET = 'orderOrLinesheetOption'
export const FORMAT_XLS_LINESHEET = 'xlsLinesheetPrintOption'
export const FORMAT_INVOICE_PDF = 'invoicePdfOption'

export const makeRequest = (
  requestConfig: AxiosRequestConfig,
): AxiosPromise<any> =>
  axios({
    ...requestConfig,
    headers: {
      ...requestConfig.headers,
      Authorization: auth.getToken() ? auth.getToken() : '',
    },
  })

export const forceFileDownload = (data: any, filename: string): string => {
  const link = document.createElement('a')
  const linkUrl = window.URL.createObjectURL(new window.Blob([data]))

  link.href = linkUrl
  link.setAttribute('download', filename)
  link.dispatchEvent(
    new window.MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  )
  window.URL.revokeObjectURL(linkUrl)
  return linkUrl
}

export const downloadFileFromResponse = (
  url: string,
  data: any,
): Promise<string> =>
  makeRequest({ method: 'post', url, data, responseType: 'blob' }).then(
    (res) => {
      if (res.status !== STATUS_CODE_SUCCESS) {
        throw new Error(ERROR_INVALID_STATUS)
      }
      if (!res.data.size) {
        throw new Error(ERROR_INVALID_RESPONSE)
      }
      const contentDisposition = res.headers['content-disposition']
      const filenameMatch = contentDisposition.match(/filename="(.+)"/)
      if (!filenameMatch) {
        throw new Error(ERROR_INVALID_HEADER)
      }
      return forceFileDownload(res.data, filenameMatch[1])
    },
  )

export const getRequestDataForExportingOrders = (
  selectedOrdersIds = [],
  format: string,
  options: any,
): string => {
  const ordersToExport = selectedOrdersIds.reduce(
    (acc, globalId) => {
      const { id: orderId } = fromGlobalId(globalId)
      // TODO `orderId` parameter can be null. Add verification somehow.
      // @ts-ignore
      acc.data.Download[orderId] = 'on'
      return acc
    },
    { data: { Download: {} } },
  )
  const data = {
    ...options,
    ...ordersToExport,
    selected_tab: format,
    from_react: true,
  }

  return qs.stringify(data)
}

export const exportOrders = (
  selectedOrderIds: string[] = [],
  format: string,
  options: any,
): Promise<any> => {
  const selectedOrdersSlices = sliceArray(
    selectedOrderIds,
    MAX_ORDERS_PER_EXPORT,
  )

  const requests = selectedOrdersSlices.map((selectedOrdersSlice) => {
    const data = getRequestDataForExportingOrders(
      selectedOrdersSlice,
      format,
      options,
    )
    // TODO(ch15457) rebuild endpoint
    return downloadFileFromResponse('/orders/download_orders/', data)
  })

  return Promise.all(requests)
}

/**
 * This functions export the selected collections into CSV/Excel/PDF
 * files.
 *
 * ! Logic to create the URL to download one collection in PDF.
 *
 * 5             /1     /0      /1                 /1                    /3            /0            /0              /1       ?downloadToken=1631272306723
 * number        /number/boolean/boolean           /boolean              /number       /boolean      /boolean        /boolean ?number
 * linesheet_ids?/style /false  /print_retail_price/print_wholesale_price/price_type_id/oversold_type/overflow_colors/download?timestamp
 *                      |>html? or pdf                                                                                |> If we want to download the file. Always true.
 *
 * If there are multiple collections selected, the `linesheet_ids` disappears from the
 * URL and is passed into the payload as a `string[]`.
 */

export const exportCollections = async (
  fileFormat: FileFormats,
  selectedRows: AgGridCollection[],
  data: CsvDataType | ExcelDataType | PdfDataType | OrderExcelDataType,
): Promise<void> => {
  const basicPayload: BasicPayloadType = {
    downloadToken: new Date().getTime(),
    file: fileFormat,
    for_order_import: 0,
  }
  if (isPdfDataType(data)) {
    basicPayload.Collection = selectedRows?.map((row) => ({
      id: fromGlobalId(row.collection_id).id,
    }))

    const payload = createPdfPayload(basicPayload, data)
    if (selectedRows.length > 1) {
      await downloadFileFromResponse(
        `/collections/print_linesheets/${createURLForPDF(payload)}`,
        payload,
      )
    } else {
      await downloadFileFromResponse(
        `/collections/print_styles/${createURLForPDF(payload)}`,
        payload,
      )
    }
  } else if (isOrderExcelDataType(data)) {
    basicPayload.file = FileFormats.Excel
    basicPayload.for_order_import = 1
    const payload = createOrderExcelPayload(basicPayload, data)
    await downloadFileFromResponse('/collections/export/', payload)
  } else {
    basicPayload.linesheet_ids = selectedRows?.map(
      (row) => fromGlobalId(row.collection_id).id,
    )
    const payload = createCsvExcelPayload(basicPayload, data)
    await downloadFileFromResponse('/collections/export/', payload)
  }
}

/**
 * This is a helper function to guarantee we only call a specific function
 * by reference once every x seconds. This is useful in cases a function
 * is called twice by an useEffect incorrectly triggered or something alike.
 * We introduced it as a guard for mutations we call on mount in case a component
 * mounts more than once for whatever reason.
 */
const functionRegistry = new WeakMap<Function, { lastCalled: number }>()
export const callGuard = (
  func: (...args: any[]) => any,
  timeout: number = 5000,
) => {
  return (...args: any[]) => {
    const now = Date.now()
    const funcData = functionRegistry.get(func)

    if (!funcData || now - funcData.lastCalled >= timeout) {
      functionRegistry.set(func, { lastCalled: now })
      return func(...args)
    }

    return undefined
  }
}
