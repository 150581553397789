import { useEffect, useState } from 'react'

import { NotificationProvider } from '@joor/design-system'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import usePHPMixpanelTrackers from 'utils/phpTracking/usePHPMixpanelTrackers'
import { setLocationForDatadogRUM } from 'utils/sdks/datadog'
import { useHotjar } from 'utils/sdks/hotjar'
import { useZendesk } from 'utils/sdks/zendesk'
import { CurrentUserInfo } from 'utils/userInfo'

import { setCurrentUser } from 'store/currentUser/actions'
import { loadUserState } from 'store/utils/storage/actions'

import SessionExpiredModal from 'components/Error/SessionExpiredModal/SessionExpiredModal'
import PageContent from 'pages/PageContent'
import PageNavbar from 'pages/PageNavbar'

import Notifications from './containers/Notifications/Notificacions.container'
import { useLocalize } from './utils/sdks/localize'
import { usePendo } from './utils/sdks/pendo'

function BaseApp({ userInfo }: { userInfo: CurrentUserInfo }) {
  const dispatch = useDispatch()
  const location = useLocation()

  const [
    sessionExpiredModalVisible,
    setSessionExpireModalVisibility,
  ] = useState(false)

  useHotjar(userInfo)
  setLocationForDatadogRUM(location)
  usePHPMixpanelTrackers()
  usePendo(userInfo)
  useZendesk(userInfo)
  useLocalize(userInfo)
  useEffect(() => {
    dispatch(setCurrentUser(userInfo))
    dispatch(loadUserState(userInfo))
  }, [userInfo])

  useEffect(() => {
    const handler = () => {
      if (!sessionExpiredModalVisible && userInfo.isLoggedIn) {
        setSessionExpireModalVisibility(true)
      }
    }
    window.addEventListener('openSessionModal', handler)
    return () => window.removeEventListener('openSessionModal', handler)
  }, [sessionExpiredModalVisible, userInfo])

  return (
    <NotificationProvider>
      <Notifications />
      <PageNavbar />
      {sessionExpiredModalVisible && (
        <SessionExpiredModal setVisibility={setSessionExpireModalVisibility} />
      )}
      <PageContent />
    </NotificationProvider>
  )
}

export default BaseApp
