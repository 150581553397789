import classNames from 'classnames'
import PropTypes from 'prop-types'

import Cell from 'components/Shop/Table/Cell'
import Row from 'components/Shop/Table/Row'

import styles from './Table.module.css'

const Table = ({
  children,
  className,
  headerSticky,
  firstColSticky,
  tableRef,
}) => (
  <div
    className={classNames(className, styles.Table, {
      [styles.headerSticky]: headerSticky,
      [styles.firstColSticky]: firstColSticky,
    })}
  >
    <table ref={tableRef}>{children}</table>
  </div>
)

Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  firstColSticky: PropTypes.bool,
  tableRef: PropTypes.func,
}

Table.defaultProps = {
  className: '',
  children: null,
  firstColSticky: false,
  tableRef: () => {},
}

Table.Row = Row
Table.Cell = Cell

export default Table
