import { ReactElement, useEffect, useState } from 'react'

import { Subtitle } from '@joor/design-system'
import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import classNames from 'classnames'
import compose from 'lodash/flowRight'
import { useLocation } from 'react-router-dom'

import { ReactComponent as JOORLogo } from 'assets/images/logo_joor.svg'

import StartOrderModal from 'modals/OrderList/StartOrderModal'
import {
  CURRENT_CONNECTIONS,
  DISCOVER_BRANDS,
  FIND_NEW_BRANDS,
  ORDER_LIST,
  PASSPORT_FAVORITES,
  PASSPORT_LANDING,
  PENDING_CONNECTIONS,
  SHOP_BY_LINESHEET,
  SHOWROOM_COLLECTION_LIST,
  SHOWROOM_STYLEBOARD_ADD,
  SHOWROOM_STYLEBOARD_LIST,
} from 'routes/paths'

import { RecentlyCreatedAccountType } from '../../NavbarLiteRetailer.queries'
import { ConnectionsRequestsLink } from '../ConnectionRequestsLinkd/ConnectionsRequestsLink'
import { AccountsOptions } from '../RetailerAccountsDropdown/RetailerAccountsDropdown'
import { SubmissionsLink } from '../SubmissionsLink/SubmissionsLink'
import { Hamburger } from './Hamburger/Hamburger'
import { MenuItem } from './MenuItem'
import {
  BackButton,
  HomeLink,
  LeftOptionsContainer,
  MenuContainer,
  MenuOptions,
  NavContainer,
  PrimaryMenuContainer,
  RightOptionsContainer,
  SecondaryMenuContainer,
  SubmenuItem,
  SubmenuSection,
} from './MobileNavbar.ui'
import withModal from 'containers/ModalHandler'
import NavbarCartDropdown from 'features/Navbar/NavbarRevamp/components/CartDropdown/CartDropdown'
import DropdownMenuText from 'features/Navbar/NavbarRevamp/components/DropdownMenuTextLink/DropdownMenuText'
import DropdownMenuTextLink from 'features/Navbar/NavbarRevamp/components/DropdownMenuTextLink/DropdownMenuTextLink'
import MessageMenuOption from 'features/Navbar/NavbarRevamp/components/MessageMenuOption/MessageMenuOption'
import ProLiteIndicator from 'features/Navbar/NavbarRevamp/components/ProLiteIndicator/ProLiteIndicator'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { HomeIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

type MobileNavbarProps = {
  homeLink: string
  isProAccount: boolean
  openDynamicModal: (props: any) => void
  recentlyCreatedAccounts?: RecentlyCreatedAccountType[]
}

type NavbarOption = {
  label?: string
  path?: string
  onClick?: () => void
  component?: ReactElement
}

type Section = NavbarOption[]

type NavigationMenu = {
  root: {
    label: string
    path?: string
    sections?: Section[]
  }[]
}

const MobileNavbar = ({
  homeLink,
  isProAccount,
  openDynamicModal,
  recentlyCreatedAccounts,
}: MobileNavbarProps) => {
  const location = useLocation()
  const { submissionsList, wayfindingFindNewBrands } = useFlags()
  const [isNavbarActive, setIsNavbarActive] = useState(false)
  const [visibleSections, setVisibleSections] = useState<Section[]>([])
  const [primaryMenu, setPrimaryMenu] = useState('')

  function toggleMenu() {
    setIsNavbarActive((prev) => !prev)
    setVisibleSections([])
    setPrimaryMenu('')
  }

  function toggleSubMenu(sections: Section[], parentMenu: string) {
    setVisibleSections(sections)
    setPrimaryMenu(parentMenu)
  }

  useEffect(() => {
    if (isNavbarActive) {
      toggleMenu()
    }
  }, [location.pathname])

  const navigationMenu: NavigationMenu = {
    root: [
      {
        label: messages.shop,
        sections: [
          [
            {
              label: messages.startAnOrder,
              onClick: () =>
                openDynamicModal({
                  component: StartOrderModal,
                }),
            },
          ],
          [
            {
              label: messages.looks,
              path: SHOWROOM_COLLECTION_LIST,
            },
            {
              label: messages.styleboards,
              path: SHOWROOM_STYLEBOARD_LIST,
            },
            {
              label: messages.createStyleboard,
              path: SHOWROOM_STYLEBOARD_ADD,
            },
          ],
          [
            {
              label: messages.linesheets,
              path: SHOP_BY_LINESHEET,
            },
          ],
        ],
      },
      {
        label: messages.manageOrders,
        path: ORDER_LIST,
      },
      {
        label: messages.exploreBrands,
        sections: [
          [
            {
              label: messages.connectedBrands,
              path: CURRENT_CONNECTIONS,
            },
            {
              label: messages.interestedInYou,
              component: <SubmissionsLink />,
            },
            {
              label: messages.connectionsRequestsReceived,
              component: <ConnectionsRequestsLink />,
            },
            {
              label: messages.connectionsRequestsSent,
              path: PENDING_CONNECTIONS,
            },
          ],
          [
            {
              label: messages.favorites,
              path: PASSPORT_FAVORITES,
            },
            {
              label: messages.findNewBrands,
              path: wayfindingFindNewBrands ? FIND_NEW_BRANDS : DISCOVER_BRANDS,
            },
            {
              label: messages.passport,
              path: PASSPORT_LANDING,
            },
          ],
        ],
      },
      {
        label: messages.accountCenter,
        sections: [
          [
            {
              component: (
                <AccountsOptions
                  className="mobile-full-width"
                  recentlyCreatedAccounts={recentlyCreatedAccounts}
                />
              ),
            },
          ],
        ],
      },
    ],
  }

  return (
    <MenuContainer>
      <NavContainer>
        <LeftOptionsContainer>
          <ProLiteIndicator isProAccount={isProAccount} />
          <HomeLink to={homeLink} data-testid={HomeIds.HomeLinkTrigger}>
            <JOORLogo />
          </HomeLink>
        </LeftOptionsContainer>
        <RightOptionsContainer>
          <MessageMenuOption />
          <NavbarCartDropdown />
          <Hamburger
            onClick={toggleMenu}
            className={isNavbarActive ? 'open' : ''}
          />
        </RightOptionsContainer>
      </NavContainer>
      <MenuOptions
        className={classNames({
          disabled: !isNavbarActive,
          submenu: visibleSections.length > 0,
        })}
      >
        <PrimaryMenuContainer>
          <div className="items-holder">
            {navigationMenu.root.map(({ label, path, sections }) => (
              <MenuItem
                key={label}
                label={label}
                path={path}
                showChevron={sections && sections.length > 0}
                onClick={() => {
                  if (sections && sections.length > 0) {
                    toggleSubMenu(sections, label)
                  } else {
                    toggleMenu()
                  }
                }}
              />
            ))}
          </div>
        </PrimaryMenuContainer>
        <SecondaryMenuContainer>
          <BackButton
            iconName="chevron-left"
            iconSize="small"
            type="button"
            onClick={() => toggleSubMenu([], '')}
          >
            <Subtitle type="3" color="primary-100">
              {primaryMenu}
            </Subtitle>
          </BackButton>
          {visibleSections.length > 0 &&
            visibleSections.map((section, index) => (
              <SubmenuSection key={`${primaryMenu}-${index}`}>
                {section.map(({ label, path, component, onClick }, index) => {
                  //TODO: Remove this when the flag is removed - submissionsList
                  if (label === messages.interestedInYou && !submissionsList) {
                    return null
                  }

                  if (component) {
                    return (
                      <SubmenuItem key={`submenu-item-component-${index}`}>
                        {component}
                      </SubmenuItem>
                    )
                  }

                  if (!path) {
                    return (
                      <SubmenuItem key={`submenu-item-${label}`}>
                        <DropdownMenuText
                          message={label ?? ''}
                          onClick={onClick}
                        />
                      </SubmenuItem>
                    )
                  }

                  return (
                    <SubmenuItem key={`submenu-item-${label || path}`}>
                      <DropdownMenuTextLink to={path} message={label ?? ''} />
                    </SubmenuItem>
                  )
                })}
              </SubmenuSection>
            ))}
        </SecondaryMenuContainer>
      </MenuOptions>
    </MenuContainer>
  )
}

export default compose(withModal)(MobileNavbar)
