import { ComponentProps } from 'react'

import { Text } from '@joor/design-system'
import styled from 'styled-components'

interface StepSectionProps {
  children?: React.ReactNode
  header: string | JSX.Element
  sectionNumber: string
}

type TextProps = ComponentProps<typeof Text>
const textProps: Pick<
  TextProps,
  'fontSize' | 'fontWeight' | 'letterSpacing'
> = {
  fontSize: 'small',
  fontWeight: 'medium',
  letterSpacing: 'tighter',
}

const StepSection = ({
  children,
  header = '',
  sectionNumber,
}: StepSectionProps) => (
  <StepSectionContainer>
    <SectionHeader>
      <Text {...textProps}>{sectionNumber}</Text>
      {typeof header === 'string' ? (
        <Text {...textProps}>{header}</Text>
      ) : (
        header
      )}
    </SectionHeader>
    <SectionContent>{children}</SectionContent>
  </StepSectionContainer>
)

const StepSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;

  &:not(:first-child) {
    margin-top: 8px;
  }
`

const SectionHeader = styled.div`
  display: flex;
  gap: 4px;
`

const SectionContent = styled.div`
  padding-left: 16px;
`

export default StepSection
