import { GammaBadge, SecondaryPill } from '@joor/design-system'
import styled from 'styled-components'

import { IDropdownMenuText } from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

const MenuTextContainer = styled.div`
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-alpha);
  letter-spacing: 0.6px;
  line-height: 16px;
  padding: 4px 9px;
  width: 100%;

  &:hover {
    border: 1px solid var(--color-primary-400);
    border-radius: 4px;
    padding: 3px 8px;
    cursor: pointer;
  }
`

const MenuTextMessage = styled.span`
  color: var(--color-secondary-800);
`

const MenuPill = styled.span`
  margin-left: 8px;
`

const DropdownMenuText = ({
  className,
  isNew = false,
  message,
  onClick,
  testId,
  id,
  count,
  badge,
}: IDropdownMenuText) => (
  <MenuTextContainer
    className={className}
    onClick={(event) => onClick && onClick(event)}
    data-testid={testId}
    id={id}
  >
    <MenuTextMessage>{message}</MenuTextMessage>
    {badge}
    {/* Update styles to match the current New Pill UI */}
    {isNew && (
      <MenuPill>
        <SecondaryPill>New!</SecondaryPill>
      </MenuPill>
    )}
    {!!count && (
      <span style={{ marginLeft: '10px' }}>
        <GammaBadge value={count} />
      </span>
    )}
  </MenuTextContainer>
)

export default DropdownMenuText
