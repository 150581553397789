export enum FileErrors {
  TOO_MANY_FILES = 'too-many-files',
  FILE_INVALID_TYPE = 'file-invalid-type',
  FILE_TOO_LARGE = 'file-too-large',
}

export enum SupportedMimeTypes {
  CSV = 'text/csv',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  NUMBERS = 'application/x-iwork-numbers-sffnumbers',
}
