import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useDispatch, useSelector } from 'react-redux'
import { Link, generatePath } from 'react-router-dom'

import { formatNumber } from 'utils/formatters'
import { fromGlobalId } from 'utils/transformations/graphql'

import { isAccountTypeBrand } from 'store/currentUser/selectors'
import { setPriceType, setRetailer } from 'store/orders/inOrderReport/actions'
import {
  toggleSelectQuickFilter,
  unselectAllFilters,
} from 'store/quickFilters/actions'

import {
  IN_ORDER_ALLOCATION_REPORT,
  IN_ORDER_BUDGET_REPORT,
  NEW_ORDER_REVIEW,
  ORDERS_CART,
  ORDER_LIST,
} from 'routes/paths'

import {
  CartPreviewContainer,
  InfoContainer,
  LinkWrapper,
  MenuBody,
  MenuHeader,
  MenuSubHeader,
  Order,
  OrderPO,
  OrderPOLink,
  OrderTitle,
  Price,
  ReportButton,
  ReportContainer,
  ReportsLabel,
  Unit,
  UnitAmount,
  UnitLabel,
  ViewCartButton,
} from '../../CartDropdown.ui'
import { OrderInCart } from '../../cartDropdown.queries'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { CartDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

interface ICartProps {
  orders: OrderInCart[]
  isCartLoading: boolean
}

interface IUnitProps {
  label: string
  value: number
}

interface ICartOrderProps {
  order: OrderInCart
}

const Units = ({ label, value }: IUnitProps) => (
  <Unit>
    <UnitAmount className="notranslate">{value}</UnitAmount>
    <UnitLabel>{label}</UnitLabel>
  </Unit>
)

const CartOrder = ({ order }: ICartOrderProps) => {
  const dispatch = useDispatch()
  const { deprecateCartCheckout, budgetReport } = useFlags()

  const isBrand = useSelector(isAccountTypeBrand)
  const displayName = isBrand
    ? order?.retailerProfile?.displayName
    : order?.brand?.name

  const saveReportInfo = (order: OrderInCart): void => {
    const retailer = order?.retailerProfile
    dispatch(setRetailer({ value: retailer?.id, label: retailer?.displayName }))
    dispatch(
      setPriceType({
        value: order?.priceType?.id,
        label: order?.priceType?.currency?.code,
      }),
    )
  }

  const orderId = fromGlobalId(order?.id).id
  return (
    <Order key={orderId}>
      {deprecateCartCheckout && orderId ? (
        <Link
          to={generatePath(NEW_ORDER_REVIEW, {
            id: orderId,
          })}
          data-testid={CartDropdownIds.OrderReviewLink}
        >
          <OrderPOLink className="notranslate">
            {order?.referenceNumber}
          </OrderPOLink>
        </Link>
      ) : (
        <OrderPO className="notranslate">{order?.referenceNumber}</OrderPO>
      )}

      <OrderTitle
        className="notranslate"
        data-testid={CartDropdownIds.DisplayName}
      >
        {displayName}
      </OrderTitle>
      <InfoContainer>
        <Units
          label={messages.styles}
          value={order?.products?.totalProducts || 0}
        />
        <Units
          label={messages.colors}
          value={order?.products?.totalVariant || 0}
        />
        <Units
          label={messages.units}
          value={order?.products?.totalUnits || 0}
        />
        <Price>
          <UnitAmount className="notranslate">
            {formatNumber(order?.financials?.dueTotal?.maxPrice)}
          </UnitAmount>
          <UnitLabel>{`${messages.total} (${order?.priceType?.currency?.code})`}</UnitLabel>
        </Price>
      </InfoContainer>
      {isBrand && (
        <ReportContainer>
          <ReportsLabel>{messages.reports}: </ReportsLabel>
          <Link
            to={IN_ORDER_ALLOCATION_REPORT}
            data-testid={CartDropdownIds.AllocationReportLink}
            onClick={(): void => saveReportInfo(order)}
          >
            <ReportButton>{messages.allocation}</ReportButton>
          </Link>
          {budgetReport && (
            <Link
              to={IN_ORDER_BUDGET_REPORT}
              data-testid={CartDropdownIds.BudgetReportLink}
              onClick={(): void => saveReportInfo(order)}
            >
              <ReportButton>{messages.budget}</ReportButton>
            </Link>
          )}
        </ReportContainer>
      )}
    </Order>
  )
}

const CartPreview = ({ orders, isCartLoading }: ICartProps) => {
  const { deprecateCartCheckout } = useFlags()
  const dispatch = useDispatch()
  return (
    <CartPreviewContainer data-testid={CartDropdownIds.CartPreview}>
      <MenuHeader>{messages.cart}</MenuHeader>
      <MenuBody>
        {orders?.length ? (
          orders.map((order: OrderInCart) => (
            <CartOrder key={order.id} order={order} />
          ))
        ) : (
          <MenuSubHeader>{messages.emptyCart}</MenuSubHeader>
        )}
      </MenuBody>
      <LinkWrapper>
        <Link
          to={deprecateCartCheckout ? ORDER_LIST : ORDERS_CART}
          data-testid={CartDropdownIds.CartView}
          id={CartDropdownIds.CartView}
          onClick={() => {
            if (deprecateCartCheckout) {
              dispatch(unselectAllFilters())
              dispatch(
                toggleSelectQuickFilter([
                  {
                    id: 'IN_PROGRESS',
                    filterDataKey: 'status',
                  },
                ]),
              )
            }
          }}
        >
          <ViewCartButton loading={isCartLoading}>
            {deprecateCartCheckout ? messages.viewAll : messages.cartView}
          </ViewCartButton>
        </Link>
      </LinkWrapper>
    </CartPreviewContainer>
  )
}

export default CartPreview
