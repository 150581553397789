import { useDispatch } from 'react-redux'

import { resetSelectedOrders } from 'store/orderList/actions'
import {
  applySearchFilter,
  applySelectedFilters,
  toggleDeselectAllFilters,
} from 'store/orderListFilters/actions'

export const useResetAllFilters = () => {
  const dispatch = useDispatch()

  const resetOrderListFilters = () => {
    dispatch(toggleDeselectAllFilters())
    dispatch(applySearchFilter(''))
    dispatch(applySelectedFilters())
    dispatch(resetSelectedOrders())
  }
  return resetOrderListFilters
}
