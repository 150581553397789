import { ComponentProps } from 'react'

import { Link, Text } from '@joor/design-system'
import styled from 'styled-components'

import { CUSTOMER_DATA_TEMPLATE_URL } from '../../ConnectionRequestModal.constants'
import { ConnectionsModalIds } from '../../ConnectionRequestModal.ids'
import messages from '../../ConnectionRequestModal.messages'

type TextProps = ComponentProps<typeof Text>
const textProps: Pick<
  TextProps,
  'fontSize' | 'fontWeight' | 'letterSpacing'
> = {
  fontSize: 'small',
  fontWeight: 'medium',
  letterSpacing: 'tighter',
}

const StyledLink = styled(Link)`
  text-decoration: none;

  &&&:visited {
    color: var(--color-secondary-800);
  }
`

const CustomerDataTemplate = () => (
  <>
    <Text {...textProps}>{messages.toAddNewCustomers}</Text>{' '}
    <StyledLink
      id={ConnectionsModalIds.DataTemplateLink}
      href={CUSTOMER_DATA_TEMPLATE_URL}
      color="secondary-800"
      {...textProps}
    >
      {messages.dataTemplate}
    </StyledLink>
  </>
)

export default CustomerDataTemplate
