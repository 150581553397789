const messages = {
  addConnections: 'Add Connections',
  connectionsWillBeProcessed:
    'Connections will be processed by our data team and added to your account within',
  hours: '48 hours.',
  toAddNewCustomers: 'To add new customers, download our',
  dataTemplate: 'Customer Data Template',
  fillInTheTemplate: 'Fill in the template according to our best practices',
  bestPractices: 'Best Practices',
  theFollowingInfo:
    'The following information must be included to set up a new customer. All other fields are optional.',
  customerName: 'Customer Name',
  and: 'and',
  or: 'or',
  storeName: 'Store Name',
  primaryAddress: 'Primary Address',
  addressFormat: 'Address 1, City, State',
  ifApplicable: '(if applicable)',
  country: 'Country',
  buyerEmail: 'Buyer Email',
  priceType: 'Price Type',
  wholesaleCurrency: 'Wholesale Currency',
  retailCurrency: 'Retail Currency',
  priceLabel: 'Price Label',
  priceTooltipText:
    'The Price Currency and Price Label need to match the existing Price type(s) on your account. To check your price types and the labels attached, please check your',
  orderSettings: 'Order Settings',
  uploadFile: 'Upload File',
  dragAndDrop: 'Drag and drop or',
  selectFile: 'select file',
  maxFileSize: 'Max file size: 4MB',
  errorToManyFiles: 'You can only upload one file.',
  errorInvalidFileType: 'You can only upload CSV, Excel or Numbers file.',
  errorFileTooLarge: 'Maximum allowed size is 4MB.',
  submitRequest: 'Submit Request',
  submitSuccessTitle: 'Connection Request Submitted',
  submitSuccessDesc:
    'Our team has received your request and connections will be added within 48 hours.',
  submitFailedTitle: 'Connection Request Failed',
  submitFailedDesc: 'There was an error sending your connection request.',
  referToOur: 'Refer to our',
  helpCenter: 'Help Center',
  toLearnMore: 'to learn more about each Customer Specific Data field.',
} as const

export default messages
