import ArrayOfIdsFilter from 'utils/filters/arrayOfIdsFilter'
import DateFilter from 'utils/filters/dateFilter'
import { formatNameWithCode } from 'utils/formatters'

import messages from './OrdersSelectionFilters.messages'
import styles from './OrdersSelectionFilters.module.css'

const statusOptions = [
  {
    id: 'ALL_NOTES',
    name: messages.allNotes,
  },
  {
    id: 'PENDING',
    name: messages.pending,
  },
  {
    id: 'APPROVED',
    name: messages.approved,
  },
  {
    id: 'SHIPPED',
    name: messages.shipped,
  },
  {
    id: 'CANCELLED',
    name: messages.cancelled,
  },
]

export const ordersSelectionFilters = {
  status: new ArrayOfIdsFilter({
    filterKey: 'status',
    label: messages.status,
    dataKey: 'status',
    options: statusOptions,
    isSearchable: false,
  }),
  newStatus: new ArrayOfIdsFilter({
    filterKey: 'status',
    label: messages.status,
    dataKey: 'newStatus',
    options: [
      ...statusOptions,
      {
        id: 'IN_PROGRESS',
        name: messages.draft,
      },
    ],
    isSearchable: false,
  }),
  salesRepresentatives: new ArrayOfIdsFilter({
    filterKey: 'salesRepresentative',
    label: messages.salesRep,
    dataKey: 'salesRepresentatives',
    nameFormatter: (filterOption) =>
      formatNameWithCode(filterOption.name, filterOption.code),
  }),
  deliveryStartDateRange: new DateFilter({
    filterKey: 'deliveryStartDateRange',
    label: messages.startShip,
    dataKey: 'deliveryStartDateRange',
    messages,
    styles,
  }),
  deliveryCompleteDateRange: new DateFilter({
    filterKey: 'deliveryCompleteDateRange',
    label: messages.completeShip,
    dataKey: 'deliveryCompleteDateRange',
    messages,
    styles,
  }),
}

export const getFilterSetup = (flag) => [
  {
    key: 'status',
    title: messages.status,
    noTranslate: false,
    filters: [
      flag ? ordersSelectionFilters.newStatus : ordersSelectionFilters.status,
    ],
  },
  {
    key: 'salesRepresentative',
    title: messages.salesRep,
    noTranslate: true,
    filters: [ordersSelectionFilters.salesRepresentatives],
  },
  {
    key: 'dates',
    title: messages.date,
    noTranslate: false,
    filters: [
      ordersSelectionFilters.deliveryStartDateRange,
      ordersSelectionFilters.deliveryCompleteDateRange,
    ],
  },
]
