import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { userCanFindConnections } from 'store/currentUser/selectors'

import {
  CURRENT_CONNECTIONS,
  DISCOVER_BRANDS,
  FIND_NEW_BRANDS,
  PASSPORT_FAVORITES,
  PASSPORT_LANDING,
  PENDING_CONNECTIONS,
} from 'routes/paths'

import { ConnectionsRequestsLink } from '../ConnectionRequestsLinkd/ConnectionsRequestsLink'
import { SubmissionsLink } from '../SubmissionsLink/SubmissionsLink'
import { useNewConnectionsCount } from 'features/Navbar/NavbarRevamp/components/ConnectionsMenuIcon/connectionsMenuOption.hooks'
import DropdownMenuGroup from 'features/Navbar/NavbarRevamp/components/DropdownMenuGroup/DropdownMenuGroup'
import DropdownMenuTextLink from 'features/Navbar/NavbarRevamp/components/DropdownMenuTextLink/DropdownMenuTextLink'
import NavbarDropdown from 'features/Navbar/NavbarRevamp/components/NavbarDropdown/NavbarDropdown'
import { useNewSubmissions } from 'features/Navbar/NavbarRevamp/components/SubmissionsListButton/submissionsListButton.hooks'
import Trigger from 'features/Navbar/NavbarRevamp/components/Trigger/Trigger'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { ExploreDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

const NavbarDiscoverDropdownContainer = styled.div`
  display: flex;
`

const NavbarExploreDropdown = () => {
  const canFindConnections = useSelector(userCanFindConnections)
  const { newConnectionsCount } = useNewConnectionsCount()
  const { submissionsList, wayfindingFindNewBrands } = useFlags()
  const newSubmissionsCount = useNewSubmissions(!submissionsList)

  const notificationsCount = newConnectionsCount + newSubmissionsCount

  return (
    <NavbarDiscoverDropdownContainer>
      <NavbarDropdown
        trigger={
          <Trigger
            label={messages.exploreBrands}
            id={ExploreDropdownIds.ExploreTrigger}
            count={notificationsCount}
          />
        }
      >
        <DropdownMenuGroup>
          <DropdownMenuTextLink
            to={CURRENT_CONNECTIONS}
            message={messages.connectedBrands}
            id={ExploreDropdownIds.ConnectedBrands}
            testId={ExploreDropdownIds.ConnectedBrands}
          />
          {submissionsList && <SubmissionsLink />}
          <ConnectionsRequestsLink />
          <DropdownMenuTextLink
            to={PENDING_CONNECTIONS}
            message={messages.connectionsRequestsSent}
            id={ExploreDropdownIds.ConnectionRequestsSent}
            testId={ExploreDropdownIds.ConnectionRequestsSent}
          />
        </DropdownMenuGroup>
        <DropdownMenuGroup>
          <DropdownMenuTextLink
            to={PASSPORT_FAVORITES}
            message={messages.favorites}
            id={ExploreDropdownIds.Favorites}
            testId={ExploreDropdownIds.Favorites}
          />
          {canFindConnections && (
            <DropdownMenuTextLink
              to={wayfindingFindNewBrands ? FIND_NEW_BRANDS : DISCOVER_BRANDS}
              message={messages.findNewBrands}
              id={ExploreDropdownIds.FindNewBrands}
              testId={ExploreDropdownIds.FindNewBrands}
            />
          )}
          <DropdownMenuTextLink
            to={PASSPORT_LANDING}
            message={messages.passport}
            id={ExploreDropdownIds.Passport}
            testId={ExploreDropdownIds.Passport}
          />
        </DropdownMenuGroup>
      </NavbarDropdown>
    </NavbarDiscoverDropdownContainer>
  )
}

export default NavbarExploreDropdown
