import { useMutation } from '@apollo/client'

import {
  AcknowledgeRecentlyCreatedAccountsDocument,
  LiteRetailerAccountsInfoDocument,
  RecentlyCreatedAccountsQueryDocument,
} from '__generated__/atlas-types'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

atlas`#graphql
  mutation AcknowledgeRecentlyCreatedAccounts($input: AcknowledgeRecentlyCreatedAccountsInput!) {
    acknowledgeRecentlyCreatedAccounts(input: $input) {
      accounts {
        id
      }
    }
  }
`

export const useAcknowledgeRecentlyCreatedAccounts = () => {
  const [mutate, { loading, error }] = useMutation(
    AcknowledgeRecentlyCreatedAccountsDocument,
    {
      client: atlasClient,
    },
  )

  return {
    updateAcknowledgeRecentlyCreatedAccounts: (accountIds: string[]) =>
      mutate({
        variables: {
          input: {
            accountIds,
          },
        },
        refetchQueries: [
          {
            query: LiteRetailerAccountsInfoDocument,
            variables: { accountIds },
          },
          {
            query: RecentlyCreatedAccountsQueryDocument,
          },
        ],
      }),
    loading,
    error,
  }
}
