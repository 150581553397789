import { CONNECTION_REQUESTS } from 'routes/paths'

import { useNewConnectionsCount } from 'features/Navbar/NavbarRevamp/components/ConnectionsMenuIcon/connectionsMenuOption.hooks'
import DropdownMenuTextLink from 'features/Navbar/NavbarRevamp/components/DropdownMenuTextLink/DropdownMenuTextLink'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { ExploreDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

export const ConnectionsRequestsLink = () => {
  const { newConnectionsCount } = useNewConnectionsCount()
  return (
    <DropdownMenuTextLink
      to={CONNECTION_REQUESTS}
      message={messages.connectionsRequestsReceived}
      id={ExploreDropdownIds.ConnectionRequestsReceived}
      testId={ExploreDropdownIds.ConnectionRequestsReceived}
      count={newConnectionsCount}
    />
  )
}
