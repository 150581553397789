import { Text } from '@joor/design-system'
import styled from 'styled-components'

import { ConnectedAccountType } from '../../NavbarLiteRetailer.queries'
import AccountInfo from './AccountInfo'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const AccountWrapper = styled.div`
  border: 1px solid var(--color-primary-200);
  border-radius: 10px;
  padding: 10px;
  width: 370px;
  margin-bottom: 6px;
  &.disabled {
    color: var(--color-primary-400);
    border: 1px solid var(--color-primary-400);
  }
`

type AccountOptionType = {
  accountName: string
  connectedAccounts?: ConnectedAccountType[]
  checked?: boolean
  privacy: boolean
  createdAt: string
  isPrimary: boolean
  hideVisibilityIcon?: boolean
  createdBy?: string | null
}

const AccountOption = ({
  accountName,
  connectedAccounts = [],
  checked = false,
  isPrimary,
  createdAt,
  privacy,
  hideVisibilityIcon = false,
  createdBy,
}: AccountOptionType) => {
  return (
    <Wrapper>
      <AccountWrapper className={isPrimary ? 'disabled' : ''}>
        <AccountInfo
          accountName={accountName}
          isPrimary={isPrimary}
          checked={checked}
          createdBy={createdBy}
          createdAt={createdAt}
          connectedAccounts={connectedAccounts}
          hideVisibilityIcon={hideVisibilityIcon}
          privacy={privacy}
          disabled={isPrimary}
        />
      </AccountWrapper>
      {isPrimary && (
        <Text fontFamily="alpha" fontSize="extrasmall" color="primary-400">
          Primary accounts are always visible to all brands
        </Text>
      )}
    </Wrapper>
  )
}

export default AccountOption
