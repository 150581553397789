import styled from 'styled-components'

import { ProLiteIndicatorIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

interface IProLiteIndicator {
  isPro: boolean
}
const ProLiteIndicatorContainer = styled.div<IProLiteIndicator>`
  width: 20px;
  min-width: 20px;
  position: relative;
  background-color: ${({ isPro }) =>
    isPro ? 'var(--color-primary-400)' : '#6884a6'};
`
const TextWrapper = styled.div<IProLiteIndicator>`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 12px;
  font-weight: bold;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  color: ${({ isPro }) =>
    isPro ? 'var(--color-primary-900)' : 'var(--color-primary-100)'};
`

const ProLiteIndicator = ({ isProAccount = false }: ProLiteIndicatorProps) => {
  const text = isProAccount ? 'PRO' : 'LITE'
  return (
    <ProLiteIndicatorContainer isPro={isProAccount}>
      <TextWrapper
        data-testid={ProLiteIndicatorIds.ProLiteIndicatorText}
        isPro={isProAccount}
      >
        {text}
      </TextWrapper>
    </ProLiteIndicatorContainer>
  )
}

interface ProLiteIndicatorProps {
  isProAccount?: boolean
}

export default ProLiteIndicator
