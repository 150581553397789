import { useEffect } from 'react'

import { useQuery } from '@apollo/client'
import isEmpty from 'lodash/isEmpty'

import { useLocalStorage } from 'utils/storage'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

import { ZENDESK_SSO_LOGIN } from 'routes/paths'

import { CurrentUserInfo } from '../userInfo'

const ZENDESK_SIGNIN_RETURN_TO = ZENDESK_SSO_LOGIN + '?return_to='

export const accessZendeskLoggedIn = (url: string): string => {
  return ZENDESK_SIGNIN_RETURN_TO + encodeURIComponent(url)
}

export const ZENDESK_USER_QUERY = atlas`
  query getZendeskUser {
    zendeskUser{
      messagingToken
    }
}
`
const LOCAL_STORAGE_EXPIRATION = 604800000 // 7 days
const LOCAL_STORAGE_ZENDESK = 'zendesk'

interface ZendeskData {
  token: string
  userId: string
  expiry: number
}

const useZendeskTokenLocalStorage = (
  userInfo?: CurrentUserInfo,
): { token: string | null; setToken: (token: string) => void } => {
  const [zendeskLocalStorage, setZendeskLocalStorage] = useLocalStorage(
    LOCAL_STORAGE_ZENDESK,
  )

  const setToken = (token: string) => {
    if (userInfo) {
      const zendeskData: ZendeskData = {
        token: token,
        userId: userInfo.userId,
        expiry: new Date().getTime() + LOCAL_STORAGE_EXPIRATION,
      }
      setZendeskLocalStorage(JSON.stringify(zendeskData))
    }
  }

  if (!zendeskLocalStorage || !userInfo) {
    return { token: null, setToken }
  }

  const zendeskData: ZendeskData = JSON.parse(zendeskLocalStorage)
  if (
    new Date().getTime() > zendeskData.expiry ||
    zendeskData.userId !== userInfo.userId
  ) {
    setZendeskLocalStorage(null)
    return { token: null, setToken }
  }

  return { token: zendeskData.token, setToken }
}

export const useZendeskToken = (userInfo?: CurrentUserInfo) => {
  const {
    token: localToken,
    setToken: setLocalToken,
  } = useZendeskTokenLocalStorage(userInfo)
  const { data } = useQuery(ZENDESK_USER_QUERY, {
    client: atlasClient,
    skip: !!localToken,
  })
  if (!localToken && data) {
    setLocalToken(data.zendeskUser.messagingToken)
    return data.zendeskUser.messagingToken
  }

  return localToken
}

const isProRetailer = (userInfo?: CurrentUserInfo) => {
  return userInfo?.account.canViewAssortments || false
}

const shouldZendeskBeVisible = (userInfo?: CurrentUserInfo) => {
  return !isProRetailer(userInfo) && userInfo?.account.enableChat
}

const SHOPIFY_DEMO_ACCOUNT_IDS = ['1194001', '1194006']
const disableZendeskForAccount = (accountId?: string) => {
  if (!accountId) return false
  return SHOPIFY_DEMO_ACCOUNT_IDS.includes(accountId.toString())
}
export const useZendesk = (userInfo?: CurrentUserInfo) => {
  const zendeskToken = useZendeskToken(userInfo)

  useEffect(() => {
    if (isEmpty(userInfo) || !window.zE) return

    if (shouldZendeskBeVisible(userInfo)) {
      showZendeskWidget()
    } else {
      hideZendeskWidget()
    }
    switchLanguage(userInfo?.displayLanguage || 'en')
    if (
      zendeskToken &&
      !disableZendeskForAccount(userInfo?.account.accountId)
    ) {
      login(zendeskToken)
    }
  }, [userInfo, zendeskToken])
}

export const showZendeskWidget = () => {
  if (window.zE) {
    window.zE('messenger', 'show')
  }
}

export const hideZendeskWidget = () => {
  if (window.zE) {
    window.zE('messenger', 'hide')
  }
}

export const switchLanguage = (languageCode: string) => {
  if (window.zE && languageCode) {
    window.zE('messenger:set', 'locale', languageCode)
  }
}

const login = (token: string) => {
  const isZendeskLoginEnable =
    (process.env.REACT_APP_ZENDESK_LOGIN_ENABLE || 'false').toLowerCase() ===
    'true'
  if (window.zE && token !== null && token !== '' && isZendeskLoginEnable) {
    window.zE(
      'messenger',
      'loginUser',
      function (callback: (token: string) => void) {
        callback(token)
      },
    )
  }
}

export const logout = () => {
  if (window.zE) {
    window.zE('messenger', 'logoutUser')
  }
}
